import React from 'react';
import { ActivityIndicator, ScrollView } from 'react-native';
import { colors } from '../../../styles/theme';
import Row from '../../../components/Row';
import Text from '../../../components/Text';
import Modal from '../../../components/Modal';
import Unit from './Unit';

class Units extends React.Component {
  state = {
    showModal: false
  }

  componentDidMount() {
    if (this.props.level && !this.props.level.loading && (!this.props.level.units || !this.props.level.units.length)) {
      this.props.loadUnits(this.props.level.id);
    }
  }

  render() {
    const {
      level, isAuthenticated, isActive
    } = this.props;

    // Group units by 2

    return (
      <React.Fragment>
        {this.state.showModal && (
          <Modal
            contentStyles={{ marginBottom: 20 }}
            onClose={() => {
              this.setState({ showModal: false });
            }}>
            <Text style={{ paddingVertical: 10, paddingHorizontal: 20 }}>
              {'Този модул ще се отключи при достигане на 50% прогрес от предходния.'}
            </Text>
          </Modal>
        )}
        <ScrollView style={{ backgroundColor: colors.white }}>
          {level && level.units && !level.loading ? (
            <Row
              style={{
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                maxWidth: 1080,
                margin: 'auto',
              }}>
              {level.units.map((unit, i) => (
                <Unit
                  {...unit}
                  key={unit.id}
                  level={level}
                  toggleModal={() => this.setState({ showModal: true })}
                  isLocked={i && level.units[i - 1].progress < 50}
                  isActive={isActive}
                  isAuthenticated={isAuthenticated} />
              ))}
            </Row>
          ) : <ActivityIndicator size="large" style={{ marginTop: 40 }} />}
        </ScrollView>
      </React.Fragment>
    );
  }
}


export default Units;
