import dictation from './dictation.png';
import grammar from './grammar.png';
import situation from './situation.png';
import newWords from './new_words.png';
import rolePlay from './role_play.png';
import study from './study.png';
import translation from './translation.png';
import writing from './writing.png';

export default {
  dictation,
  grammar,
  situation,
  new_words: newWords,
  role_play: rolePlay,
  study,
  translation,
  writing
};
