import React from 'react';
import {
  Platform,
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/reducers/authentication';
import { toggleModal } from '../../store/reducers/certificate';
import { Link } from '../Link';
import Row from '../Row';
import Text from '../Text';

import headerBackground from './upper-menu.png';

import headerLogo from '../../icons/logo-white-text.png';

const styles = StyleSheet.create({
  header: {
    padding: 10,
    height: Platform.OS === 'ios' ? 80 : 60,
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    maxWidth: 1092,
    width: '100%',
    marginHorizontal: 'auto',
  },
});

const linkStyle = {
  color: '#fff',
  margin: '0 20px',
};

const Header = ({ token, headerHeight, userName }) => {
  const dispatch = useDispatch();
  return (
    <View style={styles.header}>
      <Image
        source={headerBackground}
        style={{
          flex: 1,
          resizeMode: 'cover',
          position: 'absolute',
          left: 0,
          right: 0,
          height: headerHeight,
          opacity: 0.6,
        }}
      />

      <Row>
        <Image source={headerLogo} style={{ width: 107, height: 40 }} />

        {token && (
          <React.Fragment>
            <Link style={linkStyle} to="/">
              Начало
            </Link>
            <Link style={linkStyle} to="/levels">
              Нива
            </Link>
            <TouchableOpacity onPress={() => dispatch(toggleModal())}>
              <Text color="white" style={{ marginHorizontal: 20 }}>
                Сертификат
              </Text>
            </TouchableOpacity>

            <Link style={linkStyle} to="/help">
              Помощ
            </Link>
          </React.Fragment>
        )}
      </Row>

      <Row>
        <Text color="white" style={{ marginHorizontal: 20 }}>
          {'Здравей '}
          {userName}
        </Text>
        <Link style={linkStyle} to="/profile">
          Профил
        </Link>
        <TouchableOpacity onPress={() => dispatch(logout())}>
          <Text color="white" style={{ marginHorizontal: 20 }}>
            Изход
          </Text>
        </TouchableOpacity>
      </Row>
    </View>
  );
};

export default Header;
