import { connect } from 'react-redux';
import {
  loadUnits,
  loadUnit,
  loadLevels,
  setSuggestedLevel
} from '../../store/reducers/learn';

import TestingPage from './components/TestingPage';

export default connect(
  ({ learn, user }, { match }) => ({
    levels: learn.levels, // for unauthenticated test, TODO: find a way to find exact test here
    level: learn.levels.find(level => level.id.toString() === match.params.levelId), // for authenticated test
    testUnit: learn.units[52], // TODO: find a way to query correct unit
    units: learn.units,
    userId: user.id
  }),
  {
    loadUnits, loadUnit, loadLevels, setSuggestedLevel
  }
)(TestingPage);
