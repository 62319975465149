import React from 'react';
import { Dimensions } from 'react-native';
import { useDispatch } from 'react-redux';
import ScrollView from './ScrollView';
import Modal from '../Modal';
import DictionaryLine, { AddButton } from '../DictionaryLine';
import {
  toggleDictionary,
  removeWord,
  editWord,
  addWord,
} from '../../store/reducers/dictionary';

function Dictionary({ dictionary }) {
  const dispatch = useDispatch();

  return (
    <Modal
      title="Речник"
      onClose={() => dispatch(toggleDictionary())}
      style={{ maxHeight: Dimensions.get('window').height - 150 }}>
      <ScrollView style={{ maxHeight: Dimensions.get('window').height - 250 }}>
        {dictionary.words.map((word, i) => (
          <DictionaryLine
            key={i + word[0]}
            word={word}
            index={i}
            removeWord={index => dispatch(removeWord(index))}
            editWord={(index, word) => dispatch(editWord(index, word))}
          />
        ))}
      </ScrollView>
      <AddButton onPress={() => dispatch(addWord())} />
    </Modal>
  );
}

export default Dictionary;
