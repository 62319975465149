import { connect } from 'react-redux';
import dotProp from 'dot-prop-immutable';
import { loadLevels } from '../../store/reducers/learn';
import { loadPayments, loadProducts } from '../../store/reducers/payments';

import DashboardPage from './components/Dashboard';

export default connect(
  ({ user, learn, payments }) => {
    const { activePayment } = payments;
    const activeProduct =
      activePayment &&
      payments.products.find(({ id }) => id === activePayment.product);
    const currentUnit = user.currentUnit ? learn.units[user.currentUnit] : null;
    const activeLevel =
      currentUnit &&
      !currentUnit.loading &&
      learn.levels &&
      learn.levels.find(level => level.id === currentUnit.level);

    return {
      user,
      levelsStatus: learn.levelsStatus,
      activeLevel,
      activePayment: activePayment
        ? { ...activePayment, product: activeProduct || activePayment.product }
        : {},
      // TODO: export suggestedLevel checker
      suggestedLevel: dotProp.get(
        learn.levels.find(level => level.name === learn.suggestedLevel),
        'id',
      ),
    };
  },
  { loadLevels, loadPayments, loadProducts },
)(DashboardPage);
