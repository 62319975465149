// import { adaptKeys } from 'bell/src/utils/dataParsers';
// import tempUnit from './temp-unit';
import dotProp from 'dot-prop-immutable';
import { AsyncStorage } from 'react-native';

import { LOG_OUT } from './authentication';

export const SET_SUGGESTED_LEVEL = 'learn.SET_SUGGESTED_LEVEL';

export const LOAD_LEVELS = 'learn.LOAD_LEVELS';
export const LOAD_LEVELS_SUCCESS = 'learn.LOAD_LEVELS_SUCCESS';
export const LOAD_LEVELS_FAIL = 'learn.LOAD_LEVELS_FAIL';

// TODO: rename to UNIT_LIST
export const LOAD_UNITS = 'learn.LOAD_UNITS';
export const LOAD_UNITS_SUCCESS = 'learn.LOAD_UNITS_SUCCESS';
export const LOAD_UNITS_FAIL = 'learn.LOAD_UNITS_FAIL';

export const LOAD_UNIT = 'learn.LOAD_UNIT';
export const LOAD_UNIT_SUCCESS = 'learn.LOAD_UNIT_SUCCESS';
export const LOAD_UNIT_FAIL = 'learn.LOAD_UNIT_FAIL';

export const ANSWER = 'learn.ANSWER';
export const ANSWER_SUCCESS = 'learn.ANSWER_SUCCESS';
export const ANSWER_FAIL = 'learn.ANSWER_FAIL';

export function loadLevels() {
  return {
    type: LOAD_LEVELS,
    fetchParams: {
      path: '/learn/level/?page=1',
      success: LOAD_LEVELS_SUCCESS,
      fail: LOAD_LEVELS_FAIL,
    },
  };
}

export function loadUnits(levelId) {
  return levelId
    ? {
        type: LOAD_UNITS,
        levelId,
        fetchParams: {
          path: `/learn/level/${levelId}/`,
          success: LOAD_UNITS_SUCCESS,
          fail: LOAD_UNITS_FAIL,
        },
      }
    : { type: LOAD_UNITS_FAIL };
}

export function loadUnit(unitId) {
  return unitId
    ? {
        type: LOAD_UNIT,
        unitId,
        fetchParams: {
          path: `/learn/unit/${unitId}/`,
          success: LOAD_UNIT_SUCCESS,
          fail: LOAD_UNIT_FAIL,
        },
      }
    : { type: LOAD_UNIT_FAIL };
}

export function answerFragment({ fragmentPartId, answer, ...rest }) {
  return {
    type: ANSWER,
    ...rest,
    fragmentPartId,
    payload: {
      fragmentPart: fragmentPartId,
      answer,
    },
    fetchParams: {
      path: '/learn/answer/',
      method: 'POST',
      success: ANSWER_SUCCESS,
      fail: ANSWER_FAIL,
    },
  };
}

export function markWordsSeen({ unitId, moduleIndex }) {
  return {
    type: ANSWER,
    unitId,
    newWordsSeen: true,
    moduleIndex,
    payload: {
      unit: unitId,
      newWordsSeen: true,
    },
    fetchParams: {
      path: '/learn/answer/',
      method: 'POST',
      success: ANSWER_SUCCESS,
      fail: ANSWER_FAIL,
    },
  };
}

export function markGrammarSeen({ unitId, moduleIndex }) {
  return {
    type: ANSWER,
    unitId,
    grammarSeen: true,
    moduleIndex,
    payload: {
      unit: unitId,
      grammarSeen: true,
    },
    fetchParams: {
      path: '/learn/answer/',
      method: 'POST',
      success: ANSWER_SUCCESS,
      fail: ANSWER_FAIL,
    },
  };
}

export function markVideoSeen({ unitId }) {
  return {
    type: ANSWER,
    unitId,
    videoSeen: true,
    payload: {
      unit: unitId,
      videoSeen: true,
    },
    fetchParams: {
      path: '/learn/answer/',
      method: 'POST',
      success: ANSWER_SUCCESS,
      fail: ANSWER_FAIL,
    },
  };
}

export function setSuggestedLevel(level) {
  AsyncStorage.setItem('sl', level); // set suggested level
  return {
    type: SET_SUGGESTED_LEVEL,
    level,
  };
}

export function setSuggestedLevelFromStorage() {
  return async dispatch => {
    const level = await AsyncStorage.getItem('sl');

    return dispatch({
      type: SET_SUGGESTED_LEVEL,
      level,
    });
  };
}

const initialState = {
  suggestedLevel: null,
  levelsStatus: null,
  levels: [],
  units: {}, // { 17: adaptKeys(tempUnit, 'receive') }
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SUGGESTED_LEVEL:
      return {
        ...state,
        suggestedLevel: action.level,
      };

    case LOAD_LEVELS:
      return dotProp.set(state, 'levelsStatus', 'loading');

    case LOAD_LEVELS_SUCCESS:
      return {
        ...state,
        levelsStatus: 'success',
        levels: action.payload.results, // sorted by index key
      };

    case LOAD_LEVELS_FAIL:
      return dotProp.set(state, 'levelsStatus', 'error');

    case LOAD_UNITS:
      return {
        ...state,
        levels: state.levels.map(level =>
          level.id === action.levelId
            ? {
                ...level,
                loading: true,
              }
            : level,
        ),
      };

    case LOAD_UNITS_SUCCESS:
      return {
        ...state,
        levels: state.levels.map(level =>
          level.id === action.payload.id
            ? {
                ...level,
                loading: false,
                units: action.payload.units,
              }
            : level,
        ),
      };

    case LOAD_UNIT:
      return {
        ...state,
        units: {
          ...state.units,
          [action.unitId]: {
            ...state.units[action.unitId],
            loading: true,
          },
        },
      };

    case LOAD_UNIT_SUCCESS:
      return {
        ...state,
        units: {
          ...state.units,
          [action.unitId]: {
            ...state.units[action.unitId],
            ...action.payload,
            loading: false,
          },
        },
      };

    // it can fail if it's marked as complete
    // case ANSWER_FAIL:
    case ANSWER_SUCCESS: {
      console.log(action);

      let newState;
      if (action.newWordsSeen) {
        newState = dotProp.set(
          state,
          `units.${action.unitId}.newWordsSeen`,
          true,
        );
      } else if (action.videoSeen) {
        newState = dotProp.set(state, `units.${action.unitId}.videoSeen`, true);
      } else if (action.grammarSeen) {
        newState = dotProp.set(
          state,
          `units.${action.unitId}.grammarSeen`,
          true,
        );
      } else {
        newState = dotProp.set(
          state,
          `units.${action.unitId}.modules.${action.moduleIndex}.progress`,
          action.payload.moduleProgress,
        );
      }

      if (
        action.fragmentIndex !== undefined &&
        action.fragmentPartIndex !== undefined
      ) {
        newState = dotProp.set(
          newState,
          `units.${action.unitId}.modules.${action.moduleIndex}.fragments.${
            action.fragmentIndex
          }.fragmentParts.${action.fragmentPartIndex}.isAnswered`,
          true,
        );
      }

      if (action.payload.unitProgress) {
        // Update in unit map
        newState = dotProp.set(
          newState,
          `units.${action.unitId}.progress`,
          action.payload.unitProgress,
        );

        try {
          // update in level
          const levelIndex = newState.levels.findIndex(
            ({ id }) =>
              id === dotProp.get(newState, `units.${action.unitId}.level`),
          );

          newState = dotProp.set(
            newState,
            `levels.${levelIndex}.units`,
            dotProp
              .get(newState, `levels.${levelIndex}.units`)
              .map(unit =>
                unit.id === action.unitId
                  ? { ...unit, progress: action.payload.unitProgress }
                  : unit,
              ),
          );
        } catch (err) {
          // console.log('err', err);
        }
      }
      return newState;
    }

    case LOG_OUT:
      return initialState;

    default:
      return state;
  }
}
