import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { connect } from 'react-redux';
import {
  answerFragment,
  markWordsSeen as markWordsSeenRaw,
  markGrammarSeen as markGrammarSeenRaw,
} from '../../../../store/reducers/learn';

import { commonStyles } from '../../../../styles/theme';

import Text from '../../../../components/Text';
import Row from '../../../../components/Row';
import HelpLine from '../../../../components/HelpLine';
import Collapsable from '../../../../components/Collapsable';
import { stripHtml } from '../../../../utils/htmlParsers';

import FragmentPart from '../FragmentPart';

const styles = StyleSheet.create({
  moduleContent: {
    display: 'flex',
    paddingBottom: 40,
  },
  title: {
    display: 'flex',
    paddingVertical: 5,
    paddingHorizontal: 20,
  },
  fragmentContent: {
    marginVertical: 5,
    marginHorizontal: 20,
  },
  translationLetterTitle: {
    ...commonStyles.borderBottom,
    marginVertical: 0,
    marginHorizontal: 4,
    paddingVertical: 12,
    paddingHorizontal: 40,
  },
  paper: {
    ...commonStyles.elevation3,
    ...commonStyles.paper,
  },
});

// const Paper = (props) => <View style={[styles.paper, { paddingHorizontal: 0 }]} {...props} />;

// Standard module, see Situation, Dictation and Roleplay for other types
class Module extends React.Component {
  state = {
    playingFragment: null,
  };

  componentDidMount() {
    const {
      markWordsSeen,
      markGrammarSeen,
      module,
      unitId,
      newWordsSeen,
      grammarSeen,
    } = this.props;
    const moduleType = module.type;

    if (moduleType === 'NEW_WORDS' && !newWordsSeen) {
      markWordsSeen({
        unitId,
        moduleIndex: module.index,
      });
    }

    if (moduleType === 'GRAMMAR' && !grammarSeen) {
      markGrammarSeen({
        unitId,
        moduleIndex: module.index,
      });
    }
  }

  render() {
    const { module, unitId } = this.props;
    const isWeb = Platform.OS === 'web';

    switch (module.type) {
      case 'NEW_WORDS':
        return module.fragments.map(fragment => (
          <View style={styles.moduleContent} key={fragment.index}>
            {
              fragment.fragmentParts
                .map(fPart => ({ ...fPart, text: stripHtml(fPart.text) }))
                .sort((a, b) => a.text.localeCompare(b.text))
                .reduce(
                  ({ usedLetters, elements }, fPart) => {
                    // const text = stripHtml(fPart.text);
                    const currentLetter = usedLetters[usedLetters.length - 1];
                    const titleLetter = fPart.text.charAt(0).toUpperCase();
                    const letterChanged = currentLetter !== titleLetter;
                    // const Wrapper = isWeb ? Paper : React.Fragment;

                    return {
                      usedLetters: letterChanged
                        ? [...usedLetters, titleLetter]
                        : usedLetters,
                      elements: [
                        ...elements,
                        <React.Fragment key={fPart.index}>
                          {letterChanged && (
                            <View style={styles.translationLetterTitle}>
                              <Text style={{ fontWeight: 'bold' }} size="18">
                                {titleLetter}
                              </Text>
                            </View>
                          )}
                          <Text
                            style={{
                              paddingVertical: 8,
                              paddingHorizontal: 40,
                            }}>
                            {fPart.text.split(' - ').map((textPart, i) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <Text
                                key={i}
                                color={i ? 'greyDarken' : 'blueDarken1'}>
                                {!!i && ' - '}
                                {textPart}
                              </Text>
                            ))}
                          </Text>
                        </React.Fragment>,
                      ],
                    };
                  },
                  { usedLetters: [], elements: [] },
                ).elements
            }
          </View>
        ));

      case 'WRITING':
        // case 'TRANSLATION':
        return module.fragments.map((fragment, i) =>
          isWeb ? (
            <React.Fragment key={fragment.index}>
              <HelpLine line={this.props.helpLines[i]} />
              <Row align="flex-start" justify="flex-start" style={styles.paper}>
                <Text
                  fontSize={20}
                  style={{ paddingTop: 30, paddingLeft: 34 }}>{`${
                  fragment.title
                }:`}</Text>
                <View>
                  {fragment.fragmentParts.map(fPart => (
                    <FragmentPart
                      key={fPart.index}
                      data={fPart}
                      moduleIndex={module.index}
                      fragmentIndex={fragment.index}
                      unitId={unitId}
                    />
                  ))}
                </View>
              </Row>
            </React.Fragment>
          ) : (
            <Collapsable key={fragment.index} title={fragment.title}>
              <View style={{ paddingBottom: 50 }}>
                {fragment.fragmentParts.map(fPart => (
                  <FragmentPart
                    key={fPart.index}
                    data={fPart}
                    moduleIndex={module.index}
                    fragmentIndex={fragment.index}
                    unitId={unitId}
                  />
                ))}
              </View>
            </Collapsable>
          ),
        );

      default:
        return module.fragments.map(fragment => (
          <View style={styles.moduleContent} key={fragment.index}>
            {!!fragment.title && (
              <View style={styles.title}>
                <Text style={{ fontWeight: 'bold' }}>{fragment.title}</Text>
              </View>
            )}
            <View style={styles.fragmentContent}>
              {fragment.fragmentParts.map(fPart => (
                <FragmentPart
                  key={fPart.index}
                  playingFragment={this.state.playingFragment}
                  setPlayingFragment={playingFragment => {
                    this.setState({ playingFragment });
                  }}
                  data={fPart}
                  moduleIndex={module.index}
                  fragmentIndex={fragment.index}
                  unitId={unitId}
                />
              ))}
            </View>
          </View>
        ));
    }
  }
}

export default connect(
  () => ({}),
  {
    answer: answerFragment,
    markWordsSeen: markWordsSeenRaw,
    markGrammarSeen: markGrammarSeenRaw,
  },
)(Module);
