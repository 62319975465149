import React from 'react';
import PropTypes from 'prop-types';
import { Text as RText, StyleSheet } from 'react-native';
import { colors } from '../../styles/theme';

const styles = StyleSheet.create({
  text: {
    color: colors.purple,
    textAlign: 'left',
    fontSize: 16
  },
});

const Text = ({
  children, color, align, fontSize, style
}) => (
  <RText
    style={[
      styles.text,
      color && { color: colors[color] || colors.purple },
      align && { textAlign: align },
      fontSize && { fontSize },
      style
    ]}>
    {children}
  </RText>
);

Text.propTypes = {
  fontSize: PropTypes.number
};

export default Text;
