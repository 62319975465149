import React from 'react';
import { Image, View, StyleSheet } from 'react-native';
import { colors, commonStyles } from '../../styles/theme';
import Text from '../Text';
import { Link } from '../Link';
import PercentageCircle from '../PercentageCircle';

import bronzeMedal from './bronze-medal.png';
import silverMedal from './silver-medal.png';
import goldMedal from './gold-medal.png';

const getImage = (progress) => {
  if (progress <= 50) {
    return bronzeMedal;
  } if (progress <= 70) {
    return silverMedal;
  }

  return goldMedal;
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    backgroundColor: colors.white,
    margin: 2,
    overflow: 'hidden'
  },
  medal: {
    position: 'absolute',
    backgroundColor: colors.white,
    width: 54,
    height: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 29,
    ...commonStyles.elevation3
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  },
  percentage: {
    position: 'absolute',
    backgroundColor: colors.red,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabler: {
    position: 'absolute',
    top: 0,
    left: 0,
    flex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    width: '100%',
    height: '100%'
  }
});

export default ({
  style, linkTo, title, image, isLarge, progress, disabled, showMedal
}) => {
  const Wrapper = linkTo ? Link : View;
  const wrapperProps = linkTo ? { to: linkTo, disabled } : null;
  const imageSize = isLarge ? 138 : 70;
  const containerSize = (imageSize + 14) / 2;
  const progressButtonSize = isLarge ? 56 : 30;

  return (
    <Wrapper {...wrapperProps} style={style}>
      <View style={styles.container}>
        <PercentageCircle
          percent={progress || 0}
          radius={containerSize}
          borderWidth={3}
          color={colors.blueDarken1}
          bgColor={colors.white}>
          <View style={[styles.imageContainer, { width: imageSize, height: imageSize, borderRadius: imageSize / 2 }]}>
            {image && <Image source={image} style={{ width: imageSize, height: imageSize }} />}
          </View>
        </PercentageCircle>

        {typeof progress === 'number' && !showMedal && (
          <View style={[
            styles.percentage,
            {
              top: isLarge ? 0 : 8, width: progressButtonSize, height: progressButtonSize, borderRadius: progressButtonSize / 2
            }
          ]}>
            <Text color="white" fontSize={isLarge ? 16 : progress === 100 ? 10 : 12}>
              {`${progress}%`}
            </Text>
          </View>
        )}
        {showMedal && (
          <View style={[styles.medal, { top: isLarge ? -5 : 3 }]}>
            <Image source={getImage(progress)} style={{ width: 39, height: 46 }} />
          </View>
        )}
        <Text align="center">{title}</Text>
        {disabled && <View style={styles.disabler} />}
      </View>
    </Wrapper>
  );
};
