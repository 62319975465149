import React from 'react';
import {
  ActivityIndicator, StyleSheet, View
} from 'react-native';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import KeyboardAwareScrollView from '../../../components/KeyboardAwareScrollView';
import { Link } from '../../../components/Link';

const styles = StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const LinkText = ({ children, to, style }) => (
  <Link to={to} style={{ padding: 0, margin: 0, ...style }}>
    <Text color="white" style={{ textDecorationLine: 'underline' }}>
      {children}
    </Text>
  </Link>
);

const inputProps = {
  placeholderTextColor: 'rgba(255, 255, 255, 0.5)',
  spellCheck: false,
  autoCapitalize: 'none',
  style: { marginBottom: 20, width: 250 },
  underlineColorAndroid: 'rgba(0, 0, 0, 0)'
};

/* eslint-disable react/no-unused-state */
export default class AuthPage extends React.Component {
  state = {
    email: '',
    newPassword1: '',
    newPassword2: '',
    // phone: '',
    showErrorModal: false,
    prevResetPasswordSatus: this.props.resetPasswordSatus // when switching pages component remounts
  }

  static getDerivedStateFromProps(props, state) {
    if (state.prevResetPasswordSatus !== 'error' && props.resetPasswordSatus === 'error') {
      return {
        showErrorModal: true,
        prevResetPasswordSatus: props.resetPasswordSatus
      };
    }

    return {
      prevResetPasswordSatus: props.resetPasswordSatus
    };
  }

  handleReset = () => {
    const { token, uid } = this.props.match.params;
    if (token) {
      this.props.resetPassword(this.state, token, uid);
    } else {
      this.props.forgotPassword(this.state.email);
    }
  }

  render() {
    const {
      email, showErrorModal, newPassword1, newPassword2
    } = this.state;
    const { resetPasswordSatus, errors } = this.props;
    const isLoading = resetPasswordSatus === 'loading';
    const isSuccesfull = resetPasswordSatus === 'success';
    const { token } = this.props.match.params;

    return (
      <React.Fragment>
        {showErrorModal && (
          <Modal
            contentStyles={{ marginBottom: 20 }}
            onClose={() => {
              this.setState({ showErrorModal: false });
            }}>
            {errors ? errors.map((err, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text key={i} color="greyDarken" style={{ paddingVertical: 10, paddingHorizontal: 20 }}>{`• ${err}`}</Text>
            )) : (
              <Text color="greyDarken" style={{ paddingVertical: 10, paddingHorizontal: 20 }}>• Грешка в сървъра. Моля, опитай отново по-късно</Text>
            )}
          </Modal>
        )}
        <KeyboardAwareScrollView style={{ height: '100%' }} keyboardShouldPersistTaps="handled">
          {token ? (
            <View style={styles.content}>
              <Text color="white" fontSize={26} style={{ width: 250, marginTop: 40, marginBottom: 20 }}>
                {'Въведи нова парола'}
              </Text>

              <TextInput
                {...inputProps}
                disabled={isLoading}
                placeholder="Enter your new password"
                textContentType="password"
                secureTextEntry
                value={newPassword1}
                onChangeText={text => this.setState({ newPassword1: text })} />

              <TextInput
                {...inputProps}
                disabled={isLoading}
                placeholder="Confirm your password"
                textContentType="password"
                secureTextEntry
                value={newPassword2}
                onChangeText={text => this.setState({ newPassword2: text })} />

              {isLoading ? <ActivityIndicator /> : <Button onPress={this.handleReset} disabled={isSuccesfull}>Рестарт</Button>}
              {isSuccesfull && (
                <Text color="white" style={{ marginBottom: 20 }}>
                  {'Паролата е променена успешно'}
                </Text>
              )}
              <LinkText to="/signup">Отиди на страницата за регистрация</LinkText>

            </View>
          ) : (
            <View style={styles.content}>
              <Text color="white" fontSize={26} style={{ width: 250, marginTop: 40, marginBottom: 20 }}>
                {'Забравена парола?'}
              </Text>
              <Text color="white" fontSize={16} style={{ width: 250, marginTop: 20, marginBottom: 25 }}>
                {'Моля въведи валиден e-mail адрес и ние ще изпратим линк за възстановяване на паролата ти'}
              </Text>

              <TextInput
                {...inputProps}
                disabled={isLoading}
                placeholder="Въведете e-mail"
                textContentType="email"
                value={email}
                onChangeText={text => this.setState({ email: text })} />

              {isLoading ? <ActivityIndicator /> : <Button onPress={this.handleReset} disabled={isSuccesfull}>Изпрати</Button>}
              {isSuccesfull && (
                <Text color="white" style={{ width: 250, marginBottom: 20 }}>
                  {'Съобщението е изпратено. Моля провери Входящата си поща или папка Спам'}
                </Text>
              )}

              <LinkText to="/signup">Назад</LinkText>
            </View>
          )}
        </KeyboardAwareScrollView>
      </React.Fragment>
    );
  }
}
