// import { push, LOCATION_CHANGE } from 'react-router-redux';
import { AsyncStorage } from 'react-native';
import dotProp from 'dot-prop-immutable';
import { extractAllErrors } from '../../utils/dataParsers';
// import { clearCookies } from '../../utils/request';

export const REGISTER = 'authentication.REGISTER';
export const REGISTER_SUCCESS = 'authentication.REGISTER_SUCCESS';
export const REGISTER_FAIL = 'authentication.REGISTER_FAIL';

export const LOG_IN = 'authentication.LOG_IN';
export const LOG_IN_SUCCESS = 'authentication.LOG_IN_SUCCESS';
export const LOG_IN_FAIL = 'authentication.LOG_IN_FAIL';

export const LOG_OUT = 'authentication.LOG_OUT';
export const LOG_OUT_SUCCESS = 'authentication.LOG_OUT_SUCCESS';
export const LOG_OUT_FAIL = 'authentication.LOG_OUT_FAIL';

export const ERROR_EMAIL_MISSING = 'Въведи e-mail!';
export const ERROR_PASSWORD_MISSING = 'Въведи парола!';
export const ERROR_PASSWORD_MISMATCH = 'Паролите трябва да съвпадат!';
export const ERROR_FULLNAME_MISSING = 'Въведи име!';

export const LOAD_ACCOUNT = 'authentication.LOAD_ACCOUNT';
export const LOAD_ACCOUNT_SUCCESS = 'authentication.LOAD_ACCOUNT_SUCCESS';
export const LOAD_ACCOUNT_FAIL = 'authentication.LOAD_ACCOUNT_FAIL';

export const CHANGE_PASSWORD = 'authentication.CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'authentication.CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'authentication.CHANGE_PASSWORD_FAIL';

export const FORGOT_PASSWORD = 'authentication.FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'authentication.FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'authentication.FORGOT_PASSWORD_FAIL';

export const RESET_PASSWORD = 'authentication.RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'authentication.RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'authentication.RESET_PASSWORD_FAIL';

export function loginUser({ email, password, subscription_id }) {
  let error;
  if (!email) {
    error = ERROR_EMAIL_MISSING;
  }

  if (!password) {
    error = ERROR_PASSWORD_MISSING;
  }

  if (error) {
    return {
      type: LOG_IN_FAIL,
      payload: { error },
    };
  }

  // Saga listens to LOG_IN action
  return {
    type: LOG_IN,
    payload: {
      email: email.toLowerCase(),
      password,
      ...(subscription_id ? { subscription_id } : {}),
    },
    fetchParams: {
      path: '/user/login/',
      method: 'POST',
      success: LOG_IN_SUCCESS,
      fail: LOG_IN_FAIL,
      cancel: LOG_OUT,
    },
  };
}

export function registerUser({
  email,
  password,
  firstName,
  lastName,
  newsletter,
  subscription_id,
}) {
  let error;
  if (!email) {
    error = ERROR_EMAIL_MISSING;
  }

  if (!password) {
    error = ERROR_PASSWORD_MISSING;
  }

  // if (password !== passwordConfirmation) {
  //   error = ERROR_PASSWORD_MISMATCH;
  // }

  if (!firstName || !lastName) {
    error = ERROR_FULLNAME_MISSING;
  }

  if (error) {
    return {
      type: LOG_IN_FAIL,
      payload: { error },
    };
  }

  return {
    type: REGISTER,
    payload: {
      email: email.toLowerCase(),
      password1: password,
      password2: password,
      firstName,
      lastName,
      newsletter,
      ...(subscription_id ? { subscription_id } : {}),
    },
    fetchParams: {
      path: '/user/register/',
      method: 'POST',
      success: REGISTER_SUCCESS,
      fail: REGISTER_FAIL,
    },
  };
}

export function changePassword({ newPassword1, newPassword2 }) {
  return {
    type: CHANGE_PASSWORD,
    payload: { newPassword1, newPassword2 },
    fetchParams: {
      path: '/user/password/change/',
      method: 'POST',
      success: CHANGE_PASSWORD_SUCCESS,
      fail: CHANGE_PASSWORD_FAIL,
    },
  };
}

export function forgotPassword(email) {
  if (!email || !email.length) {
    return {
      type: FORGOT_PASSWORD_FAIL,
      payload: {
        error: 'You must put a valid email',
      },
    };
  }

  return {
    type: FORGOT_PASSWORD,
    payload: { email },
    fetchParams: {
      path: '/user/password/reset/',
      method: 'POST',
      success: FORGOT_PASSWORD_SUCCESS,
      fail: FORGOT_PASSWORD_FAIL,
    },
  };
}

export function resetPassword({ newPassword1, newPassword2 }, token, uid) {
  if (!newPassword1) {
    return {
      type: RESET_PASSWORD_FAIL,
      payload: {
        error: ERROR_PASSWORD_MISSING,
      },
    };
  }

  if (newPassword1 !== newPassword2) {
    return {
      type: RESET_PASSWORD_FAIL,
      payload: {
        error: ERROR_PASSWORD_MISMATCH,
      },
    };
  }

  return {
    type: RESET_PASSWORD,
    payload: {
      newPassword1,
      newPassword2,
      uid,
      token,
    },
    fetchParams: {
      path: '/user/password/reset/confirm/',
      method: 'POST',
      success: RESET_PASSWORD_SUCCESS,
      fail: RESET_PASSWORD_FAIL,
    },
  };
}

// Replaces addToken
export function checkToken(invalidate: boolean = false) {
  return async dispatch => {
    const token = await AsyncStorage.getItem('token');

    return dispatch(
      token && !invalidate
        ? {
            type: LOAD_ACCOUNT,
            payload: { token },
            fetchParams: {
              path: '/user/',
              success: LOAD_ACCOUNT_SUCCESS,
              fail: LOAD_ACCOUNT_FAIL,
            },
          }
        : {
            type: LOG_OUT,
          },
    );
  };
}

export function logout() {
  // clearCookies();
  AsyncStorage.removeItem('csrftoken');

  return {
    type: LOG_OUT,
  };
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  // TODO: reduce statuses to one
  loginStatus: undefined, // loading, success, error?
  registerStatus: undefined, // loading, success, error
  resetPasswordSatus: undefined,
  resetMessage: '',
  resetErrors: [],
  token: 'loading',
  error: '',
  errors: [],
  passwordChangeStatus: null,
  passwordChangeError: '',
  // message: '',
  // content: '',
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        loginStatus: 'loading',
        errors: [],
      };

    // case REGISTER_FAIL:
    //   return {
    //     ...state,
    //     registerStatus: 'error',
    //     error: action.payload.error
    //   };

    case LOG_IN:
      return {
        ...state,
        loginStatus: 'loading',
        errors: [],
      };

    case LOG_IN_SUCCESS:
    case LOAD_ACCOUNT:
    case REGISTER_SUCCESS: {
      const { token } = action.payload;
      AsyncStorage.setItem('token', token);

      return {
        ...state,
        loginStatus: 'success',
        error: '',
        token,
      };
    }

    // case LOAD_ACCOUNT_FAIL: {
    //   AsyncStorage.removeItem('token');

    //   return {
    //     ...state,
    //     token: null
    //   };
    // }

    case LOG_IN_FAIL:
    case REGISTER_FAIL:
      return {
        ...state,
        loginStatus: 'error',
        errors: extractAllErrors(action.payload),
        token: null,
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        passwordChangeStatus: 'loading',
        passwordChangeError: '',
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChangeStatus: 'success',
      };

    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        passwordChangeStatus: 'error',
        passwordChangeError: dotProp.get(
          action,
          'payload.response.new_password2.0',
        ),
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        resetPasswordSatus: 'loading',
        resetErrors: [],
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSatus: 'success',
        resetMessage: action.payload.message,
        error: '',
      };

    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordSatus: 'error',
        resetErrors: extractAllErrors(action.payload),
      };

    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordSatus: 'loading',
        resetMessage: 'Reset sent',
        resetErrors: [],
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSatus: 'success',
        resetMessage: action.payload.message,
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordSatus: 'error',
        resetErrors: extractAllErrors(action.payload),
      };

    case LOG_OUT:
      AsyncStorage.removeItem('token');

      return {
        ...initialState,
        token: null,
      };

    default:
      return state;
  }
}
