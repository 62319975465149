import React from 'react';
import { ScrollView } from 'react-native';

export default function ({ children, style }) {
  return (
    <ScrollView style={[{ height: '100%' }, style]} keyboardShouldPersistTaps="handled">
      {children}
    </ScrollView>
  );
}
