import React from 'react';
import { Image, TouchableOpacity, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { toggleDictionary } from '../../store/reducers/dictionary';
import { colors } from '../../styles/theme';
import homeIcon from '../../icons/home.png';
import dictionaryIcon from '../../icons/dictionary.png';
import profileIcon from '../../icons/profile-white.png';
import termsIcon from '../../icons/terms.png';
import helpIcon from '../../icons/help-white.png';
import background from './nav-background.png';
import { Link } from '../Link';
import Row from '../Row';
import useOrientation from '../../hooks/useOrientation';

export default function Navigation() {
  const orientation = useOrientation();
  const dispatch = useDispatch();

  return orientation !== 'PORTRAIT' ? null : (
    <Row
      alignItems="space-between"
      style={{
        backgroundColor: colors.purple,
        position: Platform.OS === 'web' ? 'fixed' : 'absolute',
        bottom: 0,
        zIndex: 10,
        width: '100%',
        height: 46,
        borderTopWidth: 1,
        borderTopColor: '#686791',
      }}>
      <Image
        source={background}
        style={{
          flex: 1,
          resizeMode: 'cover',
          position: 'absolute',
          left: 0,
          right: 0,
        }}
      />
      <Link to="/">
        <Image source={homeIcon} style={{ width: 26, height: 23 }} />
      </Link>
      <Link to="/levels">
        <Image source={termsIcon} style={{ width: 19, height: 23 }} />
      </Link>
      <TouchableOpacity onPress={() => dispatch(toggleDictionary())}>
        <Image source={dictionaryIcon} style={{ width: 30, height: 18 }} />
      </TouchableOpacity>
      <Link to="/profile">
        <Image source={profileIcon} style={{ width: 22, height: 23 }} />
      </Link>
      <Link to="/help">
        <Image source={helpIcon} style={{ width: 22, height: 22 }} />
      </Link>
    </Row>
  );
}
