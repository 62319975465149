import React from 'react';
import { ActivityIndicator } from 'react-native';
import dotProp from 'dot-prop-immutable';
import moment from 'moment';
import { colors } from '../../../styles/theme';
import ProgressButton from '../../../components/ProgressButton';
import HeadingTitle from '../../../components/HeadingTitle';
import Row from '../../../components/Row';
import Text from '../../../components/Text';
import DailyStreak from '../../../components/DailyStreak';
import { NavLink } from '../../../components/Button';
import { ScrollView } from '../../../components/SimplePage';
import image from './progress.png';

export default class DashboardPage extends React.PureComponent {
  render() {
    const {
      user, activeLevel, levelsStatus, activePayment, suggestedLevel
    } = this.props;

    const plan = dotProp.get(activePayment, 'product.period');
    const currentProgressDay = moment().diff(dotProp.get(activePayment, 'startsAt'), 'days');
    let contentLink;
    if (levelsStatus === 'success') {
      if (plan) {
        if (activeLevel) {
          contentLink = `/level/${activeLevel.id}/unit/${user.currentUnit}`;
        } else {
          contentLink = `/level/${suggestedLevel || 6}`; // 6 is 1st level
        }
      } else {
        contentLink = '/payments';
      }
    }

    return (
      <ScrollView>
        <HeadingTitle>{`YOUR PROGRESS${activeLevel ? `, LEVEL ${activeLevel.name}` : ''}`}</HeadingTitle>
        <ProgressButton
          title={!!plan && `${plan} DAYS PLAN`}
          image={image}
          isLarge
          linkTo={contentLink}
          progress={user.progress}
          style={{
            marginTop: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} />

        {levelsStatus === 'loading' ? (
          <ActivityIndicator size="large" />
        ) : (
          <React.Fragment>
            {plan && (
              <Row
                style={{
                  borderTopWidth: 1,
                  borderTopColor: colors.grey,
                  padding: 20
                }}>
                <Text color="greyDarken">
                  <Text>1</Text>
                  {' of 12 units'}
                </Text>
                <Text color="greyDarken">
                  <Text>{currentProgressDay + 1}</Text>
                  {` of ${plan} days`}
                </Text>
              </Row>
            )}

            {plan && <DailyStreak currentProgressDay={currentProgressDay} streak={user.dailyStreak} />}

            {contentLink && (
              <Row>
                <NavLink
                  variant="red"
                  to={contentLink}>
                  {'Study now'}
                </NavLink>
              </Row>
            )}
          </React.Fragment>
        )}
      </ScrollView>
    );
  }
}
