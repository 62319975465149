import { Platform } from 'react-native';
export const colors = {
  purple: '#1B1447',
  red: '#ED1C24',
  blueDarken1: '#092481',
  blueDarken1rgba: alpha => `rgba(27, 20, 71, ${alpha || '1'})`,

  grey: '#DBDBE2',
  greyDarken: '#707070',

  white: '#fff',

  yellow: '#ffb700'

};

export const commonStyles = {
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: colors.grey
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: colors.grey
  },
  borderLeft: {
    borderLeftWidth: 1,
    borderLeftColor: colors.grey
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: colors.grey
  },

  webContainer: {
    maxWidth: 1092,
    width: '100%',
    marginHorizontal: 'auto'
  },

  elevation3: (() => {
    switch (Platform.OS) {
      case 'web':
        return { boxShadow: '0 0 3px 1px rgba(0, 0, 0, 0.2)' };
      case 'ios':
        return {
          shadowOffset: {
            width: 0,
            height: 3
          },
          shadowOpacity: 1,
          shadowRadius: 6,
          shadowColor: 'rgba(0, 0, 0, 0.2)'
        };
      case 'android':
        return { elevation: 3 };

      default:
        return null;
    }
  })(),

  paper: {
    // add elevation3
    padding: 20,
    borderRadius: 10,
    marginBottom: 20
  },

  playingAnimation: {
    animationName: 'pulsatingBackground',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
  }
};
