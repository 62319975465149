import React from 'react';
import {
  ScrollView, View, Image, StyleSheet, Platform
} from 'react-native';
import { colors } from '../../styles/theme';
import HeadingTitle from '../HeadingTitle';
import ProgressButton from '../ProgressButton';

import confetti from './confetti.png';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.white,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  confettiImage: {
    position: 'absolute',
    width: 495,
    height: 209,
  }
});


const FinishScreen = ({
  progress, children, title, showMedal, image
}) => (
  <ScrollView style={styles.wrapper}>
    <View style={styles.container}>
      <Image source={confetti} style={[styles.confettiImage, Platform.OS === 'web' && { margin: 'auto' }]} />
      {title && <HeadingTitle>{title}</HeadingTitle>}
      <ProgressButton style={{ marginTop: 60 }} isLarge progress={progress} showMedal={showMedal} image={image} />
      {children}
    </View>
  </ScrollView>
);

export default FinishScreen;
