import React, { useState, useEffect } from 'react';
import {
  TouchableOpacity, ActivityIndicator, Image, StyleSheet, View, ScrollView
} from 'react-native';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { commonStyles } from '../../styles/theme';
import { toggleModal, sendCertificate } from '../../store/reducers/certificate';
import Text from '../Text';
import Button from '../Button';
import Modal from '../Modal';
import goldMedal from './gold-medal.png';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    ...commonStyles.borderBottom
  },
  text: {
    padding: 20,
    textAlign: 'center'
  },
  levels: {
    height: 120,
    paddingHorizontal: 20
  },
  levelsOverlay: {
    position: 'absolute',
    height: 120
  },
  levelButton: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...commonStyles.borderBottom

  }
});

export default function CertificateModal() {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-shadow
  const [isVisible, status, levels, user] = useSelector(({ certificate, learn, user }) => [
    certificate.isVisible, certificate.sendStatus, learn.levels, user
  ], shallowEqual);
  const [selectedLevelId, setLevel] = useState();
  useEffect(() => {
    setLevel();
  }, [isVisible]);

  const selectedLevel = levels.find(({ id }) => id === selectedLevelId);

  let content;
  if (selectedLevelId) {
    switch (status) {
      case 'loading':
        content = <ActivityIndicator />;
        break;

      case 'success':
        content = (
          <>
            <View style={[styles.header, { borderBottomWidth: 0 }]}>
              <Image source={goldMedal} style={{ width: 39, height: 46 }} />
              <Text fontSize={18} style={{ marginTop: 10 }}>
                {'Сертификата е изпратен. Моля провери Входящата си поща или папка Спам.'}
              </Text>
            </View>
            <View style={styles.container}>
              <Button variant="red" onClick={() => dispatch(toggleModal())}>Close</Button>
            </View>
          </>
        );
        break;

      default:
        content = (
          <>
            <View style={[styles.header, { borderBottomWidth: 0 }]}>
              <Image source={goldMedal} style={{ width: 39, height: 46 }} />
              <Text fontSize={16} style={{ marginTop: 10 }} color="red">
                {'Certificate of Achievement'}
              </Text>
            </View>
            <View style={[styles.container, { paddingHorizontal: 20 }]}>
              <Text fontSize={26} align="center" style={{ marginVertical: 20 }}>{`${user.firstName || ''} ${user.lastName || ''}`}</Text>
              <Text color="greyDarken" align="center">Has completed the following course:</Text>
              <Text style={{ marginVertical: 10, maxWidth: 240 }} fontSize={18} align="center">
                {'English language proficiency level '}
                {selectedLevel ? selectedLevel.name : ''}
              </Text>

              <Button variant="red" onClick={() => dispatch(sendCertificate(selectedLevelId))}>Send</Button>
            </View>
          </>
        );
    }
  } else {
    content = (
      <>
        <View style={styles.header}>
          <Image source={goldMedal} style={{ width: 39, height: 46 }} />
          <Text fontSize={22} style={{ marginTop: 10 }}>
            {'Сертификати'}
          </Text>
        </View>

        <Text color="greyDarken" style={styles.text}>
          {'При завършване на 100% уроците от едно ниво, получаваш Сертификат за съответното ниво.'}
        </Text>

        <ScrollView style={styles.levels} persistentScrollbar>
          {levels && levels.filter(({ index }) => index < 20).map(level => {
            const isClickable = level.progress === 100;
            return (
              <TouchableOpacity
                key={level.id}
                style={styles.levelButton}
                onPress={() => {
                  if (isClickable) setLevel(level.id);
                }}>
                <Text color={isClickable ? 'color' : 'grey'}>{level.name}</Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>

        <Text color="greyDarken" style={styles.text}>
          {'Стани премиум потребител и вземи своя Сертификат.'}
        </Text>
        <View style={styles.container}>
          <Button variant="red" onClick={() => dispatch(toggleModal())}>Study now</Button>
        </View>
      </>
    );
  }

  return isVisible ? (
    <Modal
      onClose={() => dispatch(toggleModal())}>
      {content}
    </Modal>
  ) : null;
}
