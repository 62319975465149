import React from 'react';
import {
  Image, View, TouchableOpacity, StyleSheet
} from 'react-native';
import { colors, commonStyles } from '../../../styles/theme';
import Text from '../../../components/Text';
import { NavLink } from '../../../components/Button';
import LockIcon from '../../../icons/lock.png';
import { Link } from '../../../components/Link';
import ProgressBar from '../../../components/ProgressBar';

const styles = StyleSheet.create({
  container: {
    padding: 20,
    margin: 10,
    width: 160,
    display: 'flex',
    alignItems: 'center',
    ...commonStyles.elevation3
  },
  lockedIcon: {
    width: 22, height: 26, position: 'absolute', top: 126, left: 69
  }
});

const lockedLink = {
  flex: 1, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10
};

const ButtonStyles = { width: 120, marginTop: 10 };

const LockedLink = props => (
  <Link
    {...props}
    style={lockedLink}>
    <Image
      source={LockIcon}
      style={styles.lockedIcon} />
  </Link>
);

const LockedButton = ({ toggleModal }) => (
  <TouchableOpacity
    onPress={toggleModal}
    style={lockedLink}>
    <Image
      source={LockIcon}
      style={styles.lockedIcon} />
  </TouchableOpacity>
);

export default ({
  id, image, level, index, isAuthenticated, isActive, isLocked, toggleModal, progress
}) => {
  const locked = index > 1 && (!isAuthenticated || !isActive || isLocked);

  return (
    <View locked={locked} style={[styles.container, { backgroundColor: locked ? '#ECEBF0' : colors.white }]}>
      <Text align="center">
        {level.name}
        {' Unit '}
        {index}
      </Text>

      <ProgressBar progress={locked ? 0 : (progress || 0)} style={{ marginHorizontal: 0, marginTop: 16, width: 160 }} disabled={locked} mini />

      <Image
        source={{ uri: image }}
        style={{
          width: 160, height: 92, marginVertical: 10, opacity: locked ? 0.5 : 1
        }} />

      {locked && (isLocked && isActive ? (
        <LockedButton toggleModal={toggleModal} />
      ) : (
        <LockedLink to={isAuthenticated ? '/payments' : '/signup'} />
      ))}

      <NavLink
        to={`/level/${level.id}/unit/${id}`}
        variant={locked ? 'greyDarken' : 'red'}
        style={{ ...ButtonStyles, opacity: locked ? 0.5 : 1 }}
        disabled={locked}>
        {'Prepare'}
      </NavLink>
      <NavLink
        to={`/test/${level.id}/${index}`}
        variant={locked ? 'greyDarken' : 'red'}
        style={{ ...ButtonStyles, opacity: locked ? 0.5 : 1 }}
        disabled={locked}>
        {'Quiz'}
      </NavLink>
    </View>
  );
};
