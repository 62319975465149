import React from 'react';
import { Dimensions, Platform } from 'react-native';
import Text from '../../../../components/Text';
import Row from '../../../../components/Row';
import TextInput from '../../../../components/TextInput';

// TODO: add show answer button

export default class TextWithInput extends React.Component {
  state = {
    userAnswer: '',
    showHint: false,
    data: {}
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.data.correctAnswer) {
      const { formatedText } = props;

      const match = formatedText.match(/_.+_/g);
      if (!match) {
        return {
          data: {
            correctAnswer: null,
            textParts: null
          }
        };
      }

      const correctAnswer = match[0].replace(/_/g, '').trim();
      const textParts = formatedText.split(match[0]).map(part => part.trim());

      return {
        data: {
          correctAnswer,
          textParts
        }
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.state.userAnswer.trim() === this.state.data.correctAnswer && !this.props.isAnswered) {
      this.props.onAnswer();
    }
  }

  /* eslint-disable react/no-array-index-key */

  render() {
    const { userAnswer, showHint, data } = this.state;
    const { correctAnswer, textParts } = data;
    if (!correctAnswer) {
      return <Row><Text>{this.props.formatedText}</Text></Row>;
    }

    const { isAnswered } = this.props;
    const hasWordsBeforeInput = textParts[0].length > 0;
    const hasWordsAfterInput = textParts[1] && textParts[1].length > 1; // > 1 in case of interpunction
    const isLargeInput = !hasWordsBeforeInput && !hasWordsAfterInput;
    const showAnswer = isAnswered || userAnswer === correctAnswer || correctAnswer === null;

    return (
      <Row style={{ marginVertical: 20, width: '100%', flexWrap: 'wrap' }} justify="flex-start">
        {showAnswer ? (
          <Text>
            {this.props.formatedText.replace(/_/g, '').trim()}
          </Text>
        ) : (
          <React.Fragment>
            {!isLargeInput && [...textParts[0].split(' ')].map((word, i) => <Text key={i}>{`${word} `}</Text>)}
            <Row style={Platform.OS === 'web' && isLargeInput ? { width: '90%' } : {}}>
              <TextInput
                multiline
                inline
                style={{
                  width: isLargeInput ? (Platform.OS === 'web' ? '100%' : Dimensions.get('window').width - 100) : 170,
                  // borderWidth: 1,
                  // borderColor: colors.blueDarken1,
                  // color: colors.blueDarken1,
                }}
                color="blueDarken1"
                hint={correctAnswer}
                underlineColorAndroid="rgba(0, 0, 0, 0)"
                value={showHint ? correctAnswer : userAnswer}
                // ascii-capable disables cirilic keyboards
                // keyboardType="ascii-capable" // fix for .replace('’', "'") on iOS
                autoCapitalize="none"
                onChangeText={text => this.setState({ userAnswer: text.replace('’', "'") })} />
              {!hasWordsAfterInput && <Text>{textParts[1]}</Text>}
            </Row>
            {hasWordsAfterInput && [...textParts[1].split(' ')].map((word, i) => <Text key={i}>{` ${word}`}</Text>)}
          </React.Fragment>
        )}
      </Row>
    );
  }
}
