import React from 'react';
import {
  View, Text, StyleSheet
} from 'react-native';
import { colors } from '../../../../styles/theme';
import { Link } from '../../../../components/Link';
import Progressbar from '../../../../components/ProgressBar';

const styles = StyleSheet.create({
  container: {
    width: 150
  },
  unitLink: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const ModuleList = ({
  modules, level, id, videoSeen, newWordsSeen, grammarSeen, isAuthenticated, isActive, loading, activeModuleType
}) => (
  <View style={styles.container}>
    {!modules || loading ? (
      <View />
    ) : (
      <React.Fragment>
        {[
          {
            index: -1,
            type: 'SITUATION',
            progress: videoSeen ? 100 : 0
          },
          ...modules.map(module => {
            if (module.type === 'GRAMMAR' && grammarSeen) {
              return {
                ...module,
                progress: 100
              };
            }
            if (module.type === 'NEW_WORDS' && newWordsSeen) {
              return {
                ...module,
                progress: 100
              };
            }
            return module;
          })
        ].map((module, i) => {
          const disabled = module.type !== 'SITUATION' && (!isAuthenticated || !isActive);
          return (
            <Link
              key={module.type}
              to={isAuthenticated && isActive ? `/level/${level.id}/unit/${id}/${module.type.toLowerCase()}` : isAuthenticated ? '/payments' : '/signup'}
              style={{ color: colors.blueDarken1, opacity: disabled ? 0.5 : 1 }}>
              <View
                style={[
                  styles.unitLink,
                  activeModuleType === module.type.toLowerCase() && { backgroundColor: colors.blueDarken1rgba('0.15') },
                  i && { marginTop: -13 }
                ]}>
                <Text color="blueDarken1">
                  {module.type.charAt(0)}
                  {module.type.slice(1).toLowerCase().replace('_', ' ')}
                </Text>
              </View>
              <Progressbar progress={module.progress || 0} mini />
            </Link>
          );
        })}
      </React.Fragment>
    )}
  </View>
);

export default ModuleList;
