import React, { useState } from 'react';
import TextInput from '../../../../components/TextInput';
import Text from '../../../../components/Text';

function Voucher({ onVoucherUse, error, buttonText, placeholder }) {
  const [code, setCode] = useState('');

  let userFriendlyError = error;
  if (error) {
    if (error.includes('Object with code')) {
      userFriendlyError = 'Този код е невалиден';
    }
  }

  return (
    <>
      <TextInput
        style={{
          marginVertical: 20,
          width: 260,
        }}
        underlineColorAndroid="rgba(0, 0, 0, 0)"
        placeholder={placeholder}
        color="blueDarken1"
        onChangeText={text => {
          setCode(text);
        }}
        value={code}
        onButtonClick={() => onVoucherUse(code)}
        buttonText={buttonText}
      />
      <Text color="red" style={{ marginBottom: 20 }}>
        {userFriendlyError}
      </Text>
    </>
  );
}

export default Voucher;
