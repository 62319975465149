import React, { useState, useEffect } from 'react';
import {
  View,
  Platform,
  ActivityIndicator,
  TouchableOpacity,
  Linking,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadProducts,
  // requestPayment,
  validatePIN,
  requestEasyPayPurchase,
  useVoucher,
  clearEpay,
} from '../../store/reducers/payments';
import {
  loadStoreProducts,
  purchaseProduct,
} from '../../store/reducers/inapProducts';

import Text from '../../components/Text';
import Collapsable from '../../components/Collapsable';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextInput';
import { ScrollView } from '../../components/SimplePage';
import EasyPay from './components/EasyPay';
import Voucher from './components/Voucher';

import styles from './styles';

// import PaymentPage from './components/PaymentPage';

function PaymentPage({ history }) {
  const dispatch = useDispatch();
  const [selectedProductId, selectProduct] = useState();
  const [selectedPaymentMethod, selectPaymentMethod] = useState();
  const [showPINModal, setPinModal] = useState();
  const [PINNumber, setPIN] = useState();
  const { payments, inapProductStatus } = useSelector(
    ({ payments, inapProducts }) => ({
      payments,
      inapProductStatus: inapProducts.status,
    }),
  );
  const { paymentStatus, pinValidationStatus } = payments;

  // On component mount
  useEffect(() => {
    dispatch(loadProducts());
    if (Platform.OS !== 'web') {
      dispatch(loadStoreProducts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On prop change
  useEffect(() => {
    if (payments.activePayment) {
      history.push('/');
      return;
    }
    if (
      payments.paymentStatus === 'success' &&
      selectedProductId &&
      !showPINModal
    ) {
      selectProduct(null);
      setPinModal(false);
      // return {
      //   // phoneNumber: '',
      //   selectedProductId: null,
      //   showPINModal: true,
      // };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments.activePayment, payments.paymentStatus, selectedProductId]);

  let content;
  if (payments.epay.status === 'success' && payments.epay.epayId) {
    content = (
      <EasyPay epay={payments.epay} productPrice={payments.epay.amountPaid} />
    );
  } else if (selectedPaymentMethod === 'voucher') {
    content = (
      <Voucher
        error={payments.voucherError}
        placeholder="Номер на ваучер"
        buttonText="Добави"
        onVoucherUse={coupon =>
          dispatch(
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useVoucher({ coupon, product: selectedProductId }),
          )
        }
      />
    );
  } else {
    content = (
      <>
        {Platform.OS !== 'web' && (
          <>
            <Text color="greyDarken" style={{ marginBottom: 20 }}>
              {'Плати през'}
            </Text>
            {inapProductStatus === 'error' && (
              <Text color="red" style={{ marginBottom: 10 }}>
                {'Възникна проблем с покупката ви, моля, опитайте отново.'}
              </Text>
            )}
            {inapProductStatus === 'loading' ? (
              <ActivityIndicator />
            ) : (
              <Button
                style={{ width: 260 }}
                variant="red"
                onClick={() =>
                  dispatch(
                    purchaseProduct(
                      selectedProductId === 6 ? '30.days' : '60.days',
                    ),
                  )
                }>
                {Platform.OS === 'ios' ? 'App Store' : 'Play Store'}
              </Button>
            )}
          </>
        )}

        <Button
          style={{ width: 260 }}
          variant="red"
          onClick={() => selectPaymentMethod('voucher')}>
          {'Номер на ваучер'}
        </Button>

        {Platform.OS !== 'ios' && (
          <>
            <Button
              style={{ width: 260 }}
              variant="red"
              onClick={() =>
                dispatch(
                  requestEasyPayPurchase({
                    product: selectedProductId,
                  }),
                )
              }>
              {'Easy Pay'}
            </Button>

            <Text color="greyDarken">{'Добави код за отстъпка'}</Text>

            <Voucher
              error={payments.voucherError}
              placeholder="Код за отстъпка"
              buttonText="Easy Pay"
              onVoucherUse={coupon =>
                dispatch(
                  requestEasyPayPurchase({
                    product: selectedProductId,
                    coupon,
                  }),
                )
              }
            />
            {/* <TextInput
              style={{
                marginVertical: 20,
              }}
              underlineColorAndroid="rgba(0, 0, 0, 0)"
              placeholder="Код за отстъпка"
              color="blueDarken1"
              onChangeText={text => {
                setPIN(text);
              }}
              value={PINNumber}
            /> */}
            {payments.epay.status === 'error' && (
              <Text>{payments.epay.error || 'Грешка при плащането'}</Text>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <>
      {selectedProductId && (
        <Modal
          onClose={() => {
            selectProduct(null);
            selectPaymentMethod(null);
            if (payments.epayId) {
              dispatch(clearEpay());
            }
          }}>
          <View style={styles.modalContent}>
            {paymentStatus === 'loading' ? (
              <ActivityIndicator size="large" style={{ marginVertical: 40 }} />
            ) : (
              content
            )}

            <View>
              {Platform.OS === 'ios' && (
                <>
                  <Text
                    color="greyDarken"
                    fontSize={14}
                    align="center"
                    style={{ marginBottom: 10 }}>
                    За други методи на плащане, моля, отиди на
                  </Text>
                  <TouchableOpacity
                    onPress={() =>
                      Linking.openURL('https://bell.bg/login').catch(err =>
                        console.error('An error occurred', err),
                      )
                    }
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 10,
                    }}>
                    <Text>www.bell.bg</Text>
                  </TouchableOpacity>
                </>
              )}
              <Text fontSize={12} color="greyDarken">
                Услугата: Онлайн приложение за обучение по английски език
              </Text>
              <Text fontSize={12} color="greyDarken">
                Абонамент: Достъп до всички нива за 30 или 60 дни
              </Text>
              <Text fontSize={12} color="greyDarken">
                Телефон за контакти: 0877 088 988
              </Text>
              <Text fontSize={12} color="greyDarken">
                Ел.поща: info@bell.bg
              </Text>
            </View>
          </View>
        </Modal>
      )}

      {showPINModal && (
        <Modal
          onClose={() => {
            setPinModal(false);
          }}>
          <View style={styles.modalContent}>
            <Text color="greyDarken">
              {'Моля, въведи ПИН кода, който получи от SMS.'}
            </Text>
            <TextInput
              style={{
                marginVertical: 20,
              }}
              underlineColorAndroid="rgba(0, 0, 0, 0)"
              placeholder="PIN код"
              color="blueDarken1"
              onChangeText={text => {
                setPIN(text);
              }}
              value={PINNumber}
            />

            {pinValidationStatus === 'error' && (
              <Text fontSize={12}>Грешен PIN</Text>
            )}

            <Button
              onClick={() => {
                dispatch(
                  validatePIN({
                    pin: PINNumber,
                  }),
                );
              }}
              variant="red"
              textProps={{ color: 'white' }}
              style={{ width: 220, marginTop: 20 }}>
              {'Абонирай се сега'}
            </Button>
          </View>
        </Modal>
      )}

      <ScrollView style={styles.wrapper}>
        <View style={styles.header}>
          <Text fontSize={18} align="center">
            {'Become a Premium Member now!'}
          </Text>
        </View>

        <Text
          align="center"
          color="greyDarken"
          style={{ paddingVertical: 16, paddingHorizontal: 20 }}>
          {
            'Премиум членството ви дава достъп до всички уроци от ниво A1.1 до ниво C1.2, за период от 30 или 60 дни.'
          }
        </Text>

        <View style={styles.info}>
          <Text style={{ marginBottom: 6 }}>
            {'• '}
            <Text fontSize={20} style={{ fontWeight: 'bold' }}>
              120
            </Text>
            {' видео урока със субтитри'}
          </Text>
          <Text style={{ marginBottom: 6 }}>
            {'• '}
            <Text fontSize={20} style={{ fontWeight: 'bold' }}>
              120+
            </Text>
            {' обяснения на граматиката'}
          </Text>
          <Text style={{ marginBottom: 6 }}>
            {'• '}
            <Text fontSize={20} style={{ fontWeight: 'bold' }}>
              600+
            </Text>
            {' упражнения'}
          </Text>
          <Text style={{ marginBottom: 6 }}>• Достъп до всички нива</Text>
          <Text style={{ marginBottom: 6 }}>• Тестове за самопроверка</Text>
          <Text style={{ fontWeight: 'bold' }}>
            • Превод и пояснения на български език.
          </Text>
        </View>

        {payments.products
          .filter(({ price }) => price > 0)
          .sort((a, b) => a.price - b.price)
          .map(({ name, price, id }, i) => (
            <Collapsable
              key={i}
              title={name}
              highlight={price === 30 && 'Most Popular'}>
              <View style={styles.paymentItem}>
                <Text fontSize={30}>{`BGN ${price}`}</Text>
                <Button
                  onClick={() => {
                    selectProduct(id);
                  }}
                  variant="red"
                  style={{ marginTop: 20 }}>
                  {'Select'}
                </Button>
              </View>
            </Collapsable>
          ))}
      </ScrollView>
    </>
  );
}

export default PaymentPage;
