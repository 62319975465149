import React from 'react';
import { View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  }
});

export default function ({
  children, style, justify, align
}) {
  return (
    <View
      style={[
        styles.row,
        justify && { justifyContent: justify },
        align && { alignItems: align },
        style
      ]}>
      {children}
    </View>
  );
}
