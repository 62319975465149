import React from 'react';
import { ActivityIndicator, View, Platform } from 'react-native';
import HeadingTitle from '../../../../components/HeadingTitle';
import ProgressBar from '../../../../components/ProgressBar';
import Modal from '../../../../components/Modal';
import Row from '../../../../components/Row';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import HelpLine from '../../../../components/HelpLine';
import FinishScreen from '../../../../components/FinishScreen';

import Situation from '../Situation';
import Dictation from '../Dictation';
import Roleplay from '../Roleplay';
import Module from '../Module';

import ModuleList from '../ModuleList';
import UnitWrapper, { Wrapper } from '../UnitWrapper';
import { commonStyles } from '../../../../styles/theme';

const helpLines = {
  situation: [
    'Гледайте анимирания филм с или без субтитри толкова пъти, колкото ви е нужно, докато можете да го разберете без субтитири. След това практикувайте и се наслаждавайте да го гледате отново и отново, докато започнете да го разбирате така, както разбирате вашия собствен език.',
  ],
  study: ['Прочетете внимателно превода на епизода и поясненията към него.'],
  dictation: [
    'Чуйте избрания откъс от епизода и напишете това, което сте чули. С помощта на системата направете проверка на написаното.',
  ],
  role_play: [
    'Прочетете на глас репликите на избрания участник в диалога вместо него. След това прослушайте записа, за да направите сравнение с оригинала. По този начин можете да анализирате произношението си и да го усъвършенствате.',
  ],
  grammar: [
    'Прочетете текста. Граматиката в него е изложена ясно, разбираемо и с примери за използване на съответните форми. Практикувайте прочетенето в модули Writing и Translation.',
  ],
  writing: [
    'Exercise 1: Отбележете изреченията като правилни (Yes) или неправилни (No)',
    'Exercise 2: Попълнете празните места с правилните граматични форми',
    'Exercise 3: Довършете започнатите изречения.',
    'Exercise 4: Преведете изреченията от английски на български език.',
  ],
  new_words: [
    'Прочетете новите думи толкова пъти, колкото е нужно, за да ги запомните.',
    'Възползвайте се от услугата - My Dictionary (моят речник), за да записвате и други думи, които искате да научите и които не са включени в този модул. С лесния и бърз достъп до тях, можете да ги прочитате многократно всеки ден, да допълвате или изтривате вече научените думи.',
  ],
  translation: ['Преведете изреченията от български на английски език.'],
};

// const progressText = {
//   text: ({percent}) => ['You completed', `${percent}%`]
// }

export default class UnitPage extends React.Component {
  state = {
    isHelpVisible: false,
    scrollY: 0,
    progressPopup: null, // change to number
    medal: null,
  };

  componentDidMount() {
    this.props.loadUnit(this.props.match.params.unitId);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.unit &&
      this.props.unit &&
      prevProps.unit.progress &&
      this.props.unit.progress
    ) {
      if (prevProps.unit.progress < 50 && this.props.unit.progress >= 50) {
        if (this.props.isActive) {
          this.showProgressPopup(50, 'bronze');
        } else {
          this.props.history.push('/payments');
        }
      } else if (
        prevProps.unit.progress < 70 &&
        this.props.unit.progress >= 70
      ) {
        this.showProgressPopup(70, 'silver');
      } else if (
        prevProps.unit.progress < 100 &&
        this.props.unit.progress >= 100
      ) {
        this.showProgressPopup(100, 'gold');
      }
    }
  }

  showProgressPopup = (percent, medal) => {
    setTimeout(() => {
      this.setState({ progressPopup: percent, medal });
    }, 250);
  };

  toggleHelp = () => {
    this.setState(state => ({ isHelpVisible: !state.isHelpVisible }));
  };

  getActiveModuleType = () => this.props.match.params.module || 'situation';

  renderHeader = ({ moduleType, moduleProgress, unit }) => (
    <React.Fragment>
      <HeadingTitle
        isHelpVisible={this.state.isHelpVisible}
        onHelpClick={helpLines[moduleType] ? this.toggleHelp : null}>
        {`${moduleType.replace('_', ' ').toUpperCase()} - ${
          this.props.level.name
        } Unit ${unit.index}`}
      </HeadingTitle>
      <ProgressBar progress={moduleProgress} />
    </React.Fragment>
  );

  renderModule = () => {
    const { unit, isAuthenticated, history } = this.props;
    const moduleType = this.getActiveModuleType();
    // if (!unit || !unit.modules) {
    //   return <ActivityIndicator size="large" />;
    // }
    const activeModule = unit.modules.find(
      module => module.type.toLowerCase() === moduleType,
    );
    let moduleProgress = activeModule ? activeModule.progress : 0;
    let moduleComponent;
    const isNative = Platform.OS !== 'web';

    switch (moduleType) {
      case 'situation':
        moduleProgress = unit.videoSeen ? 100 : 0;
        return (
          <Situation
            {...unit}
            header={
              isNative &&
              this.renderHeader({ moduleType, moduleProgress, unit })
            }
            title={unit.title}
            isAuthenticated={isAuthenticated}
            historyPush={history.push}
          />
        );
      // break;

      case 'dictation':
        moduleComponent = <Dictation module={activeModule} unitId={unit.id} />;
        break;

      case 'role_play':
        moduleComponent = <Roleplay module={activeModule} unitId={unit.id} />;
        break;

      case 'new_words':
        moduleProgress = unit.newWordsSeen ? 100 : 0;
        moduleComponent = (
          <Module
            key={'new_words' + unit.id}
            module={activeModule}
            unitId={unit.id}
            newWordsSeen={unit.newWordsSeen}
          />
        );
        break;

      case 'grammar':
        moduleProgress = unit.grammarSeen ? 100 : 0;
        moduleComponent = (
          <Module
            key={'grammar' + unit.id}
            module={activeModule}
            unitId={unit.id}
            grammarSeen={unit.grammarSeen}
          />
        );
        break;

      default:
        moduleComponent = (
          <Module
            module={activeModule}
            unitId={unit.id}
            helpLines={helpLines[moduleType]}
          />
        );
    }

    return (
      <UnitWrapper
        onScroll={event => {
          this.setState({ scrollY: event.nativeEvent.contentOffset.y });
        }}
        scrollEnabled={moduleType !== 'role_play'}
        resetScrollToCoords={{ x: 0, y: this.state.scrollY }}
        extraScrollHeight={moduleType === 'dictation' ? 160 : 50}
        scrollEventThrottle={500}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="always">
        {isNative && this.renderHeader({ moduleType, moduleProgress, unit })}
        {!isNative && moduleType !== 'writing' && moduleType !== 'situation' && (
          <View style={{ padding: 20 }}>
            {helpLines[moduleType] &&
              helpLines[moduleType].map((line, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <HelpLine key={i} line={line} />
              ))}
          </View>
        )}
        {!isNative && moduleType !== 'writing' ? (
          <View style={[commonStyles.elevation3, commonStyles.paper]}>
            {moduleComponent}
          </View>
        ) : (
          moduleComponent
        )}
      </UnitWrapper>
    );
  };

  renderFinishScreen = () => {
    const { unit, level } = this.props;
    const { progressPopup, medal } = this.state;
    const isNative = Platform.OS !== 'web';

    return (
      <View
        style={{
          position: isNative ? 'absolute' : 'fixed',
          top: 0,
          left: 0,
          zIndex: 20000,
          flex: 1,
          height: '100%',
          width: '100%',
        }}>
        <FinishScreen
          // title="Well done!"
          image={{ uri: unit.image }}
          showMedal
          progress={progressPopup}>
          <Text fontSize={22} style={{ marginBottom: 30 }}>
            Well done!
          </Text>

          <Text color="greyDarken" style={{ marginHorizontal: 20 }}>
            {'You completed '}
            <Text color="blueDarken1">{`${progressPopup}% of unit ${
              unit.index
            }`}</Text>
            {`, level ${level.name} and earned ${medal} medal!`}
          </Text>

          <Button
            onClick={() => this.setState({ progressPopup: null, medal: null })}
            variant="red"
            style={{ marginTop: 20 }}>
            {'Keep going'}
          </Button>
        </FinishScreen>
      </View>
    );
  };

  render() {
    const { isAuthenticated, isActive, unit, level } = this.props;
    const { isHelpVisible, progressPopup } = this.state;
    const moduleHelpLines = helpLines[this.getActiveModuleType()];
    const isNative = Platform.OS !== 'web';

    return (
      <Wrapper>
        {unit && level && progressPopup && this.renderFinishScreen()}
        {unit && unit.modules ? (
          <React.Fragment>
            {isHelpVisible && (
              <Modal onClose={this.toggleHelp} title="Help">
                <View style={{ padding: 20 }}>
                  {moduleHelpLines &&
                    moduleHelpLines.map((line, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <HelpLine key={i} line={line} />
                    ))}
                </View>
              </Modal>
            )}

            {isNative ? (
              this.props.match.params.module ? (
                this.renderModule()
              ) : (
                <ModuleList
                  {...unit}
                  level={level}
                  isAuthenticated={isAuthenticated}
                  isActive={isActive || unit.index === 1}
                />
              )
            ) : (
              <View>
                {level && unit && !unit.loading && (
                  <HeadingTitle
                    style={{
                      marginTop: 60,
                      marginBottom: 20,
                      paddingHorizontal: 0,
                    }}>
                    <Text fontSize={30} style={{ fontWeight: 'normal' }}>
                      {`${level.name} UNIT ${unit.index}: `}
                      <Text
                        color="greyDarken"
                        fontSize={30}
                        style={{ fontWeight: 'normal' }}>
                        {unit.title}
                      </Text>
                    </Text>
                  </HeadingTitle>
                )}
                <Row align="flex-start" justify="space-between">
                  <ModuleList
                    {...unit}
                    activeModuleType={this.getActiveModuleType()}
                    level={level}
                    isAuthenticated={isAuthenticated}
                    isActive={isActive || unit.index === 1}
                  />
                  {this.renderModule()}
                </Row>
              </View>
            )}
          </React.Fragment>
        ) : (
          <ActivityIndicator size="large" />
        )}
      </Wrapper>
    );
  }
}
