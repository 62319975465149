import React from 'react';
import { Image } from 'react-native';
import Row from '../Row';
import Text from '../Text';
import helpFilledIcon from '../../icons/help-fill.png';

const HelpLine = ({ line }) => (
  <Row align="flex-start" style={{ marginBottom: 16, marginRight: 26 }} justify="flex-start">
    <Image source={helpFilledIcon} style={{ width: 23, height: 23, marginRight: 10 }} />
    <Text>{line}</Text>
  </Row>
);

export default HelpLine;
