/* eslint-disable no-param-reassign */

// export const shuffleArray = (array) => {
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1));
//     // eslint-disable-next-line no-param-reassign
//     [array[i], array[j]] = [array[j], array[i]];
//   }
// };

export const shuffleArray = (array) => {
  let i = 0;
  let j = 0;
  let temp = null;

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};
