/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Image, View, TouchableOpacity } from 'react-native';
import Text from '../Text';
import PlayIcon from '../../icons/play.png';
import styles from './style';

export default class SoundBar extends React.Component {
  render() {
    const { onEnded, onStarted, disabled } = this.props;
    return (
      <View style={[styles.bar, { width: 40, height: 24, backgroundColor: 'transparent' }]}>
        <audio
          preload="none"
          onEnded={onEnded}
          ref={ref => { this.player = ref; }}>
          <source src={this.props.source} type="audio/mpeg" />
        </audio>
        <TouchableOpacity onPress={() => {
          if (!disabled) {
            this.player.play();
            onStarted();
          }
        }}>
          <Image source={PlayIcon} style={{ width: 23, height: 23, marginRight: 10 }} />
        </TouchableOpacity>

        <Text color="white">{this.props.title}</Text>
      </View>
    );
  }
}
