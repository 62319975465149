import React from 'react';
import {
  TextInput
} from 'react-native';

export default class Input extends React.Component {
  state = {
    height: null
  }

  // componentDidMount() {
  //   this.fixHeight();
  // }

  componentDidUpdate() {
    this.fixHeight();
  }

  fixHeight = () => {
    if (this.props.multiline && this.input._node.scrollHeight > this.input._node.offsetHeight) {
      this.setState({ height: this.input._node.scrollHeight });
    }
  }

  render() {
    const { style, ...rest } = this.props;
    return <TextInput style={[...style, { height: this.state.height || 'auto', overflow: 'hidden' }]} ref={ref => { this.input = ref; }} {...rest} />;
  }
}
