import React from 'react';
import Text from '../../components/Text';
import SimplePage from '../../components/SimplePage';
import privacyPolicy from '../../static/privacyPolicy';


const PrivacyPage = () => (
  <SimplePage title="Политика за поверителност">
    <Text style={{ marginBottom: 10 }}>{privacyPolicy.title}</Text>
    {privacyPolicy.text.split('\n').map((textLine, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Text key={i} color="greyDarken" style={{ marginBottom: 10 }}>{textLine}</Text>
    ))}
  </SimplePage>
);

export default PrivacyPage;
