import React from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  Platform,
  StyleSheet,
} from 'react-native';
import TextInput from './input';
import { colors } from '../../styles/theme';
import whiteEyeIcon from '../../icons/eye-white.png';
import eyeIcon from '../../icons/eye.png';
import Button from '../Button';

const styles = StyleSheet.create({
  hint: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10,
  },
  underLined: {
    borderBottomWidth: 1,
    borderBottomColor: colors.purple,
    marginLeft: 10,
    flex: 1,
  },
  regular: {
    padding: 10,
    color: '#fff',
    borderRadius: 19,
    borderColor: '#fff',
    borderWidth: 1,
  },
  input: {
    // width: 250,
    paddingRight: 20,
  },
  button: {
    position: 'absolute',
    ...(Platform.OS !== 'android'
      ? {
          right: 0,
          top: 0,
          padding: 10,
        }
      : {
          right: 10,
          top: 0,
          paddingVertical: 14,
        }),
  },
});

export default class Input extends React.Component {
  state = {
    isHintVisible: false,
  };

  componentWillUnmount() {
    clearTimeout(this.showTimeout);
    this.showTimeout = null;
  }

  render() {
    const {
      value,
      hint,
      background,
      color,
      variant,
      style,
      inline,
      onButtonClick,
      buttonText,
      onChangeText,
      ...rest
    } = this.props;
    const { isHintVisible } = this.state;

    return (
      <View style={[style, Platform.OS === 'web' && { position: 'relative' }]}>
        <TextInput
          onChangeText={text => {
            if (!isHintVisible) {
              onChangeText(text);
            }
          }}
          {...rest}
          style={[
            styles.input,
            variant === 'underlined' ? styles.underLined : styles.regular,
            !!hint && { paddingRight: 40 },
            !!color && { color: colors[color], borderColor: colors[color] },
            Platform.OS === 'web'
              ? { width: '100%' }
              : !inline && { width: 250 },
          ]}
          value={isHintVisible ? hint : value}
        />
        {!!hint && (
          <TouchableOpacity
            style={[
              styles.hint,
              variant === 'underlined' && {
                top: Platform.OS === 'android' ? 0 : -14,
              },
            ]}
            onPressIn={() => {
              this.setState({ isHintVisible: true });
              this.showTimeout = setTimeout(() => {
                this.setState({ isHintVisible: false });
              }, 2000);
            }}
            // onPressOut={() => {
            //   this.setState({ isHintVisible: false });
            // }}
            variant={variant}>
            <Image
              source={background === 'dark' ? whiteEyeIcon : eyeIcon}
              style={{ width: 30, height: 17 }}
            />
          </TouchableOpacity>
        )}
        {onButtonClick && buttonText && (
          <Button variant="red" style={styles.button} onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </View>
    );
  }
}
