import React from 'react';
import moment from 'moment';
import { StyleSheet } from 'react-native';
import { commonStyles } from '../../../../styles/theme';
import Text from '../../../../components/Text';
import ExternalLink from '../../../../components/ExternalLink';

const styles = StyleSheet.create({
  codeContainer: {
    marginVertical: 20,
    paddingVertical: 10,
    width: 260,
    backgroundColor: '#E6E6E6',
    borderRadius: 20,
    ...commonStyles.elevation3,
  },
});
function EasyPay({ productPrice, epay }) {
  return (
    <>
      <Text fontSize={16} align="center" color="red" fontWeight="bold">
        {`Код за плащане на каса Easy Pay`}
      </Text>
      <Text
        style={styles.codeContainer}
        fontWeight="bold"
        fontSize={20}
        align="center">
        {epay.epayId}
      </Text>
      {epay.coupon && epay.coupon.value && (
        <Text color="greyDarken" style={{ marginVertical: 10 }}>
          {'Получена отстъпка: '}
          <Text color="greyDarken" fontWeight="bold">{`-${
            epay.coupon.value
          }%`}</Text>
        </Text>
      )}
      <Text color="greyDarken" style={{ marginVertical: 10 }}>
        {'Сума за плащане: '}
        <Text
          color="greyDarken"
          fontWeight="bold">{`${productPrice} лева.`}</Text>
      </Text>
      <Text color="greyDarken" style={{ marginVertical: 10 }}>
        {'Крайна дата за плащане: '}
        <Text color="greyDarken" fontWeight="bold">{`${moment(new Date())
          .add(5, 'days')
          .format('DD.MM.YYYY.')} год.`}</Text>
      </Text>
      <Text color="greyDarken" style={{ marginBottom: 10 }}>
        {
          'За активиране на абонаментен план е необходимо да заплатите на каса на Easy Pay с посочения код. След извършено плащане, Вашият профил ще бъде активиран.'
        }
      </Text>
      <Text>{'При възникнали проблеми, моля свържете се с нас'}</Text>
      <ExternalLink
        url="https://www.easypay.bg/site/?p=offices"
        color="red"
        style={{ marginVertical: 20 }}>
        {'Намери офис на Easy Pay'}
      </ExternalLink>
    </>
  );
}

export default EasyPay;
