/* eslint-disable react/no-unused-state */
import React from 'react';
import { ActivityIndicator } from 'react-native';
import dotProp from 'dot-prop-immutable';
import { colors } from '../../../styles/theme';
import { ScrollView } from '../../../components/SimplePage';
import ProgressBar from '../../../components/ProgressBar';
import FinishScreen from '../../../components/FinishScreen';
import Button from '../../../components/Button';
import Text from '../../../components/Text';
import { shuffleArray } from '../../../utils/array';

import Question from './Question';

const correctAnswersForPass = 7;

function getTestData(testUnit, testIndex) {
  return {
    levelTitle: dotProp.get(
      testUnit.modules[testIndex],
      'fragments.0.title'
    ),
    questions: shuffleArray(dotProp.get(
      testUnit.modules[testIndex],
      'fragments.0.fragmentParts'
    ))
  };
}


class Units extends React.Component {
  state = {
    questions: [],
    currentQustion: 0,
    correctAnswers: 0,
    levelTitle: null,
    testIndex: 0 // used only on unauthenticated test
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.questions.length && props.testUnit && !props.testUnit.loading) {
      if (props.match.params.levelId) {
        return {
          questions: shuffleArray(dotProp.get(
            props.testUnit.modules.find(module => dotProp.get(module, 'fragments.0.title') === (props.level.name || 'A1.1')),
            'fragments.0.fragmentParts'
          ))
        };
      }

      return getTestData(props.testUnit, state.testIndex);
    }

    return null;
  }

  componentDidMount() {
    this.props.loadUnit(52);
  }

  render() {
    const {
      testUnit, level, match, levels, userId
    } = this.props;
    const {
      questions, correctAnswers, levelTitle, testIndex
    } = this.state;


    const isInitialTest = !match.params.levelId;
    const currentLevel = isInitialTest && levels.find(({ name }) => name === levelTitle);

    if (!testUnit || testUnit.loading) {
      return (
        <ScrollView style={{ backgroundColor: colors.white }}>
          <ActivityIndicator size="large" style={{ marginTop: 40 }} />
        </ScrollView>
      );
    }

    const { currentQustion } = this.state;

    if (currentQustion === questions.length) {
      return (
        <FinishScreen
          title={!isInitialTest && `Quiz ${level.name} Unit ${match.params.unitIndex}`}
          image={{ uri: isInitialTest ? currentLevel.image : level.units[match.params.unitIndex].image }}
          showMedal
          progress={correctAnswers * 10}>
          {correctAnswers > 5 && !isInitialTest && <Text fontSize={22} style={{ marginBottom: 20 }}>Well done!</Text>}
          {isInitialTest && correctAnswers < correctAnswersForPass && (
            <Text fontSize={22} style={{ marginBottom: 20 }}>
              {`Your recommended level is ${levelTitle}`}
            </Text>
          )}
          <Text color="greyDarken">Your score is</Text>
          <Text>
            <Text>{`${correctAnswers} out of ${questions.length} `}</Text>
            <Text color="greyDarken">correct answers</Text>
          </Text>

          <Button
            onClick={isInitialTest && correctAnswers >= correctAnswersForPass && testIndex < testUnit.modules.length - 1
              ? () => {
                this.setState(state => ({
                  currentQustion: 0,
                  correctAnswers: 0,
                  testIndex: state.testIndex + 1,
                  ...getTestData(testUnit, state.testIndex + 1)
                }));
              }
              : () => {
                if (!userId) {
                  this.props.setSuggestedLevel(levelTitle);
                  this.props.history.push('/signup');
                } else {
                  this.props.history.push(`/level/${match.params.levelId}`);
                }
              }
            }
            variant="red"
            style={{ marginTop: 20 }}>
            {!isInitialTest || correctAnswers >= correctAnswersForPass ? 'Keep going' : "Let's study" }
          </Button>
        </FinishScreen>
      );
    }


    return (
      <ScrollView style={{ backgroundColor: colors.white }}>
        <ProgressBar progress={currentQustion * 10} />
        <Question
          key={currentQustion}
          question={questions[currentQustion]}
          questionIndex={currentQustion}
          onAnswer={(isCorrect) => {
            this.setState(state => ({
              currentQustion: state.currentQustion + 1,
              correctAnswers: state.correctAnswers + (isCorrect ? 1 : 0),
            }));
          }} />
      </ScrollView>
    );
  }
}


export default Units;
