import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

/*
 * Camelise a string - from my_var to myVar
 */
export function camelise(string) {
  return string.replace(/[-_\s]+(.)?/g, (match, chr) => chr ? chr.toUpperCase() : '');
}

/*
 * Decamelise a string - from myVar to my_var
 */
export function decamelise(string) {
  if (string === 'userID') return 'user_ID'; // Add all exceptions

  return string.replace(/([A-Z])/g, (match, chr) => '_' + (chr ? chr.toLowerCase() : ''));
}


/*
 * Go through entire object and change keys
 */
export function adaptKeys(data, direction) {
  return isArray(data)
    // if data is array go trough each item and try to adapt keys
    ? data.map((childData) => childData && typeof childData === 'object' ? adaptKeys(childData, direction) : childData)
    // if data is object change the key and return value, if value is also object rerun adaptKeys
    : Object.entries(data).reduce((newData, [key, value]) => ({
      ...newData,
      [direction === 'receive' ? camelise(key) : decamelise(key)]: (value && typeof value === 'object') ? adaptKeys(value, direction) : value
    }), {});
}

export function extractAllErrors(payload) {
  const { response, error } = payload;

  if (error) return [error];

  return response
    && isObject(response)
    && Object.values(response)
      .reduce((allErrors, errorField) => ([
        ...allErrors,
        ...errorField
      ]), [])
      .map(err => {
        switch (err) {
          case 'Enter a valid email address.':
            return 'Въведи валиден e-mail адрес.';

          case 'Unable to log in with provided credentials.':
            return 'Грешно потребителско име или парола. Опитай отново.';

          case 'This password is entirely numeric.':
            return 'Паролата не трябва са съдържа само цифри.';

          case 'This password is too short. It must contain at least 8 characters.':
            return 'Тази парола е твърде кратка. Паролата трябва да съдържа минимум 8 символа.';

          case 'This password is too common.':
            return 'Тази парола е твърде лесна.';

          case 'A user is already registered with this e-mail address.':
            return 'Вече има регистриран потребител с този e-mail адрес.';

          case 'The password is too similar to the last name.':
            return 'Паролата съвпада с потребителското име. Избери друга парола.';

          default:
            return err;
        }
      });
}
