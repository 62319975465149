import React from 'react';
import Text from '../Text';

function ExternalLink({ url, children, style, ...rest }) {
  return (
    <a href={url} target="_blank" style={style}>
      <Text {...rest}>{children || url}</Text>
    </a>
  );
}

export default ExternalLink;
