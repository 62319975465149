// @flow

import React, { Component } from 'react';
import {
  TouchableOpacity,
  View,
  Text,
  ActivityIndicator,
  Animated,
  Image,
  TouchableWithoutFeedback,
  // eslint ignore next $FlowFixMe
} from 'react-native';
// import Slider from 'react-native-slider';
import playIcon from '../../icons/play-large.png';
import pauseIcon from '../../icons/pause-filled.png';
import styles from './MediaControlsStyles';
import { humanizeVideoDuration, noop } from './Utils';
import PLAYER_STATES from './Constants';

class MediaControls extends Component {
  static defaultProps = {
    isFullScreen: false,
    isLoading: false,
    mainColor: 'rgba(0, 0, 0, 0.9)',
    onReplay: noop,
    onSeeking: noop,
  };

  state = {
    opacity: new Animated.Value(1),
    isVisible: true,
  };

  // componentDidMount() {
  // this.fadeOutControls(5000);
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.playerState === PLAYER_STATES.ENDED) {
  //     this.fadeInControls(false);
  //   }
  // }

  onReplay = () => {
    this.fadeOutControls(5000);
    this.props.onReplay();
  };

  onPause = () => {
    const { playerState, onPaused } = this.props;
    const { PLAYING, PAUSED } = PLAYER_STATES;
    switch (playerState) {
      case PLAYING: {
        this.cancelAnimation();
        break;
      }
      case PAUSED: {
        this.fadeOutControls(5000);
        break;
      }
      default:
        break;
    }

    const newPlayerState = playerState === PLAYING ? PAUSED : PLAYING;
    return onPaused(newPlayerState);
  };

  setLoadingView = () => <ActivityIndicator size="large" color="#FFF" />;

  setPlayerControls = playerState => {
    const icon = this.getPlayerStateIcon(playerState);
    const pressAction =
      playerState === PLAYER_STATES.ENDED ? this.onReplay : this.onPause;
    return (
      <TouchableOpacity style={[styles.playButton]} onPress={pressAction}>
        <Image source={icon} style={styles.playIcon} />
      </TouchableOpacity>
    );
  };

  getPlayerStateIcon = playerState => {
    switch (playerState) {
      case PLAYER_STATES.PAUSED:
      case PLAYER_STATES.ENDED:
        // eslint ignore next $FlowFixMe
        return playIcon;
      case PLAYER_STATES.PLAYING:
        // eslint ignore next $FlowFixMe
        return pauseIcon;
      default:
        return null;
    }
  };

  cancelAnimation = () => {
    this.state.opacity.stopAnimation(() => {
      this.setState({ isVisible: true });
    });
  };

  toggleControls = () => {
    // value is the last value of the animation when stop animation was called.
    // As this is an opacity effect, I (Charlie) used the value (0 or 1) as a boolean
    this.state.opacity.stopAnimation(value => {
      this.setState({ isVisible: !!value });
      return value ? this.fadeOutControls() : this.fadeInControls();
    });
  };

  fadeOutControls = delay => {
    Animated.timing(this.state.opacity, {
      toValue: 0,
      duration: 300,
      delay,
    }).start(result => {
      /* I noticed that the callback is called twice, when it is invoked and when it completely finished
      This prevents some flickering */
      if (result.finished) {
        this.setState({ isVisible: false });
      }
    });
  };

  fadeInControls = loop => {
    this.setState({ isVisible: true });
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: 300,
      delay: 0,
    }).start(() => {
      if (loop) {
        this.fadeOutControls(5000);
      }
    });
  };

  // dragging = (value) => {
  //   const { onSeek, playerState } = this.props;

  //   onSeek(value);
  //   // if (playerState === PLAYER_STATES.PAUSED) return;

  //   // this.onPause();
  // };

  seekVideo = value => {
    this.props.onSeek(value);
  };

  renderControlls = () => {
    const { duration, playerState, progress } = this.props;

    return playerState === PLAYER_STATES.PLAYING ? (
      <View style={[styles.controlsRow, styles.progressContainer]}>
        <View style={styles.progressColumnContainer}>
          <View style={[styles.timerLabelsContainer]}>
            <Text style={styles.timerLabel}>
              {humanizeVideoDuration(progress)}
            </Text>
            <Text style={styles.timerLabel}>
              {humanizeVideoDuration(duration)}
            </Text>
          </View>
          {/* <Slider
            style={styles.progressSlider}
            // onValueChange={this.dragging}
            onSlidingComplete={this.seekVideo}
            maximumValue={Math.floor(duration)}
            value={Math.floor(progress)}
            trackStyle={styles.track}
            thumbStyle={[styles.thumb, { borderColor: mainColor }]}
            minimumTrackTintColor={mainColor} /> */}
        </View>
      </View>
    ) : (
      <View style={[styles.controlsRow, styles.progressContainer]}>
        <View style={styles.subtitlesContainer}>
          <Text style={styles.subtitlesText}>Subtitles</Text>
          <TouchableOpacity
            style={styles.toggleContainer}
            onPress={this.props.toggleSubtitles}>
            <View
              style={[
                styles.toggle,
                { marginLeft: this.props.subtitles ? 0 : -28 },
              ]}>
              <Text style={styles.toggleText}>ON</Text>
              <View style={styles.toggleButton} />
              <Text style={styles.toggleText}>OFF</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  renderControls() {
    const { isLoading, playerState, toolbar } = this.props;

    // this let us block the controls
    if (!this.state.isVisible) {
      return null;
    }

    // eslint ignore next $FlowFixMe
    return (
      <View style={styles.container}>
        <View style={[styles.controlsRow, styles.toolbarRow]}>{toolbar}</View>
        <View style={[styles.controlsRow]}>
          {isLoading
            ? this.setLoadingView()
            : this.setPlayerControls(playerState)}
        </View>
        {this.renderControlls()}
      </View>
    );
  }

  render() {
    return (
      <TouchableWithoutFeedback onPress={this.toggleControls}>
        <Animated.View
          style={[
            styles.container,
            { opacity: this.state.opacity },
            this.props.style,
          ]}>
          {this.renderControls()}
        </Animated.View>
      </TouchableWithoutFeedback>
    );
  }
}

export default MediaControls;
