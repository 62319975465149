import {
  LOAD_ACCOUNT, LOAD_ACCOUNT_SUCCESS, REGISTER_SUCCESS, LOG_IN_SUCCESS, LOG_OUT
} from './authentication';

export const LOAD_USER_PROFILE = 'user.LOAD_USER_PROFILE';
export const LOAD_USER_PROFILE_SUCCESS = 'user.LOAD_USER_PROFILE_SUCCESS';
export const LOAD_USER_PROFILE_FAIL = 'user.LOAD_USER_PROFILE_FAIL';

export const PATCH_USER_PROFILE = 'user.PATCH_USER_PROFILE';
export const PATCH_USER_PROFILE_SUCCESS = 'user.PATCH_USER_PROFILE_SUCCESS';
export const PATCH_USER_PROFILE_FAIL = 'user.PATCH_USER_PROFILE_FAIL';

// export function loadProfile() {
//   return {
//     type: LOAD_USER_PROFILE,
//     fetchParams: {
//       path: '/user',
//       success: LOAD_USER_PROFILE_SUCCESS,
//       fail: LOAD_USER_PROFILE_FAIL
//     }
//   };
// }

export function updateProfile(payload) {
  return {
    type: PATCH_USER_PROFILE,
    payload,
    fetchParams: {
      path: '/user/',
      method: 'PATCH',
      success: PATCH_USER_PROFILE_SUCCESS,
      fail: PATCH_USER_PROFILE_FAIL
    }
  };
}


const initialState = {
  id: null,
  username: null,
  progress: null,
  currentUnit: null,
  activeLevels: null,
  avatar: null,
  phone: null,
  testCompleted: null,
  testLevel: null,
  email: null,
  firstName: null,
  lastName: null,
  initialLevelSelected: false,
  status: 'loading'
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: null
      };

    case REGISTER_SUCCESS:
    case LOG_IN_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        status: null
      };

    case LOAD_ACCOUNT:
    case LOAD_USER_PROFILE:
    case PATCH_USER_PROFILE:
      return {
        ...state,
        status: 'loading'
      };

    case LOAD_USER_PROFILE_SUCCESS:
    case PATCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: null
      };

    case LOG_OUT:
      return initialState;

    default:
      return state;
  }
}
