import React from 'react';
import Button from '../../../../components/Button';
import Row from '../../../../components/Row';
import Text from '../../../../components/Text';
import Html from '../../../../components/NativeHtml';
import { stripHtml } from '../../../../utils/htmlParsers';
import { colors } from '../../../../styles/theme';
import FragmentPartWrapper from './FragmentPartWrapper';

const getButtonStyle = (isAnswered, isCorrect) => ({
  borderWidth: 1,
  borderColor: colors.blueDarken1,
  width: 116,
  margin: 10,
  ...(isAnswered
    ? isCorrect
      ? {
          backgroundColor: '#64dd17',
          borderColor: 'transparent',
        }
      : {
          // backgroundColor: colors.red,
          // borderColor: 'transparent',
        }
    : {}),
});

export default class YesNoQuestion extends React.Component {
  state = {
    userAnswer: null,
  };

  static getDerivedStateFromProps(props) {
    if (props.data.isAnswered) {
      return { userAnswer: props.data.correct };
    }

    return null;
  }

  onAnswer = userAnswer => () => {
    const { data } = this.props;
    if (data.isAnswered) {
      return;
    }

    this.setState({
      userAnswer,
    });

    // if (userAnswer === data.correct) {
    this.props.onAnswer();
    // }
  };

  render() {
    const { data } = this.props;
    const { userAnswer } = this.state;
    const hasUserAnswered = userAnswer !== null;

    return (
      <FragmentPartWrapper>
        {data.text && <Text align="center">{stripHtml(data.text)}</Text>}
        <Row justify="center">
          <Button
            onClick={this.onAnswer(true)}
            style={getButtonStyle(hasUserAnswered, data.correct)}
            textProps={{
              align: 'center',
              color: hasUserAnswered && data.correct ? 'white' : null,
            }}>
            {'YES'}
          </Button>
          <Button
            onClick={this.onAnswer(false)}
            style={getButtonStyle(hasUserAnswered, !data.correct)}
            textProps={{
              align: 'center',
              color: hasUserAnswered && !data.correct ? 'white' : null,
            }}>
            {'NO'}
          </Button>
        </Row>

        {!!data.hint && hasUserAnswered && (
          <Row justify="center">
            <Html html={data.hint} secondLine prefix="Correct: " />
          </Row>
        )}
      </FragmentPartWrapper>
    );
  }
}
