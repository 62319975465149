import React from 'react';
import {
  ScrollView as RNScrollView, View, StyleSheet, Platform
} from 'react-native';
import HeadingTitle from '../HeadingTitle';
import { colors, commonStyles } from '../../styles/theme';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.white,
    height: '100%'
  },
  wrapperContainer: commonStyles.webContainer,
  content: { padding: 30 }
});

export const ScrollView = ({ children }) => Platform.OS === 'web' ? (
  <View style={styles.wrapper}>
    <View style={styles.wrapperContainer}>{children}</View>
  </View>
) : (
  <RNScrollView style={styles.wrapper}>
    <View style={{ paddingBottom: 60 }}>{children}</View>
  </RNScrollView>
);

export const Content = ({ children }) => (
  <View style={styles.content}>
    {children}
  </View>
);

const SimplePage = ({ children, title }) => (
  <ScrollView>
    <HeadingTitle>{title}</HeadingTitle>
    <Content>
      {children}
    </Content>
  </ScrollView>
);

export default SimplePage;
