import React from 'react';
import {
  View, TouchableOpacity, Image, StyleSheet
} from 'react-native';
import { colors } from '../../styles/theme';
import checkIcon from '../../icons/checked-small.png';
import Row from '../Row';

// const UncheckedBox = styled.View`
//   width: 16px;
//   height: 16px;
//   border-width: 1px;
//   border-color: ${colors.white};
// `;

// const CheckButton = styled.TouchableOpacity`
//   padding: 10px;
// `;

// const CheckedBox = styled.View`
//   width: 16px;
//   height: 16px;
//   background-color: ${colors.white};
// `;

// const CheckIcon = styled.Image`
//   width: 20px;
//   height: 15px;
// `;

const styles = StyleSheet.create({
  unchecked: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderColor: colors.white
  },
  checkButton: {
    padding: 10
  },
  checked: {
    width: 16,
    height: 16,
    backgroundColor: colors.white
  },
  icon: {
    width: 20,
    height: 15,
  }
});

const Checkbox = ({
  checked, children, onClick, flush
}) => (
  <Row style={flush !== true ? { marginBottom: 10 } : {}}>
    <TouchableOpacity onPress={onClick} style={styles.checkButton}>
      {checked ? (
        <View style={styles.checked}>
          <Image style={styles.icon} source={checkIcon} />
        </View>
      ) : (
        <View style={styles.unchecked} />
      )}
    </TouchableOpacity>
    {children}
  </Row>
);

export default Checkbox;
