import React from 'react';
import { Provider } from 'react-redux';
import Wrapper from './containers/Wrapper';
import configureStore from './store';

const store = configureStore({});

const App = () => (
  <Provider store={store}>
    <Wrapper />
  </Provider>
);

export default App;
