import React from 'react';
import Text from '../../components/Text';
import SimplePage from '../../components/SimplePage';
import terms from '../../static/terms';

const TermsPage = () => (
  <SimplePage title="Общи условия">
    <Text style={{ marginBottom: 10 }}>{terms.title}</Text>
    {terms.text.split('\n').map((textLine, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Text key={i} color="greyDarken" style={{ marginBottom: 10 }}>{textLine}</Text>
    ))}
  </SimplePage>
);

export default TermsPage;
