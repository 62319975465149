/* eslint-disable react/no-array-index-key */
import React from 'react';
import Collapsable from '../../components/Collapsable';
import Text from '../../components/Text';
import { ScrollView, Content } from '../../components/SimplePage';
import HeadingTitle from '../../components/HeadingTitle';

// TODO: parse html tags and add bolded lines

const HelpPage = () => (
  <ScrollView>
    <HeadingTitle>Често задавани въпроси</HeadingTitle>
    <Collapsable
      title="Моят профил - Информация за профила ви">
      <Content>
        {[
          'Ако имате нужда от повече информация за профила си, можете да влезете в Начална страница и след това в My Profile ще намерите информация за състоянието на абонамента ви - за кое ниво сте абонирани, да проверите личните си данни и прогрес.',
          'Тук може да промените и името/фамилията, към които е насочен акаунтът. Не забравяйте да защитите промените си с избрана от вас парола.'
        ].map((line, i) => <Text key={i} align="left" style={{ marginVertical: 6 }}>{line}</Text>)}
      </Content>
    </Collapsable>

    <Collapsable
      title="Курс по английски език - Вашият език на курса">
      <Content>
        {[
          'Разбира се, че искате да научите английски, но знаем, че понякога е по-лесно да се разберат концепциите на курса, ако обясненията и субтитрите са налични и на родния ви език.',
          'Ето защо BELL - Bulgarian-English Language Learning  е разработен на български език.'
        ].map((line, i) => <Text key={i} align="left" style={{ marginVertical: 6 }}>{line}</Text>)}
      </Content>
    </Collapsable>

    <Collapsable
      title="Как може да започна да използвам BELL?">
      <Content>
        {[
          'Добре дошли в Bulgarian-English Language Learning. Ако искате да учите английски, сте дошли на правилното място!',
          'Имаме стотици страници с високо-качествен видео и аудио материал, както и интерактивни упражнения, които да ви помогнат с граматиката и лексиката. Ето три начина да намерите страница, където може да започнете:'
        ].map((line, i) => <Text key={i} align="left" style={{ marginVertical: 6 }}>{line}</Text>)}
        {[
          'Тествай нивото си  е за тези, които искат да проверят нивото си на познания.',
          'Запишете се е, за да разгледате апликацията и да видите различните материали, които предлагаме.',
          'Използвайте Търсачката в горния десен ъгъл на всяка страница, за да намерите вашето ниво, модули или речник, за които искате да научите повече.'
        ].map((line, i) => (
          <Text key={i} align="left" style={{ marginVertical: 6, paddingLeft: 20 }}>
            {i + 1}
            {'. '}
            {line}
          </Text>
        ))}
      </Content>
    </Collapsable>

    <Collapsable
      title="Как може да си подобря правописа?">
      <Content>
        {[
          'За да подобрите вашия правопис, е нужно да четете и пишете колкото се може повече. В секцията диктовки и писане ще намерите перфектните упражнения.',
          'BELL ви предлага разнообразни упражнения, за да подобрите вашия правопис самостоятелно.',
          'Каквото и да правите, опитвайте се да прекарвате поне 15 минути на ден, пишейки и четейки.',
          'Помнете, че също е важно да пишете с организиран подход. Започнете първо с четене от Study, след което слушайте внимателно избрания откъс в Dictation, напишете продиктуваните изречения (не изпускайте пунктуацията). След това започнете едно по едно упражненията в Writing.',
          'Ако се нуждаете от помощ, използвайте Help Eye, колкото ви е нужно.',
          'Изследванията показват, че добрите писатели постоянно разглеждат и редактират работата си, така че това е добър модел.'
        ].map((line, i) => <Text key={i} align="left" style={{ marginVertical: 6 }}>{line}</Text>)}
      </Content>
    </Collapsable>

    <Collapsable
      title="Как мога да подобря моята лексика?">
      <Content>
        {[
          'За да подобрите вашата лексика са важни три неща:'
        ].map((line, i) => <Text key={i} align="left" style={{ marginVertical: 6 }}>{line}</Text>)}
        {[
          'Гледайте нови и познати думи и фрази в контекст',
          'Записвайте новите думи във вашия собствен речник - Dictionary (“Ab” иконата или в горния десен ъгъл на всяка страница)',
          'Систематично упражнявайте, поправяйте и преглеждайте новия материал.'
        ].map((line, i) => (
          <Text key={i} align="left" style={{ marginVertical: 6 }}>
            {'• '}
            {line}
          </Text>
        ))}
        {[
          'Study има страхотни материали за четене и писане, които ще ви помогнат с първата точка. Колкото повече четете   списания, вестници, кратки разкази, романи, стихотворения ... впрочем, който и жанр да изберете и предпочитате да четете - толкова по-добре. Може да намерите неизчерпаеми материали в интернета, разбира се. Ние силно препоръчваме да започнете (ако все още не сте) личен лексикален речник. Организирайте го по тематика (спортове, работа, финанси...) и добавяйте нови фрази докато слушате, гледате и четете на английски. Използвайте го за да пробвате колко добре запомняте материала.'
        ].map((line, i) => <Text key={i} align="left" style={{ marginVertical: 6 }}>{line}</Text>)}
      </Content>
    </Collapsable>

    <Collapsable
      title="Какво да направя ако не мога да видя част от материала или ако някой упражнения не работят за мене?">
      <Content>
        {[
          'Някои от материалите на BELL се нуждаят от нов браузър. Ако имате проблеми, първата стъпка е да проверите дали използвате най-новия браузър. Ако това не реши проблема, опитайте да влезете в сайта на друго устройство, например лаптоп или компютър. Ако това реши проблема, трябва да знаете, че проблемът е свързан с първото устройство, което използвахте.'
        ].map((line, i) => <Text key={i} align="left" style={{ marginVertical: 6 }}>{line}</Text>)}
      </Content>
    </Collapsable>

    <Collapsable
      title="Мога ли да използвам апликацията Bell без интернет?">
      <Content>
        {[
          'Не, интернет е необходим за комуникация със сървъра и осигуряване на най-добро качество на услугата.'
        ].map((line, i) => <Text key={i} align="left" style={{ marginVertical: 6 }}>{line}</Text>)}
      </Content>
    </Collapsable>
  </ScrollView>
);

export default HelpPage;
