import React from 'react';
import { View, Dimensions, Platform } from 'react-native';
import Html from '../../../../components/NativeHtml';

const NotActionable = ({ data }) => (
  <View style={Platform.OS !== 'web' ? { width: Dimensions.get('window').width - 80 } : {}}>
    {data.text ? <Html html={data.text} /> : null}
    {data.translation ? <Html html={data.translation} secondLine /> : null}
  </View>
);

export default NotActionable;
