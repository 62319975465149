/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import {
  View, Image, TouchableOpacity, StyleSheet
} from 'react-native';
import { connect } from 'react-redux';
import { commonStyles } from '../../../../styles/theme';
import {
  answerFragment,
} from '../../../../store/reducers/learn';
import checkIcon from '../../../../icons/checked.png';
import microphoneIcon from '../../../../icons/microphone-blue.png';

import Text from '../../../../components/Text';
import Row from '../../../../components/Row';
import SoundBar from '../../../../components/SoundBar';
import { stripHtml } from '../../../../utils/htmlParsers';
import AudioRecorder from '../../../../utils/audioRecorder';

const styles = StyleSheet.create({
  recordingAnimation: {
    animationName: 'pulsatingOpacity',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
  },
  playingAnimation: commonStyles.playingAnimation
});

class Roleplay extends React.Component {
  state = {
    recordingFragment: null,
    playingFragment: null
  }

  recorder = null

  isRecording = false

  isPlaying = false

  recorderTimeout = null

  startRecording = async (fragment, fPart) => {
    this.isRecording = true;
    this.recorder = await AudioRecorder();
    this.recorder.start();
    this.setState({ recordingFragment: fPart.id });
    this.recorderTimeout = setTimeout(() => {
      this.stopRecording(fragment, fPart);
    }, 10000);
  }

  stopRecording = async (fragment, fPart) => {
    const { module, unitId, answer } = this.props;

    clearTimeout(this.recorderTimeout);
    this.isRecording = false;
    this.setState({ recordingFragment: null });
    const audio = await this.recorder.stop();
    audio.play();

    answer({
      unitId,
      moduleIndex: module.index,
      fragmentIndex: fragment.index,
      fragmentPartId: fPart.id,
      fragmentPartIndex: fPart.index
    });

    setTimeout(() => {
      this.recorder = null;
    }, 100);
  }

  render() {
    const { module } = this.props;
    const { recordingFragment, playingFragment } = this.state;

    return (
      <View>
        {module.fragments.map(fragment => (
          <Row justify="flex-start" align="flex-start" style={{ marginVertical: 12, position: 'relative' }} key={fragment.index}>
            <Text style={{ width: 100, marginTop: 16 }}>{fragment.title}</Text>
            <View style={{ width: 'calc(100% - 120px)' }}>
              {fragment.fragmentParts.map(fPart => {
                const isThisFragmentRecording = fPart.id === recordingFragment;
                const isThisFragmentPlaying = fPart.id === playingFragment;

                return (
                  <Row justify="flex-start" align="center" style={[{ paddingVertical: 6 }, isThisFragmentPlaying && styles.playingAnimation]} key={fPart.id}>
                    {fPart.isAnswered ? (
                      <Image
                        style={{
                          width: 13, height: 10, marginRight: 15, marginVertical: 7, marginLeft: 12
                        }}
                        source={checkIcon} />
                    ) : (
                      <SoundBar
                        onEnded={() => {
                          this.setState({ playingFragment: null });
                          this.isPlaying = false;
                        }}
                        onStarted={() => {
                          this.setState({ playingFragment: fPart.id });
                          this.isPlaying = true;
                        }}
                        disabled={this.isPlaying || this.isRecording}
                        source={fPart.voice}
                        key={fPart.id}
                        title={fPart.fragmentTitle} />
                    )}
                    <Text>{stripHtml(fPart.text)}</Text>
                    <TouchableOpacity onPress={() => {
                      if (!this.isRecording && !this.isPlaying) {
                        this.startRecording(fragment, fPart);
                      } else if (isThisFragmentRecording) {
                        this.stopRecording(fragment, fPart);
                      }
                    }}>
                      <Image source={microphoneIcon} style={[{ width: 15, height: 26, marginLeft: 10 }, isThisFragmentRecording && styles.recordingAnimation]} />
                    </TouchableOpacity>
                    {isThisFragmentRecording && <Text color="red" fontSize={12} style={{ fontStyle: 'italic', marginLeft: 10 }}>- recording</Text>}
                  </Row>
                );
              })}
            </View>
          </Row>
        ))}
      </View>
    );
  }
}

export default connect(
  () => ({}),
  { answer: answerFragment }
)(Roleplay);
