import { StyleSheet } from 'react-native';
import { colors } from '../../styles/theme';

export default StyleSheet.create({
  bar: {
    backgroundColor: colors.blueDarken1rgba(0.4),
    borderRadius: 20,
    height: 40,
    width: 270,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playButton: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
  progress: {
    backgroundColor: colors.purple,
    position: 'absolute',
    height: 40,
    left: 0,
    top: 0,
  }
});
