import React from 'react';
import PropTypes from 'prop-types';
import {
  View
} from 'react-native';
import Text from '../Text';

import styles from './style';

const Progressbar = ({
  progress, mini, disabled, style
}) => (
  <View style={[styles.container, styles.containerWeb, mini && styles.miniContainer, style]}>
    <View style={[styles.underline, { width: '100%' }]} />
    <View style={[styles.progressLine, { width: `${100 * (progress / 100)}%` }, mini && styles.progressMini]} />
    <View style={{ marginRight: 30 }}>
      <View style={[styles.indicator, { left: `${(100 * (progress / 100))}%` }, mini && styles.indicatorMini, disabled && styles.disabled]}>
        <Text color="white" fontSize={10} align="center" style={{ lineHeight: 30 }}>
          {`${progress}%`}
        </Text>
      </View>
    </View>
  </View>
);

Progressbar.propTypes = {
  progress: PropTypes.number.isRequired,
  mini: PropTypes.bool
};

export default Progressbar;
