import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { colors } from '../../../../styles/theme';
import KeyboardAwareScrollView from '../../../../components/KeyboardAwareScrollView';

const styles = StyleSheet.create({
  subHeader: {
    paddingVertical: 10,
    paddingHorizontal: 0,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.greyDarken,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    padding: 20,
  },
  wrapper: {
    backgroundColor: colors.white,
    display: 'flex',
    paddingBottom: 100,
    height: '100%',
  },
  wrapperWeb: {
    width: 1092,
    margin: 'auto',
  },
});

export const SubHeader = props => <View {...props} style={styles.subHeader} />;
export const Content = props => <View {...props} style={styles.content} />;
export const Wrapper = props =>
  Platform.OS === 'web' ? (
    <View style={styles.wrapper}>
      <View {...props} style={styles.wrapperWeb} />
    </View>
  ) : (
    <View {...props} style={styles.wrapper} />
  );

// const ScrollabelWrapper = ({ children, style, ...rest }) => Platform.OS === 'web' ? (
//   <View style={{ width: 'calc(100% - 176px)', ...style }}>
//     {children}
//   </View>
// ) : (
//   <KeyboardAwareScrollView style={{ paddingBottom: 0, ...style }} extraScrollHeight={0} {...rest}>
//     {children}
//   </KeyboardAwareScrollView>
// );

export default function UnitWrapper({ children, style, ...rest }) {
  if (Platform.OS === 'web') {
    return (
      <View style={{ width: 'calc(100% - 176px)', ...style }}>{children}</View>
    );
  }

  return (
    <KeyboardAwareScrollView
      style={{ height: '100%', paddingBottom: 0, ...style }}
      extraScrollHeight={0}
      {...rest}>
      {children}
    </KeyboardAwareScrollView>
  );
}
