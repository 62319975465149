import React from 'react';
import { Image, View, Platform, StyleSheet } from 'react-native';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { colors } from '../../styles/theme';

import Header from '../Header';
import Navigation from '../Navigation';
import background from './background.png';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.purple,
    height: '100%',
    display: 'flex',
    paddingBottom: 0,
  },
  headerLink: {
    position: 'absolute',
    height: 60,
    display: 'flex',
    justifyContent: 'center',
  },
});

class PageWrapper extends React.Component {
  // state = {
  //   menuExpanded: false
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.token && !this.props.token) {
      this.props.history.replace('/');
    }
  }

  render() {
    const { children, history, match, token, user } = this.props;
    const isNavVisible = !!token; // ['/help', '/terms'].includes(match.path) || /\/level/.test(match.path);
    const headerHeight = Platform.OS === 'ios' ? 80 : 60;

    return (
      <View style={styles.wrapper}>
        <Image
          source={background}
          style={{
            flex: 1,
            resizeMode: 'cover',
            position: 'absolute',
            left: 0,
            right: 0,
            marginTop: headerHeight,
          }}
        />
        {((Platform.OS !== 'web' && (!token && match.path !== '/')) ||
          !!token) && (
          <Header
            userName={[user.firstName, user.lastName].filter(Boolean).join(' ')}
            match={match}
            history={history}
            token={token}
            headerHeight={headerHeight}
          />
        )}

        {children}
        {isNavVisible && Platform.OS !== 'web' && <Navigation />}
      </View>
    );
  }
}

PageWrapper.propTypes = {
  children: PropTypes.node,
};

export default connect(({ authentication, user }) => ({
  token: authentication.token,
  user,
}))(PageWrapper);
