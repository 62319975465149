import React from 'react';
import { View, Platform } from 'react-native';

import LoginPage from '../../LoginPage';
import HomePage from '../../HomePage';
import DashboardPage from '../../DashboardPage';
import LevelsPage from '../../LevelsPage';
import LevelPage from '../../LevelPage';
import UnitPage from '../../UnitPage';
import ProfilePage from '../../ProfilePage';
import ResetPasswordPage from '../../ResetPasswordPage';
import ChangePasswordPage from '../../ChangePasswordPage';
import PrivacyPage from '../../PrivacyPage';
import TermsPage from '../../TermsPage';
import HelpPage from '../../HelpPage';
import FAQPage from '../../FAQPage';
import PaymentPage from '../../PaymentPage';
import TestPage from '../../TestPage';
import SplashPage from '../../SplashPage';
import PageWrapper from '../../../components/PageWrapper';

import { RouterWrapper, Switch, Route, Redirect } from './ReactRouter';

const Router = ({ token }) =>
  token !== 'loading' ? (
    <RouterWrapper>
      <Switch>
        {[
          // Public routes
          // { path: '', component: UnitPage },
          { path: '', component: token ? DashboardPage : SplashPage },
          { path: 'home', component: HomePage },
          { path: 'levels', component: LevelsPage },
          { path: 'level/:levelId/unit/:unitId/:module?', component: UnitPage },
          { path: 'level/:levelId', component: LevelPage },
          { path: 'privacy-policy', component: PrivacyPage },
          { path: 'terms', component: TermsPage },
          { path: 'help', component: HelpPage },
          { path: 'faq', component: FAQPage },
          { path: 'test/:levelId?/:unitIndex?', component: TestPage },
        ]
          .filter(Boolean)
          .map(({ path, component: Component }) => (
            <Route
              exact
              key={path}
              path={'/' + path}
              render={(props) => (
                <PageWrapper {...props}>
                  <Component {...props} isAuthenticated={token} />
                </PageWrapper>
              )}
            />
          ))}

        {[
          // Public routes, only unauthenticated
          // { path: 'splash', component: SplashPage },
          { path: 'signup', component: LoginPage },
          { path: 'signup/:subscription_id', component: LoginPage },
          { path: 'login', component: LoginPage },
          { path: 'login/:subscription_id', component: LoginPage },
          {
            path: 'user/reset-password/confirm/:uid/:token',
            component: ResetPasswordPage,
          },
          { path: 'reset-password', component: ResetPasswordPage },
        ].map(({ path, component: Component, ...rest }) => (
          <Route
            exact
            key={path}
            path={'/' + path}
            render={(props) =>
              token ? ( // add navigation wrapper here
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: props.location },
                  }}
                />
              ) : (
                <PageWrapper {...props}>
                  <Component {...props} {...rest} />
                </PageWrapper>
              )
            }
          />
        ))}

        {[
          // Private routes https://reacttraining.com/react-router/web/example/auth-workflow
          { path: 'profile', component: ProfilePage },
          { path: 'change-password', component: ChangePasswordPage },
          { path: 'payments', component: PaymentPage },
        ].map(({ path, component: Component, ...rest }) => (
          <Route
            exact
            key={path}
            path={'/' + path}
            render={(props) =>
              token ? ( // add navigation wrapper here
                <PageWrapper {...props}>
                  <Component {...props} {...rest} />
                </PageWrapper>
              ) : (
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        ))}

        {Platform.OS === 'web' && (
          <Route
            path="/install"
            component={() => {
              const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;

              if (/android/i.test(userAgent)) {
                window.location.href =
                  'https://play.google.com/store/apps/details?id=bg.bell';
              } else if (
                /iPad|iPhone|iPod/.test(userAgent) &&
                !window.MSStream
              ) {
                window.location.href =
                  'https://itunes.apple.com/bg/app/bell-bg/id1445452233';
              } else {
                window.location.href = 'https://www.bell.bg';
              }
              return null;
            }}
          />
        )}

        <Route path="">
          <Redirect to="" />
        </Route>
      </Switch>
    </RouterWrapper>
  ) : (
    // todo: add loader
    <View />
  );

export default Router;
