import React from 'react';
import HomeLink from './components/HomeLink';
import Wrapper from './components/Wrapper';

const HomePage = () => (
  <Wrapper>
    <HomeLink style={{ width: 'auto', borderRadius: 20, margin: 10 }} to="/levels">Виж всички нива</HomeLink>
    <HomeLink style={{ width: 'auto', borderRadius: 20, margin: 10 }} to="/test">Определи нивото си</HomeLink>
    <HomeLink style={{ width: 'auto', borderRadius: 20, margin: 10 }} to="/signup">Регистрирай се</HomeLink>
  </Wrapper>
);

export default HomePage;
