import React from 'react';
import {
  Dimensions,
  View,
  AsyncStorage,
  Platform,
  ScrollView,
} from 'react-native';
import { connect } from 'react-redux';
import VideoPlayer from './VideoPlayer';
import MediaControls, {
  PLAYER_STATES,
} from '../../../../components/MediaControls';
import { colors } from '../../../../styles/theme';
import { markVideoSeen as markVideoSeenRaw } from '../../../../store/reducers/learn';

import Modal from '../../../../components/Modal';
import Text from '../../../../components/Text';
import Orientation from '../../../../components/Orientation';

import { SubHeader, Content } from '../UnitWrapper';
import { NavLink } from '../../../../components/Button';

// TODO: try to set video height when loading: https://github.com/react-native-community/react-native-video/issues/746

class Situation extends React.Component {
  state = {
    currentTime: 0,
    duration: 0,
    isLoading: true,
    paused: true,
    playerState: PLAYER_STATES.PAUSED,
    subtitles: true,
    showSingupModal: false,
  };

  componentDidMount() {
    this.getSubtitlesState();
  }

  getSubtitlesState = async () => {
    const subtitles = await AsyncStorage.getItem('subtitles');
    if (subtitles !== null) {
      this.setState({ subtitles: subtitles === 'true' });
    }
  };

  toggleSubtitles = () => {
    this.setState(state => {
      AsyncStorage.setItem('subtitles', String(!state.subtitles));
      return { subtitles: !state.subtitles };
    });
  };

  onSeek = seek => {
    this.videoPlayer.seek(seek);
  };

  onPaused = playerState => {
    this.setState(state => ({
      paused: !state.paused,
      playerState,
    }));
  };

  onReplay = () => {
    this.setState({ playerState: PLAYER_STATES.PLAYING });
    this.videoPlayer.seek(0);
  };

  onProgress = data => {
    const { isLoading, playerState } = this.state;
    // Video Player will continue progress even if the video already ended
    if (!isLoading && playerState !== PLAYER_STATES.ENDED) {
      this.setState({ currentTime: data.currentTime });
    }
  };

  onLoad = data => this.setState({ duration: data.duration, isLoading: false });

  onLoadStart = () => this.setState({ isLoading: true });

  onEnd = () => this.setState({ playerState: PLAYER_STATES.ENDED });

  // eslint-disable-next-line no-alert
  onError = () => alert('Oh! An error occured, please try again.');

  exitFullScreen = () => {};

  enterFullScreen = () => {};

  onFullScreen = () => {};

  render() {
    const {
      id,
      video,
      subtitles,
      markVideoSeen,
      isAuthenticated,
      title,
      header, // historyPush
    } = this.props;

    return (
      <Orientation>
        {orientation => {
          const { width: windowWidth, height: windowHeight } = Dimensions.get(
            'window',
          );
          const videoHeight = (windowWidth * 9) / 16;
          const isNative = Platform.OS !== 'web';
          const isLandscape = orientation !== 'PORTRAIT';

          // View and ScrollView are here because of some weird padding on bottom of scroll view
          return (
            <View
              style={
                isNative
                  ? {
                      height: windowHeight - (isLandscape ? 0 : 100),
                      padding: 0,
                      margin: 0,
                      width: windowWidth,
                    }
                  : {
                      width: '100%',
                      paddingVertical: 0,
                      paddingHorizontal: 20,
                      margin: 0,
                    }
              }>
              <ScrollView
                scrollEnabled={!isNative || !isLandscape}
                style={{ flex: 1 }}>
                {!isLandscape && header}
                <View
                  style={
                    isNative
                      ? { height: isLandscape ? windowHeight : videoHeight }
                      : {
                          width: '100%',
                          paddingBottom: (100 * 9) / 16 + '%',
                          height: 0,
                        }
                  }>
                  <VideoPlayer
                    // onLoad={() => {
                    //   this.setState({ isVideoLoading: false });
                    // }}
                    key={id}
                    onLoad={this.onLoad}
                    onLoadStart={this.onLoadStart}
                    onProgress={this.onProgress}
                    innerRef={videoPlayer => {
                      this.videoPlayer = videoPlayer;
                    }}
                    resizeMode="contain"
                    source={{ uri: video }}
                    onEnd={() => {
                      this.setState({ playerState: PLAYER_STATES.ENDED });
                      if (isAuthenticated) {
                        markVideoSeen({
                          unitId: id,
                        });
                      } else {
                        // historyPush('/signup');
                        this.setState({ showSingupModal: true });
                      }
                    }}
                    style={
                      isNative
                        ? {
                            width: windowWidth,
                            height: isLandscape ? windowHeight : videoHeight,
                          }
                        : { width: '100%' }
                    }
                    selectedTextTrack={
                      this.state.subtitles
                        ? {
                            type: 'language',
                            value: 'en',
                          }
                        : null
                    }
                    subtitles={subtitles}
                    paused={this.state.paused}
                  />

                  {isNative && (
                    <MediaControls
                      style={isNative && isLandscape ? { bottom: -17 } : null}
                      paused={this.state.paused}
                      duration={this.state.duration}
                      isLoading={this.state.isLoading}
                      mainColor={colors.purple}
                      onPaused={this.onPaused}
                      onReplay={this.onReplay}
                      onSeek={this.onSeek}
                      playerState={this.state.playerState}
                      subtitles={this.state.subtitles}
                      toggleSubtitles={this.toggleSubtitles}
                      progress={this.state.currentTime}
                    />
                  )}
                </View>

                <Content>
                  <SubHeader>
                    <Text>{title}</Text>
                  </SubHeader>

                  <Text fontSize={14} style={{ marginBottom: 10 }}>
                    {
                      'Това е кратък анимиран филм, съдържащ ситуация от реалния живот, която ще ви помогне да свикнете с произношенията в английския език и ще развие вашия слух към езика.'
                    }
                  </Text>
                  <Text
                    fontSize={14}
                    color="greyDarken"
                    style={{ marginBottom: 10 }}>
                    {
                      '1. Гледайте този видеоклип със субтитри толкова пъти, колкото ви е нужно, докато можете да го разберете без субтитри.'
                    }
                  </Text>
                  <Text
                    fontSize={14}
                    color="greyDarken"
                    style={{ marginBottom: 10 }}>
                    {
                      '2. След това практикувайте и се наслаждавайте да го гледате отново и отново, докато започнете да го разбирате така, както разбирате вашия собствен език.'
                    }
                  </Text>
                  <Text
                    fontSize={14}
                    color="greyDarken"
                    style={{ marginBottom: 50 }}>
                    {
                      '3. Използвайте пауза, за да разберете по-добре примерите. Следвайте тези инструкции и ще научите английски език без да се усетите.'
                    }
                  </Text>
                </Content>

                {this.state.showSingupModal && (
                  <Modal
                    contentStyles={{
                      padding: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Text>
                      Регистрирайте се и пробвайте безплатно първия урок от
                      всяко ниво.
                    </Text>
                    <NavLink
                      variant="red"
                      style={{ marginTop: 20 }}
                      to="/signup">
                      Continue
                    </NavLink>
                  </Modal>
                )}
              </ScrollView>
            </View>
          );
        }}
      </Orientation>
    );
  }
}

export default connect(
  () => ({}),
  { markVideoSeen: markVideoSeenRaw },
)(Situation);
