import React from 'react';
import { View, StyleSheet } from 'react-native';
import { commonStyles } from '../../../../styles/theme';

const styles = StyleSheet.create({
  fragmentPart: {
    padding: 20,
    ...commonStyles.borderBottom
  }
});

const FragmentPart = props => <View {...props} style={styles.fragmentPart} />;
export default FragmentPart;
