import { connect } from 'react-redux';
import {
  changePassword,
} from '../../store/reducers/authentication';
import ChangePasswordPage from './components';

export default connect(({ user, authentication: { passwordChangeStatus, passwordChangeError } }) => ({
  user,
  passwordChangeStatus,
  passwordChangeError
}), { changePassword })(ChangePasswordPage);
