// import 'whatwg-fetch';
// import axios from 'axios';
import { API_URL } from '../appConfig';
import { adaptKeys } from './dataParsers';

// function formatUrl(path) {
//   let adjustedPath = path[0] !== '/' ? '/' + path : path;
//   adjustedPath = adjustedPath[adjustedPath.length - 1] !== '/' ? path + '/' : path;
//   return API_URL + adjustedPath;
// }

export function clearCookies() {}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
async function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const data = await response.json();
  return adaptKeys(data, 'receive');
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export async function checkStatus(response) {
  // alert(JSON.stringify(response));
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  // alert(JSON.stringify(response));

  const error = new Error(response.statusText);

  if (response.status === 401) {
    // console.log(response.text().then(errorBody => { if (console.log(errorBody)}))
    // error.response = ''
    error.response = await response
      .text()
      .then(errorBody => ({ error: errorBody }));
  } else {
    // Maybe should be in try/catch but sagas/common handles this anyway

    error.response = await response.json();
  }

  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [payload] Paylaod sent as request body
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param  {string} [token] Authorization token
 *
 * @return {object}           The response data
 */

export default async function request(path, payload, options, token) {
  const combinedOptions = {
    method: 'GET', // is overriden by options object
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      // 'If-None-Match': null,
      // 'Accept-Encoding': null,
      Referer: API_URL.replace('/1', ''),
      // ...cookies
      // Cache: 'no-cache'
      // 'Content-Type': 'application/x-www-form-urlencoded'
    },
    // credentials: 'same-origin',
    credentials: 'omit',
    // withCredentials: false,
    // credentials: 'include',
    ...options,
  };

  if (token) combinedOptions.headers.authorization = 'JWT ' + token;
  if (payload && (options.method === 'POST' || options.method === 'PATCH')) {
    combinedOptions.body = JSON.stringify(adaptKeys(payload), 'send');
  }
  // combinedOptions.headers = new Headers(combinedOptions.headers);

  return fetch(API_URL + path, combinedOptions)
    .then(checkStatus)
    .then(parseJSON);
}
