export const TOGGLE_MODAL = 'certificate.TOGGLE_MODAL';

export const SEND = 'certificate.SEND';
export const SEND_SUCCESS = 'certificate.SEND_SUCCESS';
export const SEND_FAIL = 'certificate.SEND_FAIL';


export function toggleModal(data) {
  return {
    type: TOGGLE_MODAL,
    payload: data
  };
}

export function sendCertificate(level) {
  return {
    type: SEND,
    payload: { level },
    fetchParams: {
      path: '/user/request-certificate/',
      method: 'POST',
      success: SEND_SUCCESS,
      fail: SEND_FAIL
    }
  };
}


const initialState = {
  isVisible: false,
  sendStatus: null
};

export default function dictionaryReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        sendStatus: null,
        isVisible: typeof action.payload !== 'undefined' ? action.payload : !state.isVisible
      };

    case SEND:
      return {
        ...state,
        sendStatus: 'loading'
      };

    case SEND_SUCCESS:
      return {
        ...state,
        sendStatus: 'success'
      };

    default:
      return state;
  }
}
