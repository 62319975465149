import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { colors } from '../../styles/theme';
import Text from '../Text';
import { Link } from '../Link';

const styles = StyleSheet.create({
  heading: {
    padding: 10,
    minHeight: 62,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: colors.white,
    borderBottomWidth: 2
  },
  highlightedHeading: {
    borderBottomColor: colors.red,
    borderBottomWidth: 3,
    borderTopColor: colors.red,
    borderTopWidth: 3,
  },
  highlightedText: {
    backgroundColor: colors.red,
    position: 'absolute',
    top: 0,
    right: 0,
    paddingTop: 0,
    paddingRight: 4,
    paddingBottom: 2,
    paddingLeft: 8,
    borderBottomLeftRadius: 14
  }
});

export default class Collapsable extends React.Component {
  state = {
    expanded: !!this.props.expandOnStart
  }

  render() {
    const { expanded } = this.state;
    const {
      title, children, highlight, linkTo
    } = this.props;
    return linkTo ? (
      <Link to={linkTo}>
        <View style={[
          styles.heading,
          { backgroundColor: colors.blueDarken1rgba(0.4) }
        ]}>
          <Text
            align="center"
            color="white"
            fontSize={16}
            style={{
              fontWeight: 'bold'
            }}>
            {title}
          </Text>
        </View>
      </Link>
    ) : (
      <React.Fragment>
        <TouchableOpacity
          style={[
            styles.heading,
            !!highlight && styles.highlightedHeading,
            { backgroundColor: colors.blueDarken1rgba(expanded ? '1' : '0.4') }
          ]}
          onPress={() => {
            this.setState(state => ({ expanded: !state.expanded }));
          }}>
          <Text
            align="center"
            color="white"
            fontSize={16}
            style={{
              fontWeight: 'bold'
            }}>
            {title}
          </Text>

          {highlight && (
            <View style={styles.highlightedText}>
              <Text color="white" fontSize={14}>{highlight}</Text>
            </View>
          )}
        </TouchableOpacity>
        {expanded && children}
      </React.Fragment>
    );
  }
}
