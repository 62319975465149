import React from 'react';
import {
  View, ActivityIndicator, ScrollView, StyleSheet
} from 'react-native';
import { colors } from '../../../styles/theme';
import HeadingTitle from '../../../components/HeadingTitle';
import TextInput from '../../../components/TextInput';
import Text from '../../../components/Text';
import Button from '../../../components/Button';

const styles = StyleSheet.create({
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
  }
});

const inputProps = {
  color: 'blueDarken1',
  textContentType: 'password',
  secureTextEntry: true,
  style: { margin: 30, width: 250 }
};

// eslint-disable-next-line react/prefer-stateless-function
export default class DashboardPage extends React.Component {
  state = {
    newPassword1: '',
    newPassword2: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.passwordChangeStatus === 'loading' && this.props.passwordChangeStatus === 'success') {
      this.props.history.goBack();
    }
  }

  render() {
    const { newPassword1, newPassword2 } = this.state;
    const { passwordChangeStatus, passwordChangeError } = this.props;

    return (
      <ScrollView style={{ backgroundColor: colors.white }}>
        <View style={styles.innerWrapper}>
          <HeadingTitle>CHANGE PASSWORD</HeadingTitle>

          <TextInput
            {...inputProps}
            placeholder="New password"
            value={newPassword1}
            onChangeText={(newValue) => {
              this.setState({ newPassword1: newValue });
            }} />


          <View>
            {[
              '- Тази парола е твърде кратка. Паролата трябва да съдържа минимум 8 символа.',
              '- Паролата не трябва са съдържа само цифри.'
            ].map((line, i) => (
              <Text
                key={i} // eslint-disable-line react/no-array-index-key
                style={{ marginVertical: 5, marginHorizontal: 30 }}
                color="greyDarken"
                align="left">
                {line}
              </Text>
            ))}
          </View>

          <TextInput
            {...inputProps}
            placeholder="New password confirmation"
            value={newPassword2}
            onChangeText={(newValue) => {
              this.setState({ newPassword2: newValue });
            }} />

          {passwordChangeStatus === 'error' && (
            <Text color="red" style={{ marginBottom: 10 }}>
              {passwordChangeError}
            </Text>
          )}

          {passwordChangeStatus === 'loading' ? (
            <ActivityIndicator size="small" color={colors.blueDarken1} />
          ) : (
            <Button
              onClick={() => {
                this.props.changePassword({ newPassword1, newPassword2 });
              }}
              variant="red">
              {'Save'}
            </Button>
          )}

        </View>
      </ScrollView>
    );
  }
}
