/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import authentication from './authentication';
import user from './user';
import learn from './learn';
import dictionary from './dictionary';
import certificate from './certificate';
import payments from './payments';
import inapProducts from './inapProducts';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer() {
  return combineReducers({
    authentication,
    user,
    learn,
    dictionary,
    certificate,
    payments,
    inapProducts
  });
}
