import React from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { Link } from '../Link';
import { colors } from '../../styles/theme';
import Text from '../Text';

const styles = StyleSheet.create({
  link: {
    backgroundColor: colors.white,
    padding: 10,
    width: 250,
    borderRadius: 19,
  },
  button: {
    backgroundColor: colors.white,
    padding: 10,
    width: 250,
    borderRadius: 19,
    marginBottom: 30,
  },
  variantRed: {
    backgroundColor: colors.red,
    width: 116
  },
  variantGrey: {
    backgroundColor: colors.greyDarken,
    width: 116
  },
  variantText: {
    color: colors.white
  }
});

export const NavLink = ({
  children, textProps, style, variant, ...rest
}) => (
  <Link {...rest}>
    <View style={[
      styles.link,
      variant === 'red' && styles.variantRed,
      variant === 'greyDarken' && styles.variantGrey,
      style
    ]}>
      <Text color={variant === 'red' && 'white'} {...textProps} align="center">{children}</Text>
    </View>
  </Link>
);

export default ({
  children, onClick, textProps, style, disabled, variant, ...rest
}) => (
  <TouchableOpacity
    onPress={onClick}
    disabled={disabled}
    style={[
      styles.button,
      disabled && { opacity: 0.4 },
      variant === 'red' && styles.variantRed,
      variant === 'greyDarken' && styles.variantGrey,
      style
    ]}
    {...rest}>
    <Text color={variant === 'red' && 'white'} align="center" {...textProps}>{children}</Text>
  </TouchableOpacity>
);
