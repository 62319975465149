import React from 'react';
import { View, StyleSheet } from 'react-native';
import Collapsable from '../../components/Collapsable';
import Text from '../../components/Text';
import { ScrollView, Content } from '../../components/SimplePage';
import HeadingTitle from '../../components/HeadingTitle';
import { commonStyles } from '../../styles/theme';
import terms from '../../static/terms';
import privacyPolicy from '../../static/privacyPolicy';

const styles = StyleSheet.create({
  textRow: {
    padding: 10,
    ...commonStyles.borderTop,
  },
});

const TextRow = ({ line, align }) => (
  <View style={styles.textRow}>
    <Text align="center">{line[0]}</Text>
    <Text align={align || 'center'} color="greyDarken">
      {line[1]}
    </Text>
  </View>
);

const HelpPage = () => (
  <ScrollView>
    <HeadingTitle>HELP</HeadingTitle>
    <Collapsable title="Как работи?">
      <Content>
        {[
          [
            'Situation',
            'Гледайте анимирания филм с или без субтитри толкова пъти, колкото ви е нужно, докато можете да го разберете без субтитири. След това практикувайте и се наслаждавайте да го гледате отново и отново, докато започнете да го разбирате така, както разбирате вашия собствен език.',
          ],

          [
            'Study',
            'Прочетете внимателно превода на епизода и поясненията към него.',
          ],

          [
            'Dictation',
            'Чуйте избрания откъс от епизода и напишете това, което сте чули. С помощта на системата направете проверка на написаното.',
          ],

          [
            'Role Play',
            'Прочетете на глас репликите на избрания участник в диалога вместо него. След това прослушайте записа, за да направите сравнение с оригинала. По този начин можете да анализирате произношението си и да го усъвършенствате.',
          ],

          [
            'Grammar',
            'Прочетете текста. Граматиката в него е изложена ясно, разбираемо и с примери за използване на съответните форми. Практикувайте прочетенето в модули Writing и Translation.',
          ],

          [
            'Writing',
            `1.Отбележете изреченията като правилни (Yes) или неправилни (No);
2.Попълнете празните места с правилните граматични форми;
3.Довършете започнатите изречения;
4.Преведете изреченията от английски на български език.`,
          ],
          [
            'New words',
            `Прочетете новите думи толкова пъти, колкото е нужно, за да ги запомните. 
Възползвайте се от услугата - My Dictionary (моят речник), за да записвате и други думи, които искате да научите и които не са включени в този модул. С лесния и бърз достъп до тях, можете да ги прочитате многократно всеки ден, да допълвате или изтривате вече научените думи.`,
          ],
          ['Translation', 'Преведете изреченията от български на английски.'],

          [
            'Добре е да знаете!',
            `При изучаване на майчин език мозъкът ни научава структурите на езика преди да са ни учили на тях. Тези структури са основа за граматичните правила на новия език, който желаем да научим. Но за да се научат, ние се нуждаем от обучение. 
Онлайн обучението е различно от класическия курсов език и има много предимства. 
Вие решавате кога, какво и колко искате да научите. В този процес вие трябва да станете активни, като преживеете различни неща, на различни места с помоща на симулирани ситуации.
Обучавайте се със системата на BELL – Bulgarian-English Language Learning, която е специално разработена и преведена на български език, за да научите английски език, разбираемо-интуитивно и достъпно за вас, с помощта на вашия роден език!`,
          ],
        ].map((line, i) => (
          <TextRow key={i} line={line} align="left" />
        ))}
      </Content>
    </Collapsable>

    <Collapsable title="Съкращения/Абревиации">
      <Content>
        <Text align="center" style={{ marginBottom: 10 }}>
          Съкращения/Абревиации
        </Text>
        {[
          [
            'Adj = adjective (fast, amazing)',
            'прилагателно (бърз, удивителен)',
          ],
          ['Adv = adverb (happily, frankly)', 'наречие (щастливо, честно)'],
          ['Conj = conjunction (and, but)', 'съюз (и, но)'],
          [
            'Inf = infinitive (go, play)',
            'инфинитив (основна форма на глагола)',
          ],
          ['N = noun (car, rabbit) - съществително (кола, заек)'],
          [
            'Phr v = phrasal verb (put off, get up)',
            'фразеологичен глагол (отлагам, ставам от сън)',
          ],
          ['Prep = preposition (in, after, to)', 'предлог (в, след, към)'],
          ['Pron. = pronoun (he, she, we)', 'местоимение (той, тя, ние)'],
          ['S = subject', 'подлог (извършител на действието)'],
          ['Sb = somebody (някой)'],
          ['Sth = something (нещо)'],
          ['V = verb (глагол/сказуемо)'],
        ].map((line, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TextRow key={i} line={line} />
        ))}
      </Content>
    </Collapsable>

    <Collapsable linkTo="/faq" title="Често задавани въпроси" />

    <Collapsable title="Политика за поверителност">
      <Content>
        <Text style={{ marginBottom: 10 }}>{privacyPolicy.title}</Text>
        {privacyPolicy.text.split('\n').map((textLine, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={i} color="greyDarken" style={{ marginBottom: 10 }}>
            {textLine}
          </Text>
        ))}
      </Content>
    </Collapsable>

    <Collapsable title="Общи условия">
      <Content>
        <Text style={{ marginBottom: 10 }}>{terms.title}</Text>
        {terms.text.split('\n').map((textLine, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Text key={i} color="greyDarken" style={{ marginBottom: 10 }}>
            {textLine}
          </Text>
        ))}
      </Content>
    </Collapsable>

    <Collapsable title="Свържи се с нас">
      <Content>
        <Text style={{ margin: 10 }}>
          {'Телефон: '}
          <Text color="greyDarken">+359 877 088 988</Text>
        </Text>
        <Text style={{ margin: 10 }}>
          {'Е-мейл: '}
          <Text color="greyDarken">info@bell.bg</Text>
        </Text>
        <Text style={{ margin: 10 }}>
          {'Адрес: '}
          <Text color="greyDarken">
            ул. Ген. Йосиф В. Гурко 62/64, София, България
          </Text>
        </Text>
      </Content>
    </Collapsable>
  </ScrollView>
);

export default HelpPage;
