/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import {
  TouchableOpacity, Image, View, StyleSheet
} from 'react-native';
import { commonStyles } from '../../../../styles/theme';
import CheckedIcon from '../../../../icons/checked.png';
import PlayIcon from '../../../../icons/play.png';
import NotActionable from './NotActionable';

const styles = StyleSheet.create({
  actionableContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingRight: 10,
    marginTop: 10,
    marginRight: 10
  },
  playingAnimation: commonStyles.playingAnimation
});

export default class Actionable extends React.Component {
  render() {
    const {
      data, onAnswer, playingFragment, setPlayingFragment
    } = this.props;
    const isThisFragmentPlaying = playingFragment === data.id;

    return (
      <TouchableOpacity
        onPress={() => {
          if (!playingFragment) {
            this.player.play();
            setPlayingFragment(data.id);
          }
        }}>
        <View style={[styles.actionableContainer, isThisFragmentPlaying && styles.playingAnimation]}>
          <audio
            preload="none"
            onEnded={() => {
              if (!data.isAnswered) {
                onAnswer();
              }
              setPlayingFragment(null);
            }}
            ref={ref => { this.player = ref; }}>
            <source src={data.voice} type="audio/mpeg" />
          </audio>
          {data.isAnswered ? (
            <Image
              source={CheckedIcon}
              style={{
                width: 20, height: 15, marginRight: 13, marginTop: 20
              }} />
          ) : (
            <Image
              source={PlayIcon}
              style={{
                width: 23, height: 23, marginRight: 10, marginTop: 12
              }} />
          )}
          <NotActionable data={data} />
        </View>
      </TouchableOpacity>
    );
  }
}
