import { StyleSheet } from 'react-native';
import { colors, commonStyles } from '../../styles/theme';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.white,
    flex: 1,
  },
  header: {
    paddingVertical: 16,
    paddingHorizontal: 0,
    ...commonStyles.borderBottom,
  },
  paymentItem: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
  },
  modalContent: {
    display: 'flex',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  info: {
    backgroundColor: colors.grey,
    paddingVertical: 10,
    paddingHorizontal: 36,
  },
});

export default styles;
