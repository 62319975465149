import React from 'react';
export default function ({ style }) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.12 130.12" style={style}>
      <defs><clipPath id="clip-path"><circle className="cls-1" cx="64.49" cy="64.84" r="56.35" /></clipPath></defs>
      <circle fill="#1b1447" cx="64.49" cy="64.84" r="63.68" />
      <g className="cls-3" clipPath="url(#clip-path)">
        <rect fill="#fff" x="36.21" y="15.47" width="15.14" height="57.75" transform="translate(-18.54 43.95) rotate(-45)" />
        <rect fill="#fff" x="79.55" y="54.21" width="15.14" height="59.71" transform="translate(-33.93 86.23) rotate(-45)" />
        <rect fill="#fff" x="78.93" y="15.75" width="15.14" height="57.75" transform="translate(116.11 137.34) rotate(-135)" />
        <rect fill="#fff" x="35.59" y="54.49" width="15.14" height="59.71" transform="translate(14.03 174.5) rotate(-135)" />
        <rect fill="#fff" x="54.47" y="5.62" width="20.04" height="118.44" />
        <rect fill="#fff" x="54.47" y="5.62" width="20.04" height="118.44" transform="translate(129.34 0.35) rotate(90)" />
        <rect fill="#ed1c24" x="59.48" y="5.62" width="10.02" height="118.44" transform="translate(129.34 0.35) rotate(90)" />
        <rect fill="#ed1c24" x="83.27" y="14.18" width="2.82" height="58.98" transform="translate(55.69 -47.09) rotate(45)" />
        <rect fill="#ed1c24" x="42.72" y="56.94" width="2.82" height="58.49" transform="translate(73.87 -5.96) rotate(45)" />
        <rect fill="#ed1c24" x="86.2" y="53.59" width="2.82" height="58.98" transform="translate(208.31 79.89) rotate(135)" />
        <rect fill="#ed1c24" x="40.07" y="16.91" width="2.82" height="58.49" transform="translate(103.44 49.47) rotate(135)" />
        <rect fill="#ed1c24" x="59.48" y="5.62" width="10.02" height="118.44" />
      </g>
      <path
        fill="#1B1447"
        d="M33.2,87.5V75.8l1.5-0.8C34.8,75,45,69.9,45,60.2C45,49.6,45,32,64.5,32S84,49.6,84,60.2C84,69.9,94.2,75,94.2,75l1.5,0.7v11.7H33.2z" />
      <path
        fill="#FFFFFF"
        d="M64.5,34.7c15.8,0,16.8,12.9,16.8,25.5c0,11.6,11.8,17.3,11.8,17.3v7.3H35.9v-7.3c0,0,11.8-5.7,11.8-17.3C47.7,47.6,48.7,34.7,64.5,34.7 M64.5,29.3c-22.2,0-22.2,20.9-22.2,30.9c0,8-8.7,12.4-8.8,12.4l-3,1.5v3.3v12.7h67.9V74.1l-3-1.5c-0.1,0-8.7-4.5-8.7-12.4C86.7,50.2,86.7,29.3,64.5,29.3L64.5,29.3z" />
      <path
        fill="#1B1447"
        d="M63.9,99.4c-4.9,0-8.8-3.9-8.8-8.8v-2.7h17.6v2.7C72.7,95.5,68.7,99.4,63.9,99.4z" />
      <path
        fill="#FFFFFF"
        d="M70,90.6c-0.1,3.4-3,6-6.4,5.9c-3.2-0.1-5.7-2.7-5.9-5.9H70 M75.4,85.2h-23v5.4c0,6.4,5.1,11.5,11.5,11.5S75.4,97,75.4,90.6V85.2z" />
    </svg>
  );
}
