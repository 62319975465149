import dotProp from 'dot-prop-immutable';
import moment from 'moment';

import { LOG_OUT } from './authentication';

export const LOAD_PAYMENTS = 'payments.LOAD_PAYMENTS';
export const LOAD_PAYMENTS_SUCCESS = 'payments.LOAD_PAYMENTS_SUCCESS';
export const LOAD_PAYMENTS_FAIL = 'payments.LOAD_PAYMENTS_FAIL';

export const LOAD_PRODUCTS = 'payments.LOAD_PRODUCTS';
export const LOAD_PRODUCTS_SUCCESS = 'payments.LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_FAIL = 'payments.LOAD_PRODUCTS_FAIL';

export const REQUEST_PAYMENT = 'payments.REQUEST_PAYMENT';
export const REQUEST_PAYMENT_SUCCESS = 'payments.REQUEST_PAYMENT_SUCCESS';
export const REQUEST_PAYMENT_FAIL = 'payments.REQUEST_PAYMENT_FAIL';

export const REQUEST_EP_PAYMENT = 'payments.REQUEST_EP_PAYMENT';
export const REQUEST_EP_PAYMENT_SUCCESS = 'payments.REQUEST_EP_PAYMENT_SUCCESS';
export const REQUEST_EP_PAYMENT_FAIL = 'payments.REQUEST_EP_PAYMENT_FAIL';

export const CLEAR_EPAY = 'payments.CLEAR_EPAY';

export const VALIDATE_PIN = 'payments.VALIDATE_PIN';
export const VALIDATE_PIN_SUCCESS = 'payments.VALIDATE_PIN_SUCCESS';
export const VALIDATE_PIN_FAIL = 'payments.VALIDATE_PIN_FAIL';

export const VOUCHER_PURCHASE = 'payments.VOUCHER_PURCHASE';
export const VOUCHER_PURCHASE_SUCCESS = 'payments.VOUCHER_PURCHASE_SUCCESS';
export const VOUCHER_PURCHASE_FAIL = 'payments.VOUCHER_PURCHASE_FAIL';

export function loadPayments() {
  return {
    type: LOAD_PAYMENTS,
    fetchParams: {
      path: '/payment/',
      success: LOAD_PAYMENTS_SUCCESS,
      fail: LOAD_PAYMENTS_FAIL,
    },
  };
}

export function loadProducts() {
  return {
    type: LOAD_PRODUCTS,
    fetchParams: {
      path: '/product/',
      success: LOAD_PRODUCTS_SUCCESS,
      fail: LOAD_PRODUCTS_FAIL,
    },
  };
}

export function requestPayment({ product, phone }) {
  return {
    type: REQUEST_PAYMENT,
    payload: {
      product,
      phone,
    },
    fetchParams: {
      path: '/payment/',
      method: 'POST',
      success: REQUEST_PAYMENT_SUCCESS,
      fail: REQUEST_PAYMENT_FAIL,
    },
  };
}

export function requestEasyPayPurchase({ product, coupon }) {
  return {
    type: REQUEST_EP_PAYMENT,
    payload: {
      product,
      coupon,
    },
    fetchParams: {
      path: '/payment/epay_purchase/',
      method: 'POST',
      success: REQUEST_EP_PAYMENT_SUCCESS,
      fail: REQUEST_EP_PAYMENT_FAIL,
    },
  };
}

export function validatePIN({ pin }) {
  return (dispatch, getState) =>
    dispatch({
      type: VALIDATE_PIN,
      payload: {
        pin,
      },
      fetchParams: {
        path: `/payment/${getState().payments.paymentId}/check_pin/`,
        method: 'POST',
        success: VALIDATE_PIN_SUCCESS,
        fail: VALIDATE_PIN_FAIL,
      },
    });
}

export function useVoucher({ coupon, product }) {
  return {
    type: VOUCHER_PURCHASE,
    payload: {
      coupon,
      product,
    },
    fetchParams: {
      path: '/payment/voucher_purchase/',
      method: 'POST',
      success: VOUCHER_PURCHASE_SUCCESS,
      fail: VOUCHER_PURCHASE_FAIL,
    },
  };
}

export function clearEpay() {
  return {
    type: CLEAR_EPAY,
  };
}

const initialState = {
  payments: [],
  activePayment: null,
  paymentsStatus: null,
  paymentId: null,
  paymentStatus: null,
  pinValidationStatus: null,
  products: [],
  productsStatus: null,
  epay: {
    status: null,
  },
  voucherError: null,
};

export default function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PAYMENTS:
      return dotProp.set(state, 'paymentsStatus', 'loading');

    case LOAD_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload.results,
        activePayment: action.payload.results.find(
          ({ status, startsAt, endsAt }) =>
            status === 'COMPLETED' &&
            moment(new Date())
              .add(5, 'minutes')
              .isBetween(startsAt, endsAt),
        ),
        paymentsStatus: 'success',
      };

    case LOAD_PAYMENTS_FAIL:
      return dotProp.set(state, 'paymentsStatus', 'error');

    case LOAD_PRODUCTS:
      return dotProp.set(state, 'paymentsStatus', 'loading');

    case LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.results,
        productsStatus: 'success',
      };

    case LOAD_PRODUCTS_FAIL:
      return dotProp.set(state, 'productsStatus', 'error');

    case REQUEST_PAYMENT:
      return dotProp.set(state, 'paymentStatus', 'loading');

    case REQUEST_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentStatus: 'success',
        paymentId: action.payload.id,
      };

    case REQUEST_PAYMENT_FAIL:
      return dotProp.set(state, 'paymentStatus', 'error');

    case VALIDATE_PIN_SUCCESS:
      return dotProp.set(state, 'pinValidationStatus', 'success');

    case VALIDATE_PIN_FAIL:
      return dotProp.set(state, 'pinValidationStatus', 'error');

    case REQUEST_EP_PAYMENT:
      return dotProp.set(state, 'epay.status', 'loading');

    case REQUEST_EP_PAYMENT_SUCCESS:
      return dotProp.set(state, 'epay', {
        status: 'success',
        ...action.payload,
      });

    case REQUEST_EP_PAYMENT_FAIL:
      return dotProp.set(state, 'epay', {
        status: 'error',
        ...action.payload,
      });

    case VOUCHER_PURCHASE:
      return {
        ...state,
        paymentStatus: 'loading',
        voucherError: null,
      };

    case VOUCHER_PURCHASE_SUCCESS:
      return dotProp.set(state, 'paymentStatus', null);

    case VOUCHER_PURCHASE_FAIL:
      return {
        ...state,
        paymentStatus: null,
        voucherError: dotProp.get(
          action,
          'payload.response.coupon.0',
          'Server error, please try again later',
        ),
      };

    case CLEAR_EPAY:
      return dotProp.set(state, 'epayId', null);

    case LOG_OUT:
      return initialState;

    default:
      return state;
  }
}
