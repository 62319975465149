import React from 'react';
import { View, StyleSheet } from 'react-native';
import { colors } from '../../../styles/theme';
import { stripHtml } from '../../../utils/htmlParsers';
import { shuffleArray } from '../../../utils/array';
import Text from '../../../components/Text';
import Button from '../../../components/Button';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
});

const getButtonStyle = (isAnswered, isCorrect) => ({
  borderWidth: 1,
  borderColor: colors.blueDarken1,
  width: 200,
  margin: 10,
  ...(isAnswered ? (isCorrect ? {
    backgroundColor: colors.blueDarken1,
    borderColor: 'transparent'
  } : {
    backgroundColor: colors.red,
    borderColor: 'transparent'
  }) : {})
});


export default class Question extends React.Component {
  constructor(props) {
    super(props);

    const questionText = stripHtml(props.question.text);
    const textSplit = questionText
      .replace('}', '') // remove last }
      .split('{');

    let answers = textSplit[1] // separate text and answers, select answers
      .split('|');

    const textParts = textSplit[0].split('_');

    let correctAnswer = '';

    textParts.forEach((part, key) => {
      if (key % 2 !== 0) {
        correctAnswer += (correctAnswer.length ? (',' + part) : part);
      }
    });

    answers.push(correctAnswer);
    answers = shuffleArray(answers);

    this.state = {
      userAnswer: null,
      textParts,
      answers,
      correctAnswer
    };
  }


  render() {
    const {
      userAnswer, textParts, answers, correctAnswer
    } = this.state;

    return (
      <React.Fragment>

        <Text
          style={{
            backgroundColor: colors.blueDarken1rgba(0.4),
            paddingVertical: 44,
            paddingHorizontal: 40,
            marginBottom: 10,
            width: '100%',
          }}
          justify="flex-start">

          <Text color="white" style={{ backgroundColor: 'transparent' }}>{`${this.props.questionIndex + 1}. `}</Text>

          {textParts.map((part, i) => (
            i % 2 !== 0 ? (
              <Text style={{ backgroundColor: 'transparent', textDecorationLine: 'underline' }} key={i}>
                {userAnswer ? ` ${part} ` : '_____'}
              </Text>
            ) : (
              <Text color="white" style={{ backgroundColor: 'transparent' }} key={i}>{part}</Text>
            )
          ))}
        </Text>

        <View style={styles.container}>
          {answers.map((answer, i) => (
            <Button
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              onClick={() => {
                if (!userAnswer) {
                  this.setState({ userAnswer: answer });
                }
              }}
              textProps={{ color: userAnswer === answer || (!!userAnswer && correctAnswer === answer) ? 'white' : null }}
              style={getButtonStyle(
                userAnswer === answer || (!!userAnswer && correctAnswer === answer),
                !!userAnswer && correctAnswer === answer
              )}>
              {answer}
            </Button>
          ))}

          <Button
            onClick={() => {
              this.props.onAnswer(correctAnswer === userAnswer);
            }}
            disabled={!userAnswer}
            variant="red">
            {'Next'}
          </Button>
        </View>
      </React.Fragment>
    );
  }
}
