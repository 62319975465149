import React from 'react';
import { View, StyleSheet } from 'react-native';
import Collapsable from '../../../components/Collapsable';
import HeadingTitle from '../../../components/HeadingTitle';
import ProgressButton from '../../../components/ProgressButton';
import { ScrollView } from '../../../components/SimplePage';
import images from '../../../images';

const styles = StyleSheet.create({
  levelWrapper: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  }
});


export default class LevelsPage extends React.Component {
  componentDidMount() {
    this.props.loadLevels();
  }

  getImage = (levelName) => {
    switch (levelName) {
      case 'C1.2':
        return images.situation;
      case 'C1.1':
        return images.role_play;
      case 'B2.2':
        return images.writing;
      case 'B2.1':
        return images.translation;
      case 'B1.2':
        return images.study;
      case 'B1.1':
        return images.role_play;
      case 'A2.2':
        return images.new_words;
      case 'A2.1':
        return images.grammar;
      case 'A1.2':
        return images.dictation;
      case 'A1.1':
      default:
        return images.situation;
    }
  }

  arrayMax = array => Array.isArray(array) ? array.reduce((a, b) => Math.max(a, b), -1) : -1

  render() {
    const {
      learn, isAuthenticated, suggestedLevel, initiated, activeLevel, /* activeLevels, activePayment */
    } = this.props;
    // TODO: not a good solution, we should map level indexes and find larges
    // const maxArrayId = this.arrayMax(activeLevels);

    return (
      <ScrollView>
        <HeadingTitle>LEVELS</HeadingTitle>

        {[
          { level: 'A1', title: 'BEGINNER' },
          { level: 'A2', title: 'ELEMENTARY' },
          { level: 'B1', title: 'INTERMEDIATE' },
          { level: 'B2', title: 'UPPER INTERMEDIATE' },
          { level: 'C1', title: 'ADVANCED' },
        ].map(({ level: levelKey, title }, i) => (
          <Collapsable
            expandOnStart={initiated && activeLevel ? activeLevel.name.split('.')[0] === levelKey : (suggestedLevel ? suggestedLevel.includes(levelKey) : !i)}
            key={suggestedLevel + levelKey}
            title={`${levelKey} ${title}`}>
            <View style={styles.levelWrapper}>
              {learn.levels && learn.levels
                .filter(level => level.name.includes(levelKey))
                // eslint-disable-next-line arrow-body-style
                .map(level => {
                  // let isDisabled = false;
                  // if (initiated) {
                  //   if (maxArrayId > -1 && level.id > maxArrayId) {
                  //     isDisabled = true;
                  //   }
                  // } else if (suggestedLevel) {
                  //   // const isCorrectLevelGroup = suggestedLevel.localeCompare(levelKey) < 0;
                  //   if (suggestedLevel.localeCompare(levelKey) < 0) {
                  //     isDisabled = true;
                  //   } else if (suggestedLevel.split('.')[0] === levelKey && suggestedLevel.split('.')[1].localeCompare(level.name.split('.')[1]) < 0) {
                  //     isDisabled = true;
                  //   }
                  // }
                  return (
                    <ProgressButton
                      {...level}
                      key={level.id}
                      // disabled={!activePayment || isDisabled}
                      progress={isAuthenticated && level.progress}
                      title={level.name}
                      // linkTo={activePayment ? (isDisabled ? null : `/level/${level.id}`) : '/payments'}
                      linkTo={`/level/${level.id}`}
                      image={this.getImage(level.name)} />
                  );
                })
              }
            </View>
          </Collapsable>

        ))}
      </ScrollView>
    );
  }
}
