function getInitialOrientation() {
  return 'PROFILE';
}
function addOrientationListener() {}
function removeOrientationListener() {}
export default {
  getInitialOrientation,
  addOrientationListener,
  removeOrientationListener,
};
