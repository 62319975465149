import React from 'react';
import {
  ActivityIndicator, View, StyleSheet, Platform
} from 'react-native';
import KeyboardAwareScrollView from '../../../components/KeyboardAwareScrollView';
import HeadingTitle from '../../../components/HeadingTitle';
import TextInput from '../../../components/TextInput';
import Button, { NavLink } from '../../../components/Button';
import { colors, commonStyles } from '../../../styles/theme';

const styles = StyleSheet.create({
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
  }
});

// eslint-disable-next-line react/prefer-stateless-function
export default class DashboardPage extends React.Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    initiated: false
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.initiated && props.user.status !== 'loading') {
      return {
        email: props.user.email || '',
        firstName: props.user.firstName || '',
        lastName: props.user.lastName || '',
        phone: props.user.phone || '',
        initiated: true
      };
    }

    return null;
  }

  // componentDidMount() {
  //   this.props.loadProfile();
  // }

  getPlaceholder = (prop) => {
    switch (prop) {
      case 'firstName':
        return 'Име';

      case 'lastName':
        return 'Фамилия';

      case 'email':
        return 'e-mail';

      case 'phone':
        return 'Phone number';

      default:
        return null;
    }
  }

  render() {
    const { user } = this.props;

    return (
      <KeyboardAwareScrollView extraScrollHeight={50} style={{ backgroundColor: colors.white }}>
        <View style={[styles.innerWrapper, Platform.OS === 'web' && commonStyles.webContainer]}>
          <HeadingTitle style={{ marginBottom: 40 }}>MY PROFILE</HeadingTitle>

          {['firstName', 'lastName', 'email', 'phone'].map(prop => (
            <TextInput
              key={prop}
              placeholder={this.getPlaceholder(prop)}
              color="blueDarken1"
              underlineColorAndroid="rgba(0, 0, 0, 0)"
              style={{ marginBottom: 20, width: 250 }}
              value={this.state[prop]}
              onChangeText={(newValue) => {
                this.setState({ [prop]: newValue });
              }} />
          ))}

          {user.status === 'loading' ? (
            <ActivityIndicator />
          ) : (
            <Button
              onClick={() => {
                this.props.updateProfile({
                  username: user.username,
                  ...(Object.entries(this.state).reduce((payload, [key, value]) => value ? {
                    ...payload,
                    [key]: value
                  } : payload, {}))
                });
              }}
              variant="red">
              {'Save'}
            </Button>
          )}

          <NavLink to="/change-password">Change password</NavLink>
        </View>
      </KeyboardAwareScrollView>
    );
  }
}
