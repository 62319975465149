import { useState, useEffect } from 'react';
import Orientation from '../utils/orientation';
// import Orientation from 'react-native-orientation';

export default function useOrientation() {
  // return 'PROFILE';
  const [orientation, setOrientation] = useState(
    Orientation.getInitialOrientation(),
  );

  useEffect(() => {
    Orientation.addOrientationListener(setOrientation);

    // Remove event listener on cleanup
    return () => {
      Orientation.removeOrientationListener(setOrientation);
    };
  }, []);

  return orientation;
}
