import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import moment from 'moment';
import { colors } from '../../styles/theme';
import checkIcon from '../../icons/checked-white.png';
import Text from '../Text';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  line: {
    position: 'absolute',
    backgroundColor: colors.red,
    // width: '100%',
    height: 2,
    top: 16,
    left: 32,
    width: 32,
  },
  streak: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  dayContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  day: {
    width: 32,
    height: 32,
    borderRadius: 16,
    borderColor: colors.purple,
    borderWidth: 1,
    marginBottom: 6,
  },
  checkedDay: {
    marginTop: 11,
    marginLeft: 8,
    width: 13,
    height: 10,
  }
});


const DailyStreak = ({ currentProgressDay, streak }) => (
  <View style={styles.container}>
    <Text>DAILY STREAK</Text>
    <View style={styles.streak}>
      {[4, 3, 2, 1, 0].filter(day => currentProgressDay - day >= 0).map(day => {
        const thisDay = moment().add(-1 * day, 'days');
        const isActive = streak && streak.includes(thisDay.format('YYYY-MM-DD'));
        return (
          <View
            key={day}
            style={[
              styles.dayContainer,
              day === 0 ? { width: 32, paddingRight: 0 } : { width: 64, paddingRight: 32 }
            ]}>
            <View style={[styles.day, { backgroundColor: isActive ? colors.purple : colors.white }]}>
              {isActive && <Image style={styles.checkedDay} source={checkIcon} />}
            </View>
            {!!day && <View style={styles.line} />}
            <Text fontSize={14} color="greyDarken">{thisDay.format('dd')}</Text>
            <Text fontSize={14} color="greyDarken" style={{ width: 50 }} align="center">
              {'day '}
              {currentProgressDay - day + 1}
            </Text>
          </View>
        );
      })}
    </View>
  </View>
);

export default DailyStreak;
