// @flow

// eslint ignore next $FlowFixMe
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    position: 'absolute',
    flex: 1,
    paddingVertical: 13,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    justifyContent: 'space-between',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  controlsRow: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  toolbarRow: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  toolbar: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
  },
  timeRow: {
    alignSelf: 'stretch',
  },
  playButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
  },
  playIcon: {
    width: 40,
    // height: 40,
    resizeMode: 'contain',
  },
  replayIcon: {
    width: 25,
    height: 20,
    resizeMode: 'stretch',
  },
  progressContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: -25,
  },
  progressColumnContainer: {
    flex: 1,
    paddingHorizontal: 20
  },
  fullScreenContainer: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
  },
  progressSlider: {
    alignSelf: 'stretch',
  },
  timerLabelsContainer: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: -7,
  },
  timerLabel: {
    fontSize: 12,
    color: 'white',
  },
  track: {
    height: 5,
    borderRadius: 1,
  },
  thumb: {
    width: 20,
    height: 20,
    borderRadius: 50,
    backgroundColor: 'white',
    borderWidth: 3,
  },
  subtitlesContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 33,
    backgroundColor: 'rgba(27, 20, 71, 0.6)',
    marginBottom: -13
  },
  subtitlesText: {
    color: '#fff',
    fontSize: 16
  },

  toggleContainer: {
    height: 24,
    width: 54,
    borderRadius: 12,
    overflow: 'hidden',
    marginLeft: 10,
    marginRight: 24
  },
  toggle: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: 8,
    height: 24,
    alignItems: 'center',
  },
  toggleText: {
    color: 'rgba(112, 112, 112, 0.5)',
    fontSize: 14
  },
  toggleButton: {
    backgroundColor: 'rgba(27, 20, 71, 1)',
    height: 18,
    width: 18,
    borderRadius: 9,
    marginHorizontal: 3,
  }
});
