/* eslint-disable func-names */
import { all, fork, takeLatest, takeEvery, put } from 'redux-saga/effects';
import {
  REGISTER,
  REGISTER_SUCCESS,
  LOG_IN,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOAD_ACCOUNT,
  LOAD_ACCOUNT_SUCCESS,
  checkToken,
} from '../reducers/authentication';
import {
  LOAD_USER_PROFILE,
  PATCH_USER_PROFILE,
  LOAD_USER_PROFILE_SUCCESS,
} from '../reducers/user';
import {
  LOAD_LEVELS,
  LOAD_UNITS,
  LOAD_UNIT,
  ANSWER,
  loadUnit,
} from '../reducers/learn';
import {
  LOAD_PAYMENTS,
  LOAD_PRODUCTS,
  REQUEST_PAYMENT,
  REQUEST_EP_PAYMENT,
  VALIDATE_PIN,
  VALIDATE_PIN_SUCCESS,
  loadPayments,
  VOUCHER_PURCHASE,
  VOUCHER_PURCHASE_SUCCESS,
} from '../reducers/payments';
import {
  UPLOAD_PURCHASE,
  UPLOAD_PURCHASE_SUCCESS,
} from '../reducers/inapProducts';
import {
  SEND as SEND_CERTIFICATE,
  toggleModal as toggleCertificateModal,
} from '../reducers/certificate';
import { createRequestSaga } from './requests';

export default function* root() {
  yield all(
    [
      function* asyncTakeLatestWatchers() {
        yield all(
          [
            REGISTER,
            LOG_IN,
            CHANGE_PASSWORD,
            FORGOT_PASSWORD,
            RESET_PASSWORD,
            ANSWER,
            REQUEST_PAYMENT,
            REQUEST_EP_PAYMENT,
            PATCH_USER_PROFILE,
            VALIDATE_PIN,
            UPLOAD_PURCHASE,
            SEND_CERTIFICATE,
            VOUCHER_PURCHASE,
          ].map(action => takeLatest(action, createRequestSaga())),
        );
      },

      function* asyncTakeEveryWatchers() {
        yield all(
          [
            LOAD_ACCOUNT,
            LOAD_USER_PROFILE,
            LOAD_LEVELS,
            LOAD_UNITS,
            LOAD_UNIT,
            LOAD_PAYMENTS,
            LOAD_PRODUCTS,
          ].map(action => takeEvery(action, createRequestSaga())),
        );
      },

      function* asyncAccountLoadWatchers() {
        yield all(
          [LOAD_ACCOUNT_SUCCESS, LOAD_USER_PROFILE_SUCCESS].map(action =>
            takeEvery(action, function*(actionData) {
              yield put(loadUnit(actionData.payload.currentUnit));
            }),
          ),
        );
      },

      function* asyncPaymentWatchers() {
        yield all(
          [
            VALIDATE_PIN_SUCCESS,
            UPLOAD_PURCHASE_SUCCESS,
            VOUCHER_PURCHASE_SUCCESS,
          ].map(action =>
            takeEvery(action, function*() {
              yield put(loadPayments());
            }),
          ),
        );
      },

      function* asyncPaymentWatchers() {
        yield all(
          [
            VALIDATE_PIN_SUCCESS,
            UPLOAD_PURCHASE_SUCCESS,
            VOUCHER_PURCHASE_SUCCESS,
          ].map(action =>
            takeEvery(action, function*() {
              // TODO: create separate action to load user data
              yield put(checkToken());
            }),
          ),
        );
      },

      function* asyncRegisterWatchers() {
        yield all(
          [REGISTER_SUCCESS].map(action =>
            takeEvery(action, function*() {
              yield put(toggleCertificateModal('signup'));
            }),
          ),
        );
      },
    ].map(watcher => fork(watcher)),
  );
}
