/* eslint-disable react/no-multi-comp */
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import {
  answerFragment,
} from '../../../../store/reducers/learn';
import { commonStyles } from '../../../../styles/theme';

import Text from '../../../../components/Text';
import Row from '../../../../components/Row';
import SoundBar from '../../../../components/SoundBar';
import TextInput from '../../../../components/TextInput';
import { stripHtml } from '../../../../utils/htmlParsers';

const styles = StyleSheet.create({
  playingAnimation: commonStyles.playingAnimation
});

class DictationInput extends React.Component {
  state = {
    answerText: '',
  }

  componentDidUpdate() {
    if (this.state.answerText.trim() === this.props.answerValue) {
      this.props.answer();
    }
  }

  render() {
    return (
      <TextInput
        multiline
        autoCorrect={false}
        underlineColorAndroid="rgba(0, 0, 0, 0)"
        value={this.state.answerText}
        hint={this.props.answerValue}
        color="blueDarken1"
        style={{ width: 400, marginHorizontal: 6 }}
        onChangeText={(newValue) => {
          this.setState({ answerText: newValue });
        }} />
    );
  }
}

class Dictation extends React.Component {
  state = {
    playingFragment: null
  }

  render() {
    const { module, unitId, answer } = this.props;
    const { playingFragment } = this.state;

    return (
      <View>
        {module.fragments.map(fragment => (
          <Row justify="flex-start" align="flex-start" style={{ marginVertical: 12 }} key={fragment.index}>
            <Text style={{ width: 100, marginTop: 16 }}>{fragment.title}</Text>
            <View style={{ width: 'calc(100% - 100px)' }}>
              {fragment.fragmentParts.map(fPart => {
                let answerValue = fPart ? stripHtml(fPart.text) : '';
                const lastChar = answerValue[answerValue.length - 1];
                let isInterpunction;
                if (fPart && (lastChar === '.' || lastChar === '?' || lastChar === '!')) {
                  answerValue = answerValue.substring(0, answerValue.length - 1);
                  isInterpunction = true;
                }
                const isThisFragmentPlaying = fPart.id === playingFragment;

                return (
                  <Row justify="flex-start" style={[{ marginVertical: 6 }, isThisFragmentPlaying && styles.playingAnimation]} key={fPart.id}>
                    <SoundBar
                      onEnded={() => {
                        this.setState({ playingFragment: null });
                      }}
                      onStarted={() => {
                        this.setState({ playingFragment: fPart.id });
                      }}
                      disabled={!!playingFragment}
                      source={fPart.voice}
                      key={fPart.id}
                      title={fPart.fragmentTitle} />
                    {fPart.isAnswered ? (
                      <Text>
                        {answerValue}
                        {lastChar}
                      </Text>
                    ) : (
                      <DictationInput
                        answerValue={answerValue}
                        answer={() => {
                          answer({
                            unitId,
                            moduleIndex: module.index,
                            fragmentIndex: fragment.index,
                            fragmentPartId: fPart.id,
                            fragmentPartIndex: fPart.index
                          });
                        }} />
                    )}

                    {!fPart.isAnswered && isInterpunction && <Text>{lastChar}</Text>}
                  </Row>
                );
              })}
            </View>
          </Row>
        ))}
      </View>
    );
  }
}

export default connect(
  () => ({}),
  { answer: answerFragment }
)(Dictation);
