export default {
  title: 'Политика за поверителност на Bell.bg',
  text: `
Този уебсайт събира лични данни от своите потребители.

Европейският съюз прие един документ, наречен Общ регламент относно защитата на данните (ЕС) 2016/679 на Европейския парламент и на Съвета от 27.04.2016г., който ние ще наричаме просто „Регламента“, с цел да защити използването на твоите лични данни. Според Регламента „лични данни“ е всяка информация, която се отнася до теб и чрез която можеш да бъдеш идентифициран. Използването на твоите лични данни Регламентът нарича „обработване“ и това може да бъде не само събирането им от нас когато ползваш услугите на сайта www.bell.bg („услугите“), но и всяко друго действие с тях.


Собственик и контролер на данни 
Консултинг Солюшънс ЕООД, със седалище и адрес на управление: София, Ген. Йосиф Гурко 64, ЕИК 204383014, BG204383014, представлявано от Стефан Георгиев.

Видове събирани данни 
Между видовете данни, които събира този уебсайт, самостоятелно или чрез трети страни, са: бисквитки, данни за използването и имейл адреси.

Пълни подробности за всеки вид събирани лични данни се дават в съответните раздели на тази политика за поверителност или от специфични обяснителни текстове, представени преди събирането на данни. 
Лични данни могат да се дават свободно от потребителя или, в случая с данните за използването, да се събират автоматично, когато се използва този уебсайт.

Освен ако не е посочено друго, всички данни, изисквани от този уебсайт, са задължителни, и ако не бъдат дадени, той няма как да извърши услугите си. В случаите, когато този уебсайт уточнява конкретно, че дадени данни не са задължителни, потребителите могат да не предоставят тези данни, без последствия за наличността или функционирането на услугата.

Потребителите, които не са сигурни кои лични данни са задължителни, могат да се свържат със собственика. 
Всяко използване на бисквитки – или на други инструменти за проследяване – от този уебсайт или от собствениците на услугите на трети страни, използвани от него, служи на целта да се осигури услугата, изисквана от потребителя, в допълнение към всички други цели, описани в настоящия документ и в политиката за бисквитките, ако има такава.

Потребителите са отговорни за всички лични данни за трети страни, получени, публикувани или споделени чрез този уебсайт, и потвърждават, че имат съгласието на третата страна за представяне на данните на Собственика.

Режим и място на обработка на данните

Методи на обработка 
Собственикът предприема съответните мерки за сигурност за предотвратяване на неоторизиран достъп, разкриване, модифициране или неоторизирано унищожаване на данните. 
Обработката на данните се осъществява посредством използването на компютри и/или активирани ИТ инструменти, следвайки организационни процедури и режими, строго свързани с обозначените цели. Освен за собственика, в някои случаи данните може да бъдат достъпни за определени типове отговорни лица, участващи в оперирането на този уебсайт (администриране, продажби, маркетинг, право, системно администриране) или външни страни (като трети страни, свързани с техническо обслужване, разнасяне на поща, хостинг доставчици, ИТ компании, комуникационни агенции), назначени, ако е необходимо, да се занимават с обработката на данни от собственика. Актуалният списък с тези страни може да бъде изискан от собственика по всяко време.

Правна основа на обработката 
Собственикът може да обработва лични данни, свързани с потребителите, при наличие на едно от следващите условия: 
• Потребителите са дали съгласието си за една или повече конкретни цели. Забележка: В съответствие с някои законодателства, на собственика може да му е позволено да обработва лични данни, докато потребителят не се противопостави на тази обработка (откаже), без да трябва да разчита на даването на съгласие или друга от следните правни основи. Това обаче не е приложимо, когато обработката на лични данни е подчинена на Европейския регламент за защита на личните данни; 
• предоставянето на данни е необходимо за изпълнението на споразумение с потребителя и/или за производните от него преддоговорни задължения; 
• обработката е нужна в съответствие с правно задължение, на което е подчинен собственикът; 
• обработката е свързана с работа, извършвана в обществен интерес или в изпълнение на официално предоставени на собственика правомощия; 
• обработката е необходима за осъществяването на легитимни цели, преследвани от собственика или от трета страна.

Във всеки случай собственикът ще помага с готовност за изясняване на специфичните правни основи на обработката на данни и в частност – дали доставянето на лични данни е установено със закон или договорно изискване, или изискване, необходимо за сключване на договор.

Място 
Данните се обработват в действащите офиси на собственика и на всяко друго място, където са разположени участващите в обработката страни.

В зависимост от местоположението на собственика, прехвърлянето на данни може да включва прехвърляне на данните на потребителя в друга, освен собствената му страна. За да научат повече за мястото на обработване на такива прехвърлени данни, потребителите могат да направят справка с раздела, съдържащ подробности за обработката на лични данни. 
Потребителите са в правото си също така да се информират за правните основи на прехвърлянето на данни в страна извън Европейския съюз или във всяка международна организация, регулирана от международното публично право или създадена от две или повече страни, като ООН, и за мерките за сигурност, взети от собственика, за опазване на техните данни.

Ако има подобно прехвърляне на данни, потребителите могат да научат повече, като направят справка със съответните раздели на този документ или се допитат до собственика, използвайки информацията, дадена в раздела за осъществяване на контакт.

Време на съхраняване на данни 
Личните данни ще бъдат обработвани и съхранявани толкова дълго, колкото е необходимо за целите, за които са били взети.

Следователно: 
• Личните данни, събрани за цели, свързани с дейността на договор между собственика и потребителя, ще бъдат съхранявани до пълното осъществяване на договореното. 
• Личните данни, събрани за целите на законни интереси на собственика, ще бъдат съхранявани толкова дълго, колкото е нужно за пълното осъществяване на договореното. Потребителите могат да намерят конкретна информация за законните интереси, преследвани от собственика, в съответните раздели на този документ или като се свържат със собственика.

На собственика може да му бъде позволено да съхранява лични данни за по-дълъг период, когато потребителят е дал съгласието си за такава обработка, стига това съгласие да не е било оттеглено. Освен това собственикът може да бъде задължен да съхранява лични данни за по-дълъг период, когато това се налага заради изпълнението на законово задължение или нареждане от съответните органи.

След изтичане на периода за съхранение, личните данни ще бъдат изтрити. Следователно правото за достъп, правото на заличаване, правото на поправка и правото на преносимост на данни не могат да бъдат привеждани в сила след изтичане на периода на съхраняване на данни.

Цели на обработката 
Данните, свързани с потребителя, се събират, за да се даде възможност на собственика да осигури своите услуги, както и със следните цели: анализ и управление на контактите и изпращане на съобщения.

Потребителите могат да открият по-подробна информация за такива цели за обработка и за специфичните лични данни, използвани за всяка цел, в съответните раздели на този документ.

Подробна информация за обработката на лични данни 
Лични данни се събират за следните цели и посредством следните услуги:

Анализ 
Услугите, съдържащи се в този раздел, позволяват на собственика да следи и анализира интернет трафика, и могат да се използват за проследяване поведението на потребителя.

Google Analytics (Google Inc.) 
Google Analytics е уеб услуга за извършване на анализи, осигурена от Google Inc. („Google“). Google използва събраните данни, за да следи и изследва използването на този уебсайт, да подготвя доклади за неговата дейност и да ги споделя с други услуги на Google. 
Google може да използва събраните данни, за да анализира и персонализира рекламите от рекламната си мрежа.

Събирани лични данни: бисквитки и данни за използването.
Място на обработка: България 

Управление на контактите и изпращане на съобщения 
Този вид услуга прави възможен контрола на база данни с имейли, телефонни номера или друга информация за осъществяване на контакт и комуникация с потребителя. 
Тази услуга може да събира също така данни за датата и времето, когато съобщението е било видяно от потребителя, както и когато потребителят е взаимодействал с него, например кликвайки върху линкове, включени в съобщението.

Събирани лични данни: имейл адрес.
Място на обработка: България.

Права на потребителите 
Потребителите могат да упражняват определени права във връзка със свои данни, обработени от собственика. 
В частност, потребителите имат правото да правят следното: 
• Да оттеглят съгласието си по всяко време. Потребителите имат правото да оттеглят вече даденото си съгласие за обработване на личните им данни. 
• Да се противопоставят срещу обработката на своите данни. Потребителите имат правото да се противопоставят срещу обработката на своите данни, ако обработката се прави на друга правна основа, освен съгласието. Повече подробности са дадени в посветения на тази тема раздел по-долу. 
• Достъп до свои данни. Потребителите имат правото да научават, ако данни се обработват от собственика, да изискват информиране за определени аспекти на обработката на данни и да получават копие на данните, които се обработват. 
• Да проверяват и да искат корекции. Потребителите имат правото да проверяват точността на своите данни и да молят да бъдат осъвременявани или коригирани. 
• Да ограничават обработката на своите данни. Потребителите имат правото, при определени обстоятелства, да ограничават обработката на своите данни. В такъв случай собственикът ще обработва данните им единствено с цел съхранение. 
• Да изискат личните им данни да бъдат изтрити или отстранени по друг начин. Потребителите имат правото, при определени обстоятелства, да изискат от собственика личните им данни да бъдат изтрити. 
• Да получат своите данни и да ги прехвърлят при друг контролер. Потребителите имат правото да получат своите данни в структуриран, масово използван и машинно четим формат и, ако е технически осъществимо, да помолят да бъде предаден на друг контролер безпрепятствено. Тази клауза е приложима при условие, че данните се обработват автоматично и обработката се прави със съгласието на потребителя, съгласно сключения с потребителя договор или в съответствие с преддоговорни задължения. 
• Да подадат жалба. Потребителите имат правото да предявят правни претенции пред компетентни органи за защита на личните данни.

Подробности във връзка с правото за противопоставяне срещу обработката на лични данни 
Когато лични данни се обработват в обществен интерес, в изпълнение на официално предоставени на собственика правомощия или за целите на легитимни интереси, преследвани от собственика, потребителите могат да се противопоставят срещу тази обработка, като се основават на конкретната си ситуация, за да обосноват противопоставянето.

Потребителите обаче трябва да знаят, че ако личните им данни се обработват за целите на директния маркетинг, могат да се противопоставят срещу тази обработка по всяко време без да се обосновават. За да разберат дали собственикът обработва лични данни за целите на директния маркетинг, потребителите могат да направят справка със съответните раздели на този документ.

Как се упражняват тези права 
Всяко искане за упражняване правата на потребителя може да бъде отправяно към собственика посредством информацията за осъществяване на контакт с него, дадена в този документ. Отправянето на тези молби е безплатно и собственикът ще се заеме с тях при първа възможност в срок до един месец.

Допълнителна информация за събиране и обработка на лични данни
Правни действия 
Личните данни на потребителя могат да бъдат използвани от собственика за правни цели в съда или в етапите, водещи до евентуални правни действия поради неправилното използване на този уебсайт или свързаните с него услуги.

Потребителят декларира, че е наясно, че е възможно собственикът да трябва да разкрие лични данни при поискване от страна на публичните власти.

Допълнителна информация за личните данни на потребители 
Освен информацията, съдържаща се в тази политика за поверителност, този уебсайт може да даде на потребителя допълнителна и контекстуална информация относно определени услуги или събирането и обработката на лични данни при поискване.



Системни регистрационни файлове и поддръжка 
За оперативни цели и във връзка с поддръжката, този уебсайт и всякакви услуги на трета страна могат да събират файлове, които записват взаимодействия с този уебсайт (системни регистрационни файлове) и използват други лични данни (като IP адрес) за тази цел.

Информация, която не се съдържа в тази политика за поверителност 
Повече подробности във връзка със събирането или обработката на лични данни може да бъдат поискани от собственика по всяко време. Моля вижте информацията за осъществяване на контакт с него в началото на този документ.

Промени в тази политика за поверителност 
Собственикът си запазва правото да прави промени в тази политика за поверителност по всяко време, уведомявайки потребителите си на тази страница и евентуално – в този уебсайт и/или – доколкото е осъществимо от техническа и от правна гледна точка – изпращайки съобщение на потребителите чрез всички средства за контакт, за които има информация. Препоръчително е тази страница да се преглежда често и да се обръща внимание на датата на последната промяна, дадена най-долу.

Ако промените засягат дейности, свързани с обработката на лични данни въз основа на съгласието на потребителя, собственикът трябва да получи ново съгласие от потребителя, където е необходимо.

Дефиниции и правни справки
Лични данни (или данни) 
Всяка информация, която директно, индиректно или във връзка с друга информация – включително личен идентификационен номер – позволява да бъде идентифицирано или разпознато физическо лице.

Данни за използването 
Информация, събирана автоматично чрез този уебсайт (или услуги на трета страна, използвани в този уебсайт), която може да включва: IP адресите или имената на домейните на компютрите, използвани от потребителя, използващ този уебсайт, URI адресите (универсален идентификатор на ресурси), времето на заявката, метода, използван за отправяне на заявката/запитването към сървъра, големината на файла, получен в отговор, цифровия код, обозначаващ статуса на отговора на сървъра (успешен изход, грешка и т. н.), страната на произход, характеристиките на браузъра и оперативната система, използвана от потребителя, различните времеви детайли на всяко посещение (напр. времето, прекарано на всяка страница в апликацията) и детайли за пътя, следван вътре в апликацията, със специална справка за последователността на посетените страници, и други параметри за оперативната система на устройството и/или ИТ среда на потребителя.

Потребител 
Индивидът, използващ този уебсайт, който, освен ако не се упоменава изрично друго, съвпада със субекта на данните.

Субект на данните 
Физическото лице, за когото се отнасят личните данни.

Администратор (или супервайзър) на данните 
Физическото или юридическото лице, публична власт, агенция или друг орган, който обработва лични данни от името на контролера, както е описано в тази политика за поверителност.

Контролер на данни (или собственик) 
Физическото или юридическото лице, публична власт, агенция или друг орган, което, самостоятелно или съвместно с други, определя целите и средствата за обработването на лични данни, включително мерките за сигурност във връзка с дейността и използването на този уебсайт. Контролерът на данни, освен ако не е посочено друго, е собственикът на този уебсайт.

Този уебсайт (или това приложение) 
Средствата, посредством които се събират и обработват личните данни на потребителя.

Услуга 
Услугата, предоставяна от този уебсайт, както е описана в съответната част на условията (ако са налични), и на този сайт/приложение.

Европейски съюз (или ЕС) 
Освен ако не е посочено друго, всички препратки, направени в този документ към Европейския съюз, включват всички настоящи държави-членки на Европейския съюз и Европейското икономическо пространство.

Бисквитки 
Малки файлове с данни, съхранявани в устройството на потребителя. Улесняват работата на сайта.

Правна информация 
Този текст за политиката за поверителност е подготвен въз основа на множество законови разпоредби, включително член 13/14 от Регламент (ЕС) 2016/679 (General Data Protection Regulation, GDPR).

Представената тук политика за поверителност се отнася само за този уебсайт, освен ако не е посочено друго в самия този документ.

Последна актуализация: 30.11.2018 г.   
  `
};
