import { StyleSheet } from 'react-native';
import { colors } from '../../styles/theme';

const styles = StyleSheet.create({
  underline: {
    borderBottomWidth: 4,
    borderBottomColor: colors.grey,
    position: 'absolute',
    top: 13,
    left: 0
  },
  progressLine: {
    borderBottomWidth: 4,
    borderBottomColor: colors.red,
    position: 'absolute',
    top: 13,
    left: 0,
  },
  progressMini: {
    borderBottomColor: colors.blueDarken1,
  },
  indicator: {
    position: 'absolute',
    top: 0,
    backgroundColor: colors.blueDarken1,
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  indicatorMini: {
    backgroundColor: colors.red,
  },
  container: {
    height: 30,
    marginVertical: 10,
    marginHorizontal: 60,
  },
  containerWeb: {
    width: '100%',
    maxWidth: 1092 - 120,
    position: 'relative'
    // marginHorizontal: 0,
    // paddingHorizontal: 60
  },
  miniContainer: {
    marginTop: -17,
    marginBottom: 0,
    marginHorizontal: 0,
    zIndex: 5
  },
  disabled: {
    backgroundColor: '#A5A4A9'
  }
});

export default styles;
