import React from 'react';
import {
  View, Image, StyleSheet
} from 'react-native';
import Row from '../../../../components/Row';
import Text from '../../../../components/Text';
import london from './london.jpg';
import Logo from './logo';
import { colors } from '../../../../styles/theme';

const styles = StyleSheet.create({
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: '-50%' }, { translateY: '-50%' }],
    width: 400
  },
  logo: {
    position: 'absolute',
    left: 0,
    width: 152,
    height: 332
  },
  cover: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    width: '100%'
  }
});

const HomeWrapper = ({ children }) => (
  <View style={{ position: 'relative', height: '100%' }}>

    <View style={styles.cover}>
      <Image
        source={london}
        style={{
          flex: 1, resizeMode: 'cover',
        }} />
      <View style={[styles.cover, { backgroundColor: colors.blueDarken1rgba('0.45') }]} />
    </View>
    <View style={styles.content}>
      <Logo style={{ width: 180 }} />
      <Text color="white" fontSize={40} style={{ marginBottom: 40 }}>
        {'Bulgarian - English'}
      </Text>
      <Text color="white" fontSize={40} style={{ marginBottom: 40 }}>
        {'Language Learning'}
      </Text>
      <Row style={{ width: '100%' }}>
        {children}
      </Row>
    </View>
  </View>
);

export default HomeWrapper;
