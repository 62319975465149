import React from 'react';
import {
  TouchableOpacity, Image, TextInput, StyleSheet
} from 'react-native';
import debounce from 'lodash/debounce';
import { commonStyles } from '../../styles/theme';
import addIcon from '../../icons/add.png';
import deleteIcon from '../../icons/delete.png';
import Row from '../Row';

const styles = StyleSheet.create({
  addButtonWrapper: {
    marginVertical: 20,
    marginHorizontal: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  deleteButtonWrapper: {
    position: 'absolute',
    right: 10,
    top: 14,
  },
  buttonImage: {
    width: 20,
    height: 20,
  },
  input: {
    width: '50%',
    paddingVertical: 14,
    paddingHorizontal: 10,
    height: 46
  }
});

export const AddButton = props => (
  <TouchableOpacity style={styles.addButtonWrapper} {...props}>
    <Image style={styles.buttonImage} source={addIcon} />
  </TouchableOpacity>
);

const inputProps = {
  spellCheck: false,
  autoCapitalize: 'none',
  underlineColorAndroid: 'rgba(0, 0, 0, 0)',
  // keyboardType: 'ascii-capable'
};

export default class DictionaryLine extends React.Component {
  state = {
    word: this.props.word[0] || '',
    translation: this.props.word[1] || ''
  }

  componentDidUpdate(_, prevState) {
    if (prevState.word !== this.state.word || prevState.translation !== this.state.translation) {
      this.onUpdate();
    }
  }

  onUpdate = debounce(() => {
    this.props.editWord(this.props.index, [this.state.word, this.state.translation]);
  }, 1000)

  render() {
    const { word, translation } = this.state;
    return (
      <Row style={{ width: '100%', ...commonStyles.borderBottom }}>
        <TextInput
          {...inputProps}
          style={[styles.input, commonStyles.borderRight]}
          onChangeText={text => {
            this.setState({ word: text });
          }}
          value={word} />

        <TextInput
          {...inputProps}
          style={styles.input}
          onChangeText={text => {
            this.setState({ translation: text });
          }}
          value={translation} />


        <TouchableOpacity
          style={styles.deleteButtonWrapper}
          onPress={() => {
            this.props.removeWord(this.props.index);
          }}>
          <Image style={styles.buttonImage} source={deleteIcon} />
        </TouchableOpacity>
      </Row>
    );
  }
}
