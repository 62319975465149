import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { colors } from '../../../../styles/theme';
import Text from '../../../../components/Text';
import { Link } from '../../../../components/Link';

const styles = StyleSheet.create({
  link: {
    backgroundColor: colors.white,
    padding: 10,
    width: 260,
    borderRadius: 10,
  },
});

// Try to remove text component
const HomeLink = ({ children, to, style }) => (
  <View style={[styles.link, style]}>
    <Link to={to} style={Platform.OS === 'web' && { textDecorationLine: 'none', textAlign: 'center' }}>
      <Text align="center">{children}</Text>
    </Link>
  </View>
);

export default HomeLink;
