import React from 'react';
import { connect } from 'react-redux';
import {
  answerFragment,
} from '../../../../store/reducers/learn';
import Actionable from './Actionable';
import NotActionable from './NotActionable';
import CorrectText from './CorrectText';
import CorrectTranslation from './CorrectTranslation';
import YesNoQuestion from './YesNoQuestion';

// const notActionable = 'fragment.type.not-actionable';
// const yesNoQuestion = 'fragment.type.yes-no-question';

function stripHtml(html) {
  return html.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ');
}

const FragmentPart = ({
  data, answer, unitId, moduleIndex, fragmentIndex, playingFragment, setPlayingFragment
}) => {
  const onAnswer = () => {
    answer({
      unitId,
      moduleIndex,
      fragmentIndex,
      fragmentPartId: data.id,
      fragmentPartIndex: data.index
    });
  };

  if (data.correct !== null) {
    return <YesNoQuestion data={data} onAnswer={onAnswer} />;
  }

  const formatedText = data.text && stripHtml(data.text);
  if (formatedText && /_/g.test(formatedText)) {
    return <CorrectText data={data} onAnswer={onAnswer} formatedText={formatedText} />;
  }

  const formatedTranslation = data.translation && stripHtml(data.translation);
  if (formatedTranslation && /_/g.test(formatedTranslation)) {
    return <CorrectTranslation data={data} onAnswer={onAnswer} formatedText={formatedTranslation} />;
  }


  if (data.actionable) {
    return <Actionable data={data} onAnswer={onAnswer} playingFragment={playingFragment} setPlayingFragment={setPlayingFragment} />;
  }

  return <NotActionable data={data} />;
};

export default connect(
  () => ({}),
  { answer: answerFragment }
)(FragmentPart);
