import { connect } from 'react-redux';
import {
  loadUnits,
} from '../../store/reducers/learn';

import UnitList from './components/UnitList';

export default connect(
  ({ learn, authentication, payments }, props) => ({
    level: learn.levels.find(level => level.id.toString() === props.match.params.levelId),
    isAuthenticated: !!authentication.token,
    isActive: !!payments.activePayment,
    payments
    // isLevelActive: !!payments.payments.find(payment => payment.level.toString() === props.match.params.levelId && payment.status === 'COMPLETED')
  }),
  { loadUnits }
)(UnitList);
