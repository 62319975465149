import {
  call,
  put,
  take,
  race,
  select, // , delay
} from 'redux-saga/effects';
// import { delay } from 'redux-saga';
import request from '../../utils/request';
import { LOG_OUT } from '../reducers/authentication';

/* eslint-disable func-names */
export const createRequestSaga = () =>
  function*({ payload, fetchParams, type, ...rest }) {
    // action object
    // type is here just to omit it from {rest}
    // rest is other parameter passed to action that might be required by SUCCESS or FAIL actions
    const { path, method, success, fail, cancel } = fetchParams;

    try {
      // Call our request helper (see 'utils/request')
      // const data = yield call(request, path, payload);
      const token = yield select(state => state.authentication.token);

      const options = {};

      // if (token) options.token = token;
      if (method) options.method = method;

      const raceOptions = {
        data: call(request, path, payload, options, token),
        // isTimeout: call(delay, 10000)
      };

      if (cancel) {
        raceOptions.cancelReq = take(cancel);
      }

      // TODO: fix eslint to allow named object: https://github.com/pke/eslint-plugin-redux-saga/blob/develop/lib/rules/no-yield-in-race.js
      // Create a pull request to fix it
      const { data, isTimeout, cancelReq } = yield race(raceOptions);

      if (isTimeout) {
        // timed out
        const error = new Error('Api method has timed out');

        error.response =
          'Response timed out, please check internet connection and try again';
        throw error;
      } else if (cancelReq) {
        // if cancel is called
        yield put({ type: cancel });
      } else {
        // succesfull action
        yield put({
          type: success,
          payload: data,
          prevPayload: payload,
          ...rest,
        });
      }
    } catch (err) {
      if (
        err.response &&
        (err.response.error === '{"detail":"Error decoding signature."}' ||
          err.response.error === '{"detail":"Signature has expired."}')
      ) {
        yield put({
          type: LOG_OUT,
        });
      }
      // TODO: better error handling
      yield put({
        type: fail,
        payload: err,
        prevPayload: payload,
        ...rest,
      });
    }
  };
