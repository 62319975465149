import React from 'react';
import { View, StyleSheet, StatusBar, AppState } from 'react-native';

import { connect } from 'react-redux';

import SplashScreen from '../../utils/splashcreen';

import { checkToken } from '../../store/reducers/authentication';
import { loadDictionary } from '../../store/reducers/dictionary';
import {
  loadLevels,
  setSuggestedLevelFromStorage,
} from '../../store/reducers/learn';
import { loadPayments, loadProducts } from '../../store/reducers/payments';
import { setupPurchaseListener } from '../../store/reducers/inapProducts';
import Dictionary from '../../components/Dictionary';
import CertificateModal from '../../components/CertificateModal';
import Router from './components/Router';

const mapStateToProps = ({ authentication, dictionary, payments }) => ({
  token: authentication.token,
  dictionary,
  payments,
});

const mapDispatchToProps = {
  checkToken,
  loadDictionary,
  loadLevels,
  loadPayments,
  loadProducts,
  setSuggestedLevelFromStorage,
  setupPurchaseListener,
};

const styles = StyleSheet.create({
  wrapper: {
    height: '100%',
  },
});

class Wrapper extends React.Component {
  componentDidMount() {
    const params = window.location.href.match(
      /(?<=\/(signup|login)\/)[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/gm,
    );

    const subscription_id = params && params.length > 0 ? params[0] : null;

    this.props.checkToken(!!subscription_id);
    this.props.loadDictionary();
    this.props.setSuggestedLevelFromStorage();
    setTimeout(SplashScreen.hide, 1000);

    this.props.setupPurchaseListener();
    AppState.addEventListener('change', this.handleAppStateChange);
    // this.pollingStarter = setTimeout(() => {
    //   if (!this.props.activePayment) {
    //     this.startPollingPayment();
    //   }
    // }, 120000);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.token &&
      this.props.token !== 'loading' &&
      prevProps.token !== this.props.token
    ) {
      this.props.loadLevels();
      this.props.loadPayments();
      this.props.loadProducts();
      // this.props.checkExistingPurchases();
    } else if (prevProps.token === 'loading') {
      this.props.loadLevels();
    }
  }

  componentWillUnmount() {
    this.removeSubscriptionLister();
    AppState.addEventListener('change', this.handleAppStateChange);
    this.stopPollingPayment();
    clearTimeout(this.pollingStarter);
    this.pollingStarter = null;
  }

  // TODO: test if this does anything, complete functional component in wip file
  removeSubscriptionLister = () => {
    if (this.purchaseUpdateSubscription) {
      this.purchaseUpdateSubscription.remove();
      this.purchaseUpdateSubscription = null;
    }
    if (this.purchaseErrorSubscription) {
      this.purchaseErrorSubscription.remove();
      this.purchaseErrorSubscription = null;
    }
  };

  handleAppStateChange = (nextAppState) => {
    if (nextAppState === 'active' && !this.props.activePayment) {
      this.props.loadPayments();
      this.startPollingPayment();
    }
    if (nextAppState === 'background') {
      this.stopPollingPayment();
    }
  };

  startPollingPayment = () => {
    this.paymentPollingInterval = setInterval(() => {
      this.props.loadPayments();
    }, 120000);
  };

  stopPollingPayment = () => {
    clearInterval(this.paymentPollingInterval);
    this.paymentPollingInterval = null;
  };

  render() {
    const { token, dictionary } = this.props;

    return (
      <View style={styles.wrapper}>
        <StatusBar backgroundColor="#092481" barStyle="light-content" />
        <Router token={token} />
        {dictionary.isVisible && <Dictionary dictionary={dictionary} />}
        <CertificateModal />
      </View>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
