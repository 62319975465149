import React from 'react';
import { Image, TouchableOpacity } from 'react-native';
import { colors, commonStyles } from '../../styles/theme';
import helpIcon from '../../icons/help-purple.png';
import helpFilledIcon from '../../icons/help-fill.png';
import Text from '../Text';
import Row from '../Row';

const HeadingTitle = ({
  children, isHelpVisible, onHelpClick, style
}) => (
  <Row
    justify="space-between"
    style={{
      backgroundColor: colors.white,
      paddingVertical: 17,
      paddingHorizontal: 50,
      width: '100%',
      ...commonStyles.borderBottom,
      ...style
    }}>
    <Text style={{ fontWeight: 'bold', lineHeight: 20 }}>
      {children}
    </Text>

    {onHelpClick && (
      <TouchableOpacity onPress={onHelpClick}>
        <Image source={isHelpVisible ? helpFilledIcon : helpIcon} style={{ width: 23, height: 23 }} />
      </TouchableOpacity>
    )}
  </Row>
);

export default HeadingTitle;
