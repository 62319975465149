import { AsyncStorage } from 'react-native';
// import {LOAD_ACCOUNT, }

const DICTIONARY_KEY = 'dictionary';

const TOGGLE_DICTIONARY = 'dictionary.TOGGLE_DICTIONARY';

const ADD_WORD = 'dictionary.ADD_WORD';
const EDIT_WORD = 'dictionary.EDIT_WORD';
const REMOVE_WORD = 'dictionary.REMOVE_WORD';

export const LOAD_DICTIONARY_SUCCESS = 'dictionary.LOAD_DICTIONARY_SUCCESS';
export const LOAD_DICTIONARY_FAIL = 'dictionary.LOAD_DICTIONARY_FAIL';

export function toggleDictionary() {
  return {
    type: TOGGLE_DICTIONARY
  };
}

export function addWord() {
  return {
    type: ADD_WORD
  };
}

export function editWord(index, word) {
  return {
    type: EDIT_WORD, index, word
  };
}

export function removeWord(index) {
  return {
    type: REMOVE_WORD,
    index
  };
}

export function loadDictionary() {
  return async dispatch => {
    try {
      const words = await AsyncStorage.getItem(DICTIONARY_KEY);
      dispatch({
        type: LOAD_DICTIONARY_SUCCESS,
        words: words ? JSON.parse(words) : []
      });
    } catch (e) {
      storeDictionary(['', '']);
      dispatch({
        type: LOAD_DICTIONARY_FAIL,
      });
    }
  };
}

function storeDictionary(words) {
  try {
    AsyncStorage.setItem(DICTIONARY_KEY, JSON.stringify(words));
  // eslint-disable-next-line no-empty
  } catch (e) {}
}

const initialState = {
  words: [],
  isVisible: false
};

export default function dictionaryReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DICTIONARY_SUCCESS:
      return {
        ...state,
        words: action.words,
      };


    case TOGGLE_DICTIONARY:
      return {
        ...state,
        isVisible: !state.isVisible
      };

    case ADD_WORD:
      return {
        ...state,
        words: [...state.words, ['', '']]
      };

    case EDIT_WORD: {
      const newWords = state.words.map((word, i) => (
        i === action.index
          ? action.word
          : word
      ));

      storeDictionary(newWords);

      return {
        ...state,
        words: newWords
      };
    }

    case REMOVE_WORD: {
      const newWords = state.words.filter((word, i) => i !== action.index);

      storeDictionary(newWords);

      return {
        ...state,
        words: newWords
      };
    }

    case LOAD_DICTIONARY_FAIL: {
      return {
        ...state,
        words: []
      };
    }

    default:
      return state;
  }
}
