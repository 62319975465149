import React from 'react';
import Html from '../../../../components/NativeHtml';
import FragmentPartWrapper from './FragmentPartWrapper';
import TextWithInput from './TextWithInput';

// import HTML from 'react-native-render-html';


export default class CorrectText extends React.PureComponent {
  render() {
    const { data, formatedText, onAnswer } = this.props;

    return (
      <FragmentPartWrapper>
        <TextWithInput formatedText={formatedText} onAnswer={onAnswer} isAnswered={data.isAnswered} />
        {data.translation ? <Html html={data.translation} secondLine /> : null}
      </FragmentPartWrapper>
    );
  }
}
