import { connect } from 'react-redux';
import {
  loadUnit,
} from '../../store/reducers/learn';

import Unit from './components/Unit';

export default connect(
  ({ authentication, learn, payments }, props) => ({
    level: learn.levels.find(level => level.id.toString() === props.match.params.levelId),
    unit: learn.units[props.match.params.unitId], // props.match.params.unitId
    isAuthenticated: !!authentication.token,
    isActive: !!payments.activePayment,
  }),
  { loadUnit }
)(Unit);
