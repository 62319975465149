import { connect } from 'react-redux';
import {
  forgotPassword,
  resetPassword
} from '../../store/reducers/authentication';
import ResetPasswordPage from './components';

export default connect(({ authentication: { resetPasswordSatus, resetErrors } }) => ({
  resetPasswordSatus,
  errors: resetErrors
}), { forgotPassword, resetPassword })(ResetPasswordPage);
