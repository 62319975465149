import { connect } from 'react-redux';
import {
  loadLevels,
} from '../../store/reducers/learn';

import LevelsPage from './components/LevelsPage';

export default connect(({
  learn, authentication, user, // payments
}) => ({
  learn,
  isAuthenticated: !!authentication.token,
  suggestedLevel: learn.suggestedLevel,
  initiated: user.initialLevelSelected,
  // activeLevels: user.activeLevels,
  activeLevel: user.currentUnit && learn.units[user.currentUnit] && !learn.units[user.currentUnit].loading && learn.levels && learn.levels.find(level => level.id === learn.units[user.currentUnit].level),
  // activePayment: payments.activePayment
}), { loadLevels })(LevelsPage);
