export const UPLOAD_PURCHASE = 'inapp.UPLOAD_PURCHASE';
export const UPLOAD_PURCHASE_SUCCESS = 'inapp.UPLOAD_PURCHASE_SUCCESS';
export const UPLOAD_PURCHASE_FAIL = 'inapp.UPLOAD_PURCHASE_FAIL';

export function loadStoreProducts() {
  return { type: 'ignore' };
}

export function checkExistingPurchases() {
  return { type: 'ignore' };
}

export function purchaseProduct() {
  return { type: 'ignore' };
}

export function endConnection() {
  return { type: 'ignore' };
}

export function handlePurchase() {
  return { type: 'ignore' };
}

export function handlePurchaseError() {
  return { type: 'ignore' };
}

export function setupPurchaseListener() {
  return { type: 'ignore' };
}

export default function inappProducts() {
  return { products: [] };
}
