import React from 'react';
import {
  View, TouchableOpacity, Image, StyleSheet
} from 'react-native';
import { colors, commonStyles } from '../../styles/theme';
import closeIcon from '../../icons/close.png';
import Text from '../Text';

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    flex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    paddingTop: 90,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    backgroundColor: colors.white,
    borderRadius: 20,
    overflow: 'hidden',
    width: '90%',
    maxWidth: 400,
    ...commonStyles.elevation3
  },
  header: {
    ...commonStyles.borderBottom,
    height: 40,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10
  },
  closeButtonImage: {
    width: 20,
    height: 20,
  }
});

export default ({
  title, children, onClose, contentStyles
}) => (
  <View style={styles.overlay}>
    <View style={styles.wrapper}>
      <TouchableOpacity onPress={onClose} style={styles.closeButton}>
        <Image style={styles.closeButtonImage} source={closeIcon} />
      </TouchableOpacity>
      <View styles={styles.header}>
        {title && <Text color="greyDarken" style={{ marginVertical: 10, marginHorizontal: 50, fontWeight: 'bold' }}>{title}</Text>}
      </View>
      <View style={contentStyles}>
        {children}
      </View>
    </View>
  </View>
);
