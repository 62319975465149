/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { checkStatus } from '../../../../utils/request';

class VideoPlayer extends React.Component {
  state = {
    subtitles: null
  }

  componentDidMount() {
    // Fix for:
    // Unsafe attempt to load URL {s3 url} from frame with URL https://bell.bg. Domains, protocols and ports must match.
    // Fix by using fetch to load subtitles and server from local state
    fetch(this.props.subtitles)
      .then(checkStatus)
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then(url => {
        this.setState({ subtitles: url });
      });
  }

  render() {
    const {
      source, innerRef, selectedTextTrack, onEnd, paused, ...props
    } = this.props;
    const { subtitles } = this.state;
    return (
      <video
        {...props}
        controls
        controlsList="nodownload"
        onEnded={onEnd}
        ref={innerRef}>
        {subtitles && <track label="English" kind="captions" srcLang="en" src={subtitles} default />}
        <source src={source.uri} type="video/mp4" />
      </video>
    );
  }
}

export default VideoPlayer;
