import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import Text from '../../../components/Text';
import Row from '../../../components/Row';
import TextInput from '../../../components/TextInput';
import Button, { NavLink } from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import Modal from '../../../components/Modal';
import KeyboardAwareScrollView from '../../../components/KeyboardAwareScrollView';
import { Link } from '../../../components/Link';

const styles = StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const LinkText = ({ children, to, style }) => (
  <Link to={to} style={{ padding: 0, margin: 0, ...style }}>
    <Text color="white" style={{ textDecorationLine: 'underline' }}>
      {children}
    </Text>
  </Link>
);

const inputProps = {
  placeholderTextColor: 'rgba(255, 255, 255, 0.5)',
  spellCheck: false,
  autoCapitalize: 'none',
  style: { marginBottom: 20, width: 250 },
  underlineColorAndroid: 'rgba(0, 0, 0, 0)',
};

/* eslint-disable react/no-unused-state */
export default class AuthPage extends React.Component {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    // phone: '',
    termsAccepted: false,
    newsletter: false,
    showErrorModal: false,
    prevLoginStatus: this.props.loginStatus, // when switching pages component remounts
  };

  componentDidMount() {
    // Get id param in URL
    const { subscription_id } = this.props.match.params;
    this.setState({ subscription_id: subscription_id });
  }

  static getDerivedStateFromProps(props, state) {
    if (state.prevLoginStatus !== 'error' && props.loginStatus === 'error') {
      return {
        showErrorModal: true,
        prevLoginStatus: props.loginStatus,
      };
    }

    // if (props.loginStatus !== 'error') {
    //   return {
    //     errorShown: false
    //   };
    // }

    return {
      prevLoginStatus: props.loginStatus,
    };
  }

  loginUser = () => {
    this.setState({ prevLoginStatus: null });
    this.props.loginUser({
      email: this.state.email,
      password: this.state.password,
      subscription_id: this.state.subscription_id,
    });
  };

  registerUser = () => {
    this.setState({ prevLoginStatus: null });
    this.props.registerUser({
      email: this.state.email,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      newsletter: this.state.newsletter,
      subscription_id: this.state.subscription_id,
    });
  };

  render() {
    const {
      email,
      password,
      firstName,
      lastName,
      termsAccepted,
      newsletter,
      showErrorModal,
    } = this.state;
    const { location, loginStatus } = this.props;
    const isLoading = loginStatus === 'loading';

    return (
      <React.Fragment>
        {showErrorModal && (
          <Modal
            contentStyles={{ marginBottom: 20 }}
            onClose={() => {
              this.setState({ showErrorModal: false });
            }}>
            {this.props.errors ? (
              this.props.errors.map((err, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Text
                  key={i}
                  color="greyDarken"
                  style={{
                    paddingVertical: 10,
                    paddingHorizontal: 20,
                  }}>{`• ${err}`}</Text>
              ))
            ) : (
              <Text
                color="greyDarken"
                style={{ paddingVertical: 10, paddingHorizontal: 20 }}>
                • Server error, please try again later
              </Text>
            )}
          </Modal>
        )}
        <KeyboardAwareScrollView
          style={{ height: '100%' }}
          keyboardShouldPersistTaps="handled">
          <View style={styles.content}>
            {location.pathname.includes('/signup') ? (
              <React.Fragment>
                <Text
                  color="white"
                  style={{ width: 250, marginTop: 40, marginBottom: 25 }}>
                  Регистрация
                </Text>
                <TextInput
                  {...inputProps}
                  disabled={isLoading}
                  placeholder="Име"
                  textContentType="givenName"
                  value={firstName}
                  onChangeText={(text) => this.setState({ firstName: text })}
                />
                <TextInput
                  {...inputProps}
                  disabled={isLoading}
                  placeholder="Фамилия"
                  textContentType="familyName"
                  value={lastName}
                  onChangeText={(text) => this.setState({ lastName: text })}
                />
              </React.Fragment>
            ) : (
              <Text
                color="white"
                style={{ width: 250, marginTop: 40, marginBottom: 25 }}>
                Вход
              </Text>
            )}

            <TextInput
              {...inputProps}
              disabled={isLoading}
              placeholder="Въведете e-mail"
              textContentType="emailAddress"
              value={email}
              onChangeText={(text) => this.setState({ email: text })}
            />

            <TextInput
              {...inputProps}
              disabled={isLoading}
              placeholder="Парола"
              textContentType="password"
              secureTextEntry
              value={password}
              onChangeText={(text) => this.setState({ password: text })}
            />

            {location.pathname.includes('/signup') ? (
              <React.Fragment>
                {/* <TextInput
                {...inputProps}
                disabled={isLoading}
                placeholder="Phone number"
                textContentType="telephoneNumber"
                value={phone}
                onChangeText={text => this.setState({ phone: text })} /> */}

                <Checkbox
                  checked={termsAccepted}
                  onClick={() => {
                    this.setState((state) => ({
                      termsAccepted: !state.termsAccepted,
                    }));
                  }}>
                  <View style={{ width: 270 }}>
                    <Text color="white">
                      {'Запознат съм с и съм съгласен с '}
                    </Text>
                    <LinkText to="/terms">Общите условия</LinkText>
                  </View>
                </Checkbox>

                <Checkbox
                  flush
                  checked={newsletter}
                  onClick={() => {
                    this.setState((state) => ({
                      newsletter: !state.newsletter,
                    }));
                  }}>
                  <Text color="white" style={{ width: 270 }}>
                    Искам да получавам маркетингови съобщения
                  </Text>
                </Checkbox>

                {isLoading ? (
                  <ActivityIndicator />
                ) : (
                  <Button
                    onPress={this.registerUser}
                    disabled={!termsAccepted}
                    style={{ marginTop: 20 }}>
                    Регистрирай се
                  </Button>
                )}

                <Text
                  color="white"
                  style={{ width: 270, marginBottom: 20, fontSize: 12 }}>
                  Ако искате да активирате абонамент за BELL от А1 България, но
                  имате активен абонамент закупен от платформата ни, трябва да
                  изчакате периодът на абонамента Ви да изтече и тогава да
                  заявите нов от А1. Ако вече сте активирали и двата,
                  абонаментът от А1 България ще започне да се прилага
                  автоматично, веднага след изтичане на срока на ползване на
                  абонамента от BELL.
                </Text>

                <Row>
                  <LinkText to="/privacy-policy">
                    Политика за поверителност
                  </LinkText>
                </Row>

                <Text color="white" style={{ opacity: 0.5, marginVertical: 8 }}>
                  Вече имаш регистрация?
                </Text>

                <NavLink
                  to={
                    this.state.subscription_id
                      ? `/login/${this.state.subscription_id}`
                      : '/login'
                  }>
                  Вход
                </NavLink>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <LinkText to="/reset-password" style={{ marginBottom: 20 }}>
                  Забравена парола?
                </LinkText>

                {isLoading ? (
                  <ActivityIndicator />
                ) : (
                  <Button onPress={this.loginUser} disabled={isLoading}>
                    Вход
                  </Button>
                )}

                <Text color="white" style={{ opacity: 0.5, marginVertical: 8 }}>
                  {'Нямаш профил?'}
                </Text>

                <NavLink
                  to={
                    this.state.subscription_id
                      ? `/signup/${this.state.subscription_id}`
                      : '/signup'
                  }>
                  Регистрирай се
                </NavLink>
              </React.Fragment>
            )}
          </View>
        </KeyboardAwareScrollView>
      </React.Fragment>
    );
  }
}
