import { connect } from 'react-redux';
import {
  registerUser,
  loginUser,
} from '../../store/reducers/authentication';

import LoginPage from './components/LoginPage';

export default connect(
  ({ authentication: { loginStatus, error, errors } }) => ({ loginStatus, error, errors }),
  { loginUser, registerUser }
)(LoginPage);
